import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Row,
  Col,
  Button,
  Container,
  FormGroup,
} from "reactstrap";
import "./App.css";
import Footer from "./Footer";
import AppNavbarLight from "./AppNavbarLight";
import { useAuth } from "./AuthContext";
import { HBApi } from "./HBApi";
import { useDispatch } from "react-redux";
import { usersignin, boatinformation } from "./user/userreducer";
const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useAuth();
  const { t } = useTranslation();
  let location = useLocation();
  const [credentials, setCredentials] = useState();
  const [error401, setError401] = useState(false);

  console.log("Enter login");

  const handleChange = async (event) => {
    const { name, value } = event.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("Submitting credentials in handleSubmit" + credentials.email);
    HBApi.signIn(credentials)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return Promise.reject(response);
      })
      .then((json) => {
        console.log(
          "Received user from sign in, will now obtain boat information."
        );
        HBApi.getBoatInfo().then((response) => {
          if (response.ok) {
            response.json().then((boatJson) => {
              console.log(
                "Received boat information " + JSON.stringify(boatJson)
              );
              dispatch(boatinformation(boatJson));
            });
          }
        });
      })
      .then(() => {
        console.log(
          "Received user from sign in, will now obtain additional user information."
        );
        HBApi.getUserInfo()
          .then((responseInfo) => responseInfo.json())
          .then((jsonInfo) => {
            console.log(
              "Received user info: " +
                JSON.stringify(jsonInfo) +
                " now executing userLogin"
            );
            dispatch(usersignin(jsonInfo));
            auth.userLogin(jsonInfo);
            if (location.state && location.state.nextpage) {
              console.log(
                "Navigating to next page from sign in: " +
                  location.state.nextpage +
                  " location state is. " +
                  JSON.stringify(location.state)
              );
              navigate(location.state.nextpage, { state: location.state });
            } else {
              navigate("/");
            }
          });
      })
      .catch((errorResponse) => {
        console.log("errorResponse" + errorResponse.status);
        if (errorResponse.status === 401) {
          setError401(true);
        }
      });
  };

  return (
    <div>
      <Container>
        <Row>
          <Col md="12">
            <AppNavbarLight />
          </Col>
        </Row>
        <Row>
          <Col md="1"></Col>
          <Col>
            <Form
              className="bg-light border"
              style={{ "border-radius": "15px" }}
              onSubmit={handleSubmit}
            >
              <h2 align="center">{t("signin.header")}</h2>
              <FormGroup row>
                <Label for="email">{t("common.email")}</Label>
                <InputGroup>
                  {error401 ? (
                    <Input
                      style={{ "border-radius": "10px" }}
                      id="email"
                      name="email"
                      type="text"
                      autoComplete="email"
                      invalid
                      onChange={handleChange}
                    />
                  ) : (
                    <Input
                      style={{ "border-radius": "8px" }}
                      id="email"
                      name="email"
                      type="text"
                      autoComplete="email"
                      onChange={handleChange}
                    />
                  )}
                  <FormFeedback>
                    {t("signin.invalidusernamepassword")}
                  </FormFeedback>
                </InputGroup>
              </FormGroup>
              <FormGroup row>
                <Label for="password">{t("common.password")}</Label>
                <InputGroup>
                  {error401 ? (
                    <Input
                      style={{ "border-radius": "8px" }}
                      id="password"
                      name="password"
                      type="password"
                      invalid
                      onChange={handleChange}
                    />
                  ) : (
                    <Input
                      style={{ "border-radius": "8px" }}
                      id="password"
                      name="password"
                      type="password"
                      onChange={handleChange}
                    />
                  )}

                  <FormFeedback>
                    {t("signin.invalidusernamepassword")}
                  </FormFeedback>
                </InputGroup>
              </FormGroup>
              <FormGroup row>
                <InputGroup>
                  <Button
                    size="md"
                    color="primary"
                    id="submitSignin"
                    type="submit"
                  >
                    {t("signin.signinbutton")}
                  </Button>
                </InputGroup>
              </FormGroup>
              <FormGroup row>
                <Link id="forgotPasswordId" to="/forgotpassword">
                  {t("signin.forgotpassword")}
                </Link>
              </FormGroup>
            </Form>
          </Col>
          <Col md="1"></Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default SignIn;
