import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import { addDays } from "date-fns";
import {
  Form,
  Label,
  Input,
  Row,
  Col,
  Button,
  Container,
  Spinner,
  Collapse,
  Table,
  FormGroup,
} from "reactstrap";
import "../App.css";
import { HBApi } from "../HBApi";
import { DateFunctions } from "../hmehelpers/DateFunctions";
import BookingBody from "../bookinglists/BookingBody";
import { BookingFunctions } from "../hmehelpers/BookingFunctions";

const Bookings = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [harbour, setHarbour] = useState();
  const [bookings, setBookings] = useState([]);
  const [overlays, setOverlays] = useState([]);
  const [showCancelled, setShowCancelled] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const [currentBooking, setCurrentBooking] = useState({});
  const [date, setDate] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const toggle = (event) => {
    setIsOpen(!isOpen);
    setCurrentBooking(event.target.id);
  };
  const filterRelevantBookings = (bookings) =>
    bookings.filter(
      (b) =>
        b.status === 1 ||
        b.status === 3 ||
        (showCancelled && b.status === 2) ||
        showAll
    );
  useEffect(() => {
    const now = new Date();
    now.setHours(12, 0, 0, 0);
    const tomorrow = addDays(now, 1);
    async function fetchData() {
      const adminInfo = await HBApi.getAdminInfo();
      const adminInfoJson = await adminInfo.json();
      const bookingsResponse = await HBApi.getAdminBookingsByHarbour(
        adminInfoJson.harbour.id,
        now.toISOString(),
        now.toISOString()
      );
      const bookingsJson = await bookingsResponse.json();
      if (bookingsJson.length > 0) {
        const overlaysResponse = await HBApi.getAdminOverlaysByHarbour(
          adminInfoJson.harbour.id,
          now.toISOString(),
          tomorrow.toISOString()
        );
        const overlaysJson = await overlaysResponse.json();
        setOverlays(overlaysJson);
      }
      setDate(now);
      setHarbour(adminInfoJson.harbour.id);
      setBookings(bookingsJson.sort(sortBookingsOnLabel));
      bookingsJson.forEach((element) => {
        BookingFunctions.bookingTrailSorted(element);
      });
    }
    fetchData().then((result) => setLoading(false));
  }, []);
  // TODO This does not work for trails.
  function sortBookingsOnLabel(a, b) {
    const nameA = a.bdvs[0].label.toUpperCase(); // ignore upper and lowercase
    const nameB = b.bdvs[0].label.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  }

  const onDateChange = async (date) => {
    const start = date.setHours(12, 0, 0, 0);
    const tomorrow = addDays(start, 1);
    console.log(`Handle date = ${start}`);
    const bookingsResponse = await HBApi.getAdminBookingsByHarbour(
      harbour,
      date.toISOString(),
      date.toISOString()
    );
    const bookingsJson = await bookingsResponse.json();
    if (bookingsJson.length > 0) {
      const overlaysResponse = await HBApi.getAdminOverlaysByHarbour(
        harbour,
        date.toISOString(),
        tomorrow.toISOString()
      );
      const overlaysJson = await overlaysResponse.json();
      setOverlays(overlaysJson);
    } else {
      setOverlays([]);
    }

    setDate(date);
    bookingsJson.forEach((element) => {
      BookingFunctions.bookingTrailSorted(element);
    });
    setBookings(bookingsJson.sort(sortBookingsOnLabel));
  };

  const BookingsList = () => {
    const toRender = filterRelevantBookings(bookings);
    if (toRender.length === 0) {
      return (
        <Container>
          <Row>
            <h3>No bookings at {DateFunctions.onlyDateString(date)} </h3>
          </Row>
        </Container>
      );
    }
    return (
      <Table striped responsive>
        <thead>
          <tr>
            <th>Berth</th>
            <th>Electricity</th>
            <th>Status</th>
            <th>Model</th>
            <th>Name</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
          {toRender.map((b, indext) => {
            const todaysBooking = b.bdvs.find((bdv) =>
              DateFunctions.spannedByInclusiveFrom(date, bdv.from, bdv.to)
            );
            const otherBookings = b.bdvs
              .filter((candidate) => candidate.id !== todaysBooking.id)
              .reduce((l, r) => l + ", " + r.label, "");
            console.log(
              `Candidate id: ${JSON.stringify(
                todaysBooking.id
              )} Other bookings = ${JSON.stringify(otherBookings)}`
            );

            return (
              <>
                <tr>
                  <td>
                    {" "}
                    {todaysBooking.label} {otherBookings}
                  </td>
                  <td>{b.electricity ? "Yes" : "No"}</td>
                  <td>{t("common.bookingStatus." + b.status)}</td>
                  <td>{b.boatModel}</td>
                  <td>{b.boatName}</td>
                  <td>
                    <Button onClick={() => toggle({ target: { id: b.id } })}>
                      Details
                    </Button>
                  </td>
                </tr>
                <Collapse isOpen={isOpen && currentBooking === b.id}>
                  <tr>
                    <td>
                      <BookingBody
                        booking={{ bookingTrailDeepView: b, harbourView: {} }}
                        overlays={overlays.filter((o) =>
                          BookingFunctions.overlayAffectsBookingTrail(b, o)
                        )}
                      />
                    </td>
                  </tr>
                </Collapse>
              </>
            );
          })}
        </tbody>
      </Table>
    );
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <Container>
      <Row>
        <Form>
          <FormGroup row>
            <Col>
              <Label for="dateInput">
                <b>Bookings for date</b>
              </Label>
              <DatePicker
                id="dateInputId"
                name="dateInput"
                showTimeInput="true"
                selected={date}
                onChange={onDateChange}
              ></DatePicker>
            </Col>
          </FormGroup>
          <FormGroup row switch>
            <Col>
              <Label for="showCancelled">
                <b>Show cancelled</b>
              </Label>
              <Input
                name="showCancelled"
                id="showCancelledId"
                checked={showCancelled}
                type="switch"
                onClick={() => setShowCancelled(() => !showCancelled)}
              />
            </Col>
            <Col>
              <Label for="showAll">
                <b>Show all</b>
              </Label>
              <Input
                name="showAll"
                id="showAllId"
                checked={showAll}
                type="switch"
                onClick={() => setShowAll(() => !showAll)}
              />
            </Col>
          </FormGroup>
        </Form>
      </Row>

      <Row>{bookings.length > 0 && <h3>Bookings</h3>}</Row>
      <Row>
        <BookingsList />
      </Row>
    </Container>
  );
};

export default Bookings;
