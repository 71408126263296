import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Row, Col, Container, Nav, NavItem, NavLink } from "reactstrap";
import "./App.css";

import About from "./info/About";

const ChildrenHandler = ({ children }) => {
  if (!children) {
    return <About />;
  } else {
    return children;
  }
};

const InfoPage = ({ children }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Row>
        <Col>
          <Nav tabs>
            <NavItem>
              <NavLink id="aboutId" tag={Link} to="/about" active>
                {t("info.about.navtext")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink id="termsandconditionsId" tag={Link} to="/terms" active>
                {t("info.terms.navtext")}
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink id="faqId" tag={Link} to="/faq" active>
                {t("info.faq.navtext")}
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
      <ChildrenHandler children={children} />
    </Container>
  );
};

export default InfoPage;
