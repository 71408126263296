import "leaflet/dist/leaflet.css";
import React from "react";
import { MapContainer, TileLayer, Circle, useMap } from "react-leaflet";

const OneBerthHarbourMap = ({
  coords,
  selectedBerth,
  berths,
  setSelectedBerth,
}) => {
  console.log(
    "Enter OneBerthHarbourMap with selected berth: " +
      JSON.stringify(selectedBerth) +
      ", coords: " +
      JSON.stringify(coords)
  );

  const BerthMarkers = () => {
    return berths.map((berth) => {
      var radius = selectedBerth.id === berth.id ? 2 : 2;
      var color = selectedBerth.id === berth.id ? "white" : "gray";
      return (
        <Circle
          key={berth.id}
          center={[berth.latitude, berth.longitude]}
          radius={radius}
          color={color}
          eventHandlers={{
            click: (event) => {
              console.log(
                "marker clicked for key: " +
                  berth.id +
                  ", previously selected berth " +
                  selectedBerth
              );
              setSelectedBerth(berth);
            },
          }}
        />
      );
    });
  };

  const ChangeMapView = ({ coords }) => {
    const map = useMap();
    map.setView(coords, map.getZoom());
  };

  return (
    <MapContainer center={coords} zoom={17} scrollWheelZoom={false}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <BerthMarkers />
      <ChangeMapView coords={coords} />
    </MapContainer>
  );
};

export default OneBerthHarbourMap;
