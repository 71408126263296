import React, { useEffect, useState } from "react";
import { Label, Input, Row, Col, Container, Spinner } from "reactstrap";
import "../App.css";
import { HBApi } from "../HBApi";
import BerthStatusAdmin from "./BerthStatusAdmin";
import OneBerthHarbourMap from "./OneBerthHarbourMap";
const BerthAvailability = () => {
  const [berths, setBerths] = useState([]);
  const [currentBerth, setCurrentBerth] = useState();
  const [harbour, setHarbour] = useState();
  const [berthFilter, setBerthFilter] = useState("ALL");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log("Enter useEffect");
    async function harbourInfo() {
      const adminInfoResponse = await HBApi.getAdminInfo();
      const adminJson = await adminInfoResponse.json();
      setHarbour(adminJson.harbour);
      const berthsResponse = await HBApi.getBerthsForHarbour(
        adminJson.harbour.id
      );
      const berthsJson = await berthsResponse.json();
      setCurrentBerth(berthsJson[0]);
      setBerths(berthsJson);
    }

    harbourInfo().then((result) => setLoading(false));
  }, []);

  const handleBerthSelected = async (event) => {
    const { name, value } = event.target;
    console.log(`handleBerthSelected ${name}, ${value}`);
    setCurrentBerth(berths.find((candidate) => candidate.id === value));
  };
  const handleFilter = async (event) => {
    const { name, value } = event.target;
    setBerthFilter(value);
  };
  const BerthSelectOptions = () =>
    berths
      .filter((b) => {
        if (berthFilter === "ALL") {
          return true;
        } else {
          console.log(`berthFilter is ${berthFilter}`);
          return b.default_status === berthFilter;
        }
      })
      .map((b) => (
        <option key={b.id} value={b.id}>
          {b.label}
        </option>
      ));

  if (loading) {
    return <Spinner />;
  }

  return (
    <Container>
      <Row>
        <Col>
          <Label for="berthSelect">Berth</Label>
          <Input
            id="berthSelectId"
            value={currentBerth?.id}
            type="select"
            name="berthSelect"
            onChange={handleBerthSelected}
          >
            <BerthSelectOptions />
          </Input>
        </Col>
        <Col>
          <Label for="berthFilter">Show </Label>
          <Input
            name="berthFilter"
            id="berthFilterId"
            type="select"
            value={berthFilter}
            onChange={handleFilter}
          >
            <option value="ALL">Show all</option>
            <option value="ENABLED">Show default enabled</option>
            <option value="DISABLED">Show default disabled</option>
          </Input>
        </Col>
      </Row>
      <Row>
        <BerthStatusAdmin berth={currentBerth} />
      </Row>
      <Row>
        <Col>
          <OneBerthHarbourMap
            coords={[currentBerth.latitude, currentBerth.longitude]}
            selectedBerth={currentBerth}
            berths={berths}
            setSelectedBerth={setCurrentBerth}
          />
        </Col>
      </Row>
    </Container>
  );
};
export default BerthAvailability;
