import { useAuth } from "../AuthContext";
import { Input, Button, Badge, Row, Col, Container, Spinner } from "reactstrap";
import { MapContainer, TileLayer, Circle, Tooltip } from "react-leaflet";
import React, { useEffect, useState } from "react";
import { useGeolocated } from "react-geolocated";
import { HBApi } from "../HBApi";
import { BerthFunctions } from "../hmehelpers/BerthFunctions";
import { DateFunctions } from "../hmehelpers/DateFunctions";
import { addDays, subDays } from "date-fns";
const FeeSupport = () => {
  const now = new Date();
  const yesterday = subDays(now, 1);
  const tommorrow = addDays(now, 1);
  const auth = useAuth();
  const [harbour, setHarbour] = useState();
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState("t");
  const [berths, setBerths] = useState([]);
  const [todayBerths, setTodayBerths] = useState([]);
  const [yesterdayBerths, setYesterdayBerths] = useState([]);
  const [tomorrowBerths, setTomorrowBerths] = useState([]);
  const [manualCoords, setManualCoords] = useState();
  const onError = (event) => {};
  const onSuccess = (event) => {
    try {
      console.log(
        `onSuccess : ${JSON.stringify(event.coords.latitude)}, ${JSON.stringify(
          event.coords.longitude
        )}`
      );
    } catch (onError) {
      console.log(`Caught error ${JSON.stringify(onError)}`);
    }
    setManualCoords([event.coords.latitude, event.coords.longitude]);
  };
  const { coords, isGeolocationAvailable, isGeolocationEnabled, getPosition } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: true,
      },
      watchPosition: false,
      userDecisionTimeout: 5000,
      suppressLocationOnMount: false,
      isOptimisticGeolocationEnabled: true,
      watchLocationPermissionChange: false,
      onError,
      onSuccess,
    });

  const handleSubmit = async (event) => {
    event.preventDefault();
    getPosition();
  };

  const computeMapCoords = () => {
    if (BerthFunctions.near(coords, berths[0], 1000)) {
      return manualCoords;
    } else {
      return [harbour.latitude, harbour.longitude];
    }
  };
  const BerthStatusSymbol = ({ berth }) => {
    console.log(`BerthStatusSymbol : ${JSON.stringify(berth)}`);
    if (berth.enabled === false) {
      return <Badge color="danger">Unavailable</Badge>;
    } else if (berth.available === true) {
      return <Badge color="success">Available</Badge>;
    } else {
      return <Badge color="secondary">Booked</Badge>;
    }
  };

  const BoatText = ({ berth }) => {
    if (berth.boat) {
      return (
        <div>
          {berth.electricity ? "(E)" : " "} {berth.cancellable ? "(C)" : " "}{" "}
          {berth.boat?.boatModel} , {berth.boat?.boatName}
        </div>
      );
    } else {
      return "";
    }
  };
  const NearbyBerths = () => {
    console.log(`Compute near berths`);
    return berths
      .filter((b) => BerthFunctions.near(coords, b, 30))
      .toSorted((l, r) => {
        if (
          BerthFunctions.distance(coords, l) <
          BerthFunctions.distance(coords, r)
        ) {
          return -1;
        } else if (
          BerthFunctions.distance(coords, l) >
          BerthFunctions.distance(coords, r)
        ) {
          return 1;
        } else {
          return 0;
        }
      })
      .map((nearBerth) => {
        return (
          <Row>
            <Col>
              <b> {nearBerth.label}</b> <BerthStatusSymbol berth={nearBerth} />
            </Col>

            {nearBerth.boat ? (
              <Col>
                {nearBerth.electricity ? "(E)" : " "}{" "}
                {nearBerth.boat?.boatModel} , {nearBerth.boat?.boatName}
              </Col>
            ) : (
              <Col></Col>
            )}
          </Row>
        );
      });
  };

  const MyHarbourStatusMap = ({ myCoords, berths }) => {
    console.log(
      `HarbourStatusMap with berths ${berths.length}, latitude:${myCoords[0]}, longitude: ${myCoords[1]}`
    );

    const BerthMarkers = () => {
      return berths.map((berth) => {
        var radius = 2;
        var color = "";
        if (!berth.enabled) {
          color = "red";
        } else if (berth.available) {
          color = "green";
        } else {
          color = "gray";
        }

        return (
          <Circle
            key={berth.id}
            center={[berth.latitude, berth.longitude]}
            radius={radius}
            color={color}
          >
            <Tooltip>
              {berth.label} <BoatText berth={berth} />
            </Tooltip>
          </Circle>
        );
      });
    };

    return (
      <MapContainer center={myCoords} zoom={18} scrollWheelZoom={false}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Circle key="mapCenterId" center={myCoords} radius="1" color="yellow">
          <Tooltip>Your position</Tooltip>
        </Circle>
        <BerthMarkers />
      </MapContainer>
    );
  };

  useEffect(() => {
    setLoading(true);

    async function fetchData() {
      const hb = await HBApi.getAdminInfo();
      const adminInfoJson = await hb.json();
      setHarbour(adminInfoJson.harbour);
      const r0 = await HBApi.getAdminBerthStatusForFeeSupport(
        adminInfoJson.harbour.id,
        now.toISOString()
      );
      const r0Json = await r0.json();
      setBerths(r0Json);
      setTodayBerths(r0Json);
      const r1 = await HBApi.getAdminBerthStatusForFeeSupport(
        adminInfoJson.harbour.id,
        yesterday.toISOString()
      );
      const r1Json = await r1.json();
      setYesterdayBerths(r1Json);
      const r2 = await HBApi.getAdminBerthStatusForFeeSupport(
        adminInfoJson.harbour.id,
        tommorrow.toISOString()
      );
      const r2Json = await r2.json();
      setTomorrowBerths(r2Json);
    }
    if (auth.hasRole("ADMIN")) {
      fetchData().then(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, []);

  const dateString = (date) => {
    const oneDayForward = addDays(date, 1);
    return `${DateFunctions.onlyDateString(
      date
    )} 12.00 to ${DateFunctions.onlyDateString(oneDayForward)} 12.00`;
  };
  const handleDateSelected = async (event) => {
    const { name, value } = event.target;
    console.log(`handleDateSelected ${name}, ${value}`);
    setSelectedDate(value);
    if (value === "t") {
      setBerths(todayBerths);
    } else if (value === "m") {
      setBerths(tomorrowBerths);
    } else if (value === "y") {
      setBerths(yesterdayBerths);
    }
  };
  if (!auth.hasRole("ADMIN")) {
    return <div>Forbidden</div>;
  }
  if (!isGeolocationAvailable) {
    return <div>Your browser does not support Geolocation</div>;
  }
  if (!isGeolocationEnabled) {
    return <div>Geolocation is not enabled</div>;
  }

  if (loading) {
    return <Spinner />;
  }

  if (!manualCoords) {
    return <div>Getting location</div>;
  }

  if (auth.hasRole("ADMIN"))
    return (
      <Container>
        <NearbyBerths />
        <Row>
          <Col>
            <Button color="primary" onClick={handleSubmit}>
              Update
            </Button>
          </Col>
          <Col>
            <Input
              id="dateSelect"
              name="dateSelect"
              onChange={handleDateSelected}
              value={selectedDate}
              type="select"
            >
              <option value="y">{dateString(yesterday)}</option>
              <option value="t">{dateString(now)}</option>
              <option value="m">{dateString(tommorrow)}</option>
            </Input>
          </Col>
        </Row>
        <Row>
          <Col>
            <MyHarbourStatusMap
              myCoords={computeMapCoords()}
              berths={berths}
            ></MyHarbourStatusMap>
          </Col>
        </Row>
      </Container>
    );
};

export default FeeSupport;
