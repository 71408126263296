import { DateFunctions } from "./DateFunctions";
import { addHours } from "date-fns";
export const BookingFunctions = {
  bookingTrailSorted,
  berthsCommaSeparated,
  berthsCommaSeparatedForSegments,
  overlayAffectsBookingTrail,
  calcExcludedDays,
};

function bookingTrailSorted(bt) {
  bt.bdvs.sort((l, r) => DateFunctions.compareDateStrings(l.from, r.from));
  bt.bdvs.map((bdv) =>
    bdv.days.sort((l, r) => DateFunctions.compareDateStrings(l.from, r.from))
  );
}

function berthsCommaSeparated(bt) {
  const bdvCount = bt.bdvs.length;
  const cs = bt.bdvs.map((bdv, index) => {
    if (index === bdvCount - 1) {
      return bdv.label;
    } else {
      return bdv.label + ", ";
    }
  });
  return cs.reduce((l, r) => l + r);
}
function berthsCommaSeparatedForSegments(segments) {
  const segmentCount = segments.length;
  const cs = segments.map((segment, index) => {
    if (index === segmentCount - 1) {
      return segment.label;
    } else {
      return segment.label + ", ";
    }
  });
  return cs.reduce((l, r) => l + r);
}
function overlayAffectsBookingTrail(bt, overlay) {
  return bt.bdvs.find((bdv) => bdv.id === overlay.bookingId);
}

function calcExcludedDays(arrival, departure, unavailableDates) {
  // console.log(
  //   `calcExcludedDates with arrival: ${arrival}, departure: ${departure} , unavailableDates: ${JSON.stringify(
  //     unavailableDates
  //   )}`
  // );

  const rv = unavailableDates
    ? unavailableDates.map((d) => new Date(Date.parse(d)))
    : [];
  if (arrival && !departure) {
    const udArg = unavailableDates
      ? unavailableDates.map((d) => new Date(Date.parse(d)))
      : [];
    const unavailableDaysForArrival = DateFunctions.unavailableDates(
      udArg,
      arrival
    );
    console.log(
      `Calcexcludeddays: Only arrival chosen, setting unavailableDays ${JSON.stringify(
        unavailableDaysForArrival
      )}`
    );

    return unavailableDaysForArrival;
  } else if (arrival && departure && departureIsUnavailable(rv, departure)) {
    const dep = new Date(Date.parse(departure));
    return rv.filter((d) => d.getTime() !== addHours(dep, 12).getTime()); // searchObject is not a date
  }
  return rv;
}
function departureIsUnavailable(ud, departureIn) {
  const departure = new Date(Date.parse(departureIn));
  // console.log(
  //   `departureIsUnavailable with ud: ${JSON.stringify(
  //     ud
  //   )} and selectedDate: ${JSON.stringify(departure)}`
  // );
  const rv = ud.find((d) => d.getTime() === addHours(departure, 12).getTime());
  console.log(`departureIsUnavailable find yields ${JSON.stringify(rv)}`);

  return rv !== undefined;
}
