import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DateFunctions } from "./hmehelpers/DateFunctions";
import {
  Toast,
  ToastHeader,
  ToastBody,
  Row,
  Col,
  Button,
  Container,
  List,
  Spinner,
  Input,
} from "reactstrap";
import "./App.css";
import { HBApi } from "./HBApi";
import { BookingFunctions } from "./hmehelpers/BookingFunctions";

const BookingCancellationAnonymous = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [booking, setBooking] = useState({});
  const [dryRun, setDryRun] = useState({});
  const [errorResult, setErrorResult] = useState(null);
  const [cancelComplete, setCancelComplete] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cancelError, setCancelError] = useState(null);
  const [toCancel, setToCancel] = useState(new Map());
  useEffect(() => {
    setLoading(true);
    HBApi.getBookingByIdNoJson(id)
      .then((response) => {
        if (response.ok) {
          console.log("Response is ok");
          return response.json();
        } else {
          console.log(`Response is not ok`);
          return Promise.reject(response);
        }
      })
      .then((booking) => {
        HBApi.cancelDryRun(id, 0)
          .then((cancelDRResponse) => {
            if (cancelDRResponse.ok) {
              return cancelDRResponse.json();
            } else {
              return Promise.reject(cancelDRResponse);
            }
          })
          .then((dryRunJson) => {
            console.log(
              "Received dryRun response : " + JSON.stringify(dryRunJson)
            );
            const toCancelMap = new Map();
            dryRunJson.daysCancelled.forEach((day) =>
              toCancelMap.set(day.id, true)
            );
            setToCancel(toCancelMap);
            HBApi.getHarbourById(booking.harbourId)
              .then((harbourResponse) => {
                if (harbourResponse.ok) {
                  return harbourResponse.json();
                } else {
                  return Promise.reject(harbourResponse);
                }
              })
              .then((harbourJson) => {
                const bAndH = { ...booking, harbourLabel: harbourJson.name };
                setBooking(bAndH);
                setDryRun(dryRunJson);
                setLoading(false);
              })
              .catch((errorResponse) => {
                setLoading(false);
                setErrorResult(t("common.generalerrormessage"));
              });
          })
          .catch((errorResponse) => {
            setLoading(false);
            console.log("errorResponse " + errorResponse.status);
            if (errorResponse.status === 404) {
              setErrorResult(t("bookingcancellation.nobookingfound"));
            } else if (errorResponse.status === 500) {
              setErrorResult(t("common.generalerrormessage"));
            }
          });
      });
  }, []);

  const handleCancel = async (event) => {
    event.preventDefault();
    setErrorResult(null);
    setLoading(true);
    console.log(`Will cancel days ${JSON.stringify(toCancel)}`);
    HBApi.cancelBookingDaylist(booking.id, toCancel)
      .then((response) => {
        if (response.ok) {
          setLoading(false);
          return response.body;
        } else {
          console.log(`Received error response ${response.status}`);
          return Promise.reject(response);
        }
      })
      .then((success) => {
        setCancelComplete(true);
      })
      .catch((errorResponse) => {
        setLoading(false);
        if (errorResponse.status === 409) {
          setCancelError(409);
        }
        if (errorResponse.status === 412) {
          setCancelError(412);
        }
        if (errorResponse.status === 417) {
          setCancelError(417);
        } else if (errorResponse.status === 423) {
          setCancelError(423); // LOCKED means the booking is historic and therefore cannot be cancelled
        } else if (errorResponse.status === 400) {
          setCancelError(400);
        } else {
          setErrorResult("An error ocurred");
        }
      });
  };
  const Error400Message = () => {
    if (cancelError === 400) {
      return <div>{t("bookingcancellation.illegalcancelleddaysmessage")}</div>;
    }
  };

  const BookingDryRunResult = () => {
    let cancelledDaysCount = dryRun.daysCancelled.length;
    if (cancelledDaysCount > 0) {
      return (
        <div>
          <li>
            {t("bookingcancellation.willbecancelledmessage", {
              count: cancelledDaysCount,
            })}
          </li>
          {/* <DaysList /> */}
        </div>
      );
    } else {
      return booking.bdvs
        .flatMap((bdv) => bdv.days)
        .map((day, index) => (
          <li key={index}>
            {t("common.day")} {index},{" "}
            {day.status === 2 && t("common.cancelled")}
            {day.status === 1 && t("common.confirmed")}
          </li>
        ));
    }
  };
  const isDisabled2 = (dayArg) => {
    if (toCancel.has(dayArg.id)) {
      return false;
    } else {
      return true;
    }
  };
  const atLeastOneDayNotCancellable = () => {
    return (
      booking.bdvs
        .flatMap((bdv) => bdv.days)
        .filter((day) => day.status === 1)
        .filter((day) => !toCancel.has(day.id)).length > 0
    );
  };
  const DaysSelect = () => {
    console.log(
      `Enter daysselect with days ${JSON.stringify(toCancel.entries().next())}`
    );
    return booking.bdvs
      .flatMap((bdv) => bdv.days)
      .map((day, index) => {
        if (isDisabled2(day)) {
          return (
            <li>
              <Input
                id={"cancellation-" + day.id}
                checked={toCancel.get(day.id)}
                disabled
                type="checkbox"
                onClick={() => {
                  const currentValue = toCancel.get(day.id);
                  toCancel.set(day.id, !currentValue);
                  const newMap = new Map(toCancel);
                  setToCancel(newMap);
                }}
              ></Input>{" "}
              {DateFunctions.stringToOnlyDateString(day.from)}{" "}
              {day.status === 2 && t("bookingcancellation.alreadycancelled")}
              {day.status === 1 && "(*)"}
            </li>
          );
        } else {
          return (
            <li>
              <Input
                id={"cancellation-" + day.id}
                checked={toCancel.get(day.id)}
                type="checkbox"
                onClick={() => {
                  const currentValue = toCancel.get(day.id);
                  toCancel.set(day.id, !currentValue);
                  const newMap = new Map(toCancel);
                  setToCancel(newMap);
                }}
              ></Input>{" "}
              {DateFunctions.stringToOnlyDateString(day.from)}
            </li>
          );
        }
      });
  };

  const SubmitButton = () => {
    const atLeastOneSelected =
      [...toCancel.entries()].filter(({ 1: v }) => v === true).length > 0;
    if (atLeastOneSelected) {
      return (
        <Button id="cancelButtonId" color="primary" onClick={handleCancel}>
          {t("bookingcancellation.confirmbutton")}
        </Button>
      );
    } else {
      return (
        <Button
          id="cancelButtonId"
          disabled
          color="primary"
          onClick={handleCancel}
        >
          {t("bookingcancellation.confirmbutton")}
        </Button>
      );
    }
  };
  if (loading) {
    return <Spinner />;
  }

  if (cancelError === 423) {
    return (
      <div>
        <Container>
          <Row>
            <Col>
              <div className="p-3 my-2 bg-secondary rounded">
                <Toast>
                  <ToastHeader>
                    <b>{t("bookingcancellation.notcancellableheader")}</b>
                  </ToastHeader>
                  <ToastBody>
                    <List>
                      <li>{t("bookingcancellation.notcancellablemessage")}</li>
                    </List>
                    <hr />
                  </ToastBody>
                </Toast>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
  if (cancelError === 417) {
    return (
      <div>
        <Container>
          <Row>
            <Col>
              <div className="p-3 my-2 bg-secondary rounded">
                <Toast>
                  <ToastHeader>
                    <b>{t("bookingcancellation.notcancellableheader")}</b>
                  </ToastHeader>
                  <ToastBody>
                    <List>
                      <li>
                        {t(
                          "bookingcancellation.notbookedwithcancellablemessage"
                        )}
                      </li>
                    </List>

                    <hr />
                  </ToastBody>
                </Toast>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
  if (cancelError === 409) {
    return (
      <div>
        <Container>
          <Row>
            <Col>
              <div className="p-3 my-2 bg-secondary rounded">
                <Toast>
                  <ToastHeader>
                    <b>
                      {t("bookingcancellation.bookingalreadycancelledheader")}
                    </b>
                  </ToastHeader>
                  <ToastBody>
                    <List>
                      <li>{t("bookingcancellation.youcancelled")}</li>
                      <li>{t("bookingcancellation.emailwassent")}</li>
                      <li>{t("bookingcancellation.returnMessage")}</li>
                    </List>

                    <hr />
                  </ToastBody>
                </Toast>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
  if (cancelError === 412) {
    // Entity lock claimed by other
    return (
      <div>
        <Container>
          <Row>
            <Col>
              <div className="p-3 my-2 bg-secondary rounded">
                <Toast>
                  <ToastHeader>
                    <b>{t("bookingcancellation.temporarilylockedheader")}</b>
                  </ToastHeader>
                  <ToastBody>
                    <List>
                      <li>
                        {t("bookingcancellation.temporarilylockedmessage")}
                      </li>
                      <li>{t("bookingcancellation.tryagain")}</li>
                    </List>

                    <hr />
                  </ToastBody>
                </Toast>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
  if (cancelComplete) {
    return (
      <div>
        <Container>
          <Row>
            <Col>
              <div className="p-3 my-2 bg-secondary rounded">
                <Toast>
                  <ToastHeader>
                    <b>{t("bookingcancellation.successheader")}</b>
                  </ToastHeader>
                  <ToastBody>
                    <List>
                      <li>{t("bookingcancellation.confirmationMessage")}</li>
                      <li>{t("bookingcancellation.returnMessage")}</li>
                    </List>

                    <hr />
                  </ToastBody>
                </Toast>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  } else if (errorResult) {
    return (
      <div>
        <Container>
          <Row>
            <Col>
              <div className="p-3 my-2 bg-danger rounded">
                <Toast>
                  <ToastHeader>
                    <b>{t("bookingcancellation.header")}</b>
                  </ToastHeader>
                  <ToastBody>
                    {errorResult}
                    <hr />
                  </ToastBody>
                </Toast>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div className="p-3 my-2 bg-secondary rounded">
              <Toast>
                <ToastHeader>
                  <b>{t("bookingcancellation.header")}</b>
                </ToastHeader>
                {dryRun.daysCancelled.length > 0 && (
                  <ToastBody>
                    {t("bookingcancellation.aboutto")}
                    <List>
                      <li>
                        {t("common.berthCapital")}{" "}
                        {BookingFunctions.berthsCommaSeparated(booking)}{" "}
                      </li>
                      <li>
                        {t("common.atHarbourPrepositionCapital")}{" "}
                        {booking.harbourLabel}
                      </li>
                      <li>
                        {t("common.from")}{" "}
                        {DateFunctions.onlyDateString(booking.from)}
                      </li>
                      <li>
                        {t("common.to")}{" "}
                        {DateFunctions.onlyDateString(booking.to)}
                      </li>

                      <BookingDryRunResult />
                    </List>
                    {t("bookingcancellation.selectdays")}
                    <List>
                      <DaysSelect />
                    </List>
                    {atLeastOneDayNotCancellable() && (
                      <div>
                        * {t("bookingcancellation.notcancellableexplanation")}
                      </div>
                    )}
                    <Error400Message />
                    <SubmitButton />

                    <hr />
                  </ToastBody>
                )}
                {dryRun.daysCancelled.length === 0 && (
                  <ToastBody>
                    <b>{t("bookingcancellation.nocancellabledays")}</b>
                    <List>
                      <li key="berth">
                        {t("common.berthCapital")} {booking.bdvs[0].label}{" "}
                      </li>
                      <li key="harbour">
                        {t("common.atHarbourPrepositionCapital")}{" "}
                        {booking.harbourLabel}
                      </li>
                      <li key="from">
                        {t("common.from")}{" "}
                        {DateFunctions.onlyDateString(booking.from)}
                      </li>
                      <li key="to">
                        {t("common.to")}{" "}
                        {DateFunctions.onlyDateString(booking.to)}
                      </li>
                      <BookingDryRunResult />
                    </List>
                    <hr />
                  </ToastBody>
                )}
              </Toast>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default BookingCancellationAnonymous;
