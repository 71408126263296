import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Accordion,
  Badge,
  Label,
  Input,
  Row,
  Col,
  Button,
  Container,
  Spinner,
} from "reactstrap";
import "./App.css";
import { HBApi } from "./HBApi";
import {
  stringToDateString,
  stringToWithCustomEndTimeHoursDateString,
} from "./hmehelpers/DateFunctions";
import { BerthFunctions } from "./hmehelpers/BerthFunctions";
import { useAuth } from "./AuthContext";
import DatePicker, { registerLocale } from "react-datepicker";
import { sv } from "date-fns/locale";

const MyHarbour = () => {
  registerLocale("sv", sv);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const auth = useAuth();

  let MILLIS_PER_DAY = 86400000;
  const [myBerths, setMyBerths] = useState([]);
  const [futureBookings, setFutureBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [futureStatuses, setFutureStatuses] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [customArrival, setCustomArrival] = useState("12");
  const [refresh, setRefresh] = useState(0);
  var today = new Date();
  var maxDate = new Date(today.getTime() + 150 * MILLIS_PER_DAY);

  const [open, setOpen] = useState("0");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  useEffect(() => {
    console.log("Enter useEffect");
    setLoading(true);
    HBApi.getMyBerths()
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return Promise.reject(response);
      })
      .then((data) => {
        if (!data[0]) {
          setLoading(false);
          return;
        } else {
          console.log("My harbour received berths: " + JSON.stringify(data));
          setMyBerths(data);
          HBApi.getBookingsByBerth(data[0].id, today, maxDate)
            .then((bookings) => {
              console.log("Received bookings: " + JSON.stringify(bookings));
              setFutureBookings(bookings);
              HBApi.getBerthStatusesByBerth(
                data[0].id,
                new Date(),
                new Date()
              ).then((statusesJson) => {
                console.log(
                  "Received berth statuses: " + JSON.stringify(statusesJson)
                );
                setFutureStatuses(statusesJson);
              });
            })
            .then(() => setLoading(false));
        }
      })
      .catch((errorResponse) => {
        console.log("errorResponse" + errorResponse.status);
        if (errorResponse.status === 401) {
          // If the server has restarted, this will happen
          console.log(
            "Unexpected 401 response, this will happen if the server has restarted"
          );
          auth.userLogout();
          navigate("/signin", { state: { nextpage: "/mypage/myberth" } });
        }
      });
  }, [refresh]);

  const highlightWithRanges = [
    {
      "react-datepicker__day--highlighted-custom-1":
        BerthFunctions.bookedDays(futureBookings),
    },
    {
      "react-datepicker__day--highlighted-custom-2":
        BerthFunctions.statusDaysExcludingBookedDays(
          futureStatuses,
          futureBookings
        ),
    },
  ];

  const onDateChange = (dates) => {
    const [start, end] = dates;
    console.log("Enter onDateChange with start: " + start + " and end: " + end);
    if (end && start.getTime() === end.getTime()) {
      console.log("Start date = end date");
      return;
    }
    setStartDate(start);
    setEndDate(end);
  };

  const StatusDates = () => {
    let hld = highlightWithRanges;
    return (
      <DatePicker
        locale={i18n.language}
        minDate={new Date()}
        maxDate={maxDate}
        onChange={onDateChange}
        highlightDates={hld}
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        inline
        selectsDisabledDaysInRange={false}
      />
    );
  };

  const StatusCol = ({ berth }) => {
    if (!berth.enabled) {
      return (
        <Col>
          <Badge>{t("myharbour.currentlydisabled")}</Badge>
        </Col>
      );
    } else if (berth.enabled && berth.available) {
      return (
        <Col>
          <Badge>{t("myharbour.currentlyavailable")}</Badge>
        </Col>
      );
    } else {
      return (
        <Col>
          <Badge>{t("myharbour.currentlybooked")}</Badge>
        </Col>
      );
    }
  };

  const FutureBookingCount = ({ berth }) => {
    return (
      <Col>
        <Badge>
          {t("myharbour.futurebookeddays")}:{" "}
          {BerthFunctions.bookedDays(futureBookings).length}
        </Badge>
      </Col>
    );
  };

  const statusLabel = (berth) => {
    if (berth.defaultStatus === 0) {
      return t("myharbour.futureenableddays");
    } else {
      return t("myharbour.futurebdisableddays");
    }
  };
  const FutureEnabledCount = ({ berth }) => {
    return (
      <Col>
        <Badge>
          {statusLabel(berth)}:{" "}
          {BerthFunctions.futureStatusDays(futureStatuses).length}
        </Badge>
      </Col>
    );
  };

  const BookingsStatusesAccordion = () => {
    return (
      <Accordion open={open} toggle={toggle}>
        <AccordionItem>
          <AccordionHeader targetId="1">
            <b>{t("myharbour.futurebookingdetails")}</b>
          </AccordionHeader>
          <AccordionBody accordionId="1">
            <BookingsList />
          </AccordionBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeader targetId="2">
            <b>{t("myharbour.futurestatuses")}</b>
          </AccordionHeader>
          <AccordionBody accordionId="2">
            <StatusesList />
          </AccordionBody>
        </AccordionItem>
      </Accordion>
    );
  };

  const BookingsList = () => {
    return futureBookings.map((b) => (
      <Row key={b.id}>
        <Col>
          <b>{t("myharbour.booking")}:</b>{" "}
        </Col>
        <Col>
          {" "}
          {t("common.from")}: {stringToWithCustomEndTimeHoursDateString(b.from)}{" "}
        </Col>
        <Col>
          {" "}
          {t("common.to")}: {stringToWithCustomEndTimeHoursDateString(b.to)}{" "}
        </Col>
        <Col>
          {" "}
          {t("myharbour.bookedat")}: {stringToDateString(b.createdAt)}{" "}
        </Col>
        <Col>
          {" "}
          {t("common.cancellable")}:{" "}
          {b.cancellable ? t("common.yes") : t("common.no")}{" "}
        </Col>
      </Row>
    ));
  };

  const StatusesList = () => {
    return futureStatuses.map((st) => (
      <Row key={st.id}>
        <Col>
          <b>
            {st.status === 1
              ? t("myharbour.bookableperiod")
              : t("myharbour.nonbookableperiod")}
          </b>{" "}
        </Col>
        <Col>
          {" "}
          {t("common.from")}:{" "}
          {stringToWithCustomEndTimeHoursDateString(st.from)}{" "}
        </Col>
        <Col>
          {" "}
          {t("common.to")}: {stringToWithCustomEndTimeHoursDateString(st.to)}{" "}
        </Col>
        <Col>
          <Button onClick={() => deleteStatus({ target: { id: st.id } })}>
            X
          </Button>
        </Col>
        <Col />
      </Row>
    ));
  };

  const NoBerth = () => {
    return (
      <Container>
        <Row>
          <Col md="12">
            <h1 align="center">No berth</h1>
          </Col>
        </Row>
      </Container>
    );
  };

  const handleCustomArrival = async (event) => {
    const { name, value } = event.target;
    setCustomArrival(value);
  };

  const deleteStatus = (event) => {
    console.log(`deleteStatus with ${JSON.stringify(event.target)}`);
    HBApi.deleteBerthStatus({ id: event.target.id })
      .then((response) => {
        if (response.status.ok) {
          return response.json();
        }
      })
      .then((jsonResponse) => setRefresh(refresh + 1));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    console.log(
      `Submit make available request with start date ${startDate} and end date ${endDate} (hours: ${endDate.getHours()}) and custom arrival ${customArrival}`
    );
    HBApi.modifyBerthStatus({
      berth: myBerths[0].id,
      berthStatus: "1",
      from: startDate,
      to: endDate,
      customArrival: customArrival,
    })
      .then((response) => {
        console.log(`Got response: ${response.status}`);
        return response.json();
      })
      .then((data) => {
        console.log(`Modify statuses respons: ${JSON.stringify(data)}`);
        let newFutureStatuses = [...futureStatuses, data[0]];
        console.log(
          `New future statuses: ${JSON.stringify(newFutureStatuses)}`
        );
        setRefresh(refresh + 1);
      });
  };

  const MakeAvailableButton = () => {
    if (
      endDate &&
      BerthFunctions.atLeastOneDayChangesStatusToEnabled(
        startDate,
        endDate,
        futureStatuses,
        futureBookings
      )
    ) {
      return (
        <Button
          id="makeAvailableButtonId"
          size="lg"
          color="primary"
          onClick={handleSubmit.bind(this)}
        >
          {t("myharbour.makeavailablebutton")}
        </Button>
      );
    } else {
      return (
        <Button size="lg" color="primary" disabled>
          {t("myharbour.makeavailablebutton")}
        </Button>
      );
    }
  };

  const CustomHours = () => {
    return Array.from({ length: 10 }, (value, index) => index + 12).map(
      (actualHour) => {
        return (
          <option key={actualHour} value={actualHour}>
            {actualHour}
          </option>
        );
      }
    );
  };
  const CustomArrival = () => {
    return (
      <div>
        <Label for="customarrival">{t("myharbour.customendtime")}</Label>
        <Input
          value={customArrival}
          id="customarrival"
          bssize="md"
          type="select"
          name="customarrival"
          onChange={handleCustomArrival}
        >
          <CustomHours />
        </Input>
      </div>
    );
  };

  const DefaultStatus = () => {
    if (myBerths[0].defaultStatus === 0) {
      return (
        <>
          {t("myharbour.defaultstatus")} <Badge color="warning">Disabled</Badge>
        </>
      );
    } else {
      return (
        <>
          {t("myharbour.defaultstatus")} <Badge>Enabled</Badge>
        </>
      );
    }
  };

  if (loading) {
    return <Spinner />;
  }

  if (myBerths.length === 0) {
    return <NoBerth />;
  } else {
    return (
      <Container>
        <Row>
          <Col md="12">
            <h3 align="center">{t("myharbour.heading")}</h3>
          </Col>
        </Row>
        <Row>
          <Col id="berthLabelId">
            {t("myharbour.berthlabel")}: {myBerths[0].label}
          </Col>
          <Col>
            <DefaultStatus />
          </Col>
          <Col />
          <Col />
        </Row>
        <Row>
          <Col id="maxLengthId">
            {t("common.maxlength")} {myBerths[0].maxLength}
          </Col>
          <Col>
            {t("common.maxwidth")} {myBerths[0].maxWidth}
          </Col>
          <Col>
            {t("common.maxdepth")} {myBerths[0].maxDepth}
          </Col>
          <Col>
            {t("common.fixationTypeCapital")}:{" "}
            {t("common." + myBerths[0].fixationTypeLabel)}
          </Col>
        </Row>
        <Row>
          <StatusCol berth={myBerths[0]} />
          <FutureBookingCount berth={myBerths[0]} />
          <FutureEnabledCount berth={myBerths[0]} />
          <Col />
        </Row>

        <Row>
          <BookingsStatusesAccordion />
        </Row>
        <Row>
          <Col />
          <Col>
            <StatusDates />
          </Col>
          <Col>
            <CustomArrival />
          </Col>
        </Row>
        <Row>
          <Col />
          <Col>
            <MakeAvailableButton />
          </Col>
          <Col />
        </Row>
      </Container>
    );
  }
};

export default MyHarbour;
