import "leaflet/dist/leaflet.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Card, CardTitle, CardBody, Button } from "reactstrap";
//import "./App.css";
import { DateFunctions } from "../hmehelpers/DateFunctions";
import { useDispatch } from "react-redux";
import { segments, dates } from "./bookingreducer";

const ToBookingDetailsButton = ({
  trailSearchResult,
  harbour,
  criteria,
  submitDestination,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <Button
      id="toBookingDetailsButton"
      size="lg"
      color="primary"
      onClick={() => {
        console.log(
          `Clicked proceed with trailSearchResult
                ${JSON.stringify(trailSearchResult)}`
        );
        dispatch(segments(trailSearchResult));

        const a = criteria.arrival;
        const d = criteria.departure;
        d.setHours(12);
        a.setHours(12);
        const arrival = a.toISOString();
        const departure = d.toISOString();
        dispatch(
          dates({
            arrival: arrival,
            departure: departure,
          })
        );
        navigate(submitDestination, {
          state: {
            trail: trailSearchResult,
            harbour: harbour,
            criteria: {
              ...criteria,
              arrival: criteria.arrival.setHours(12),
              departure: criteria.departure.setHours(12),
            },
          },
        });
      }}
    >
      {t("book.proceedtodetails")}
    </Button>
  );
};
const Trail = ({ trailSearchResult, harbour, criteria, submitDestination }) => {
  const { t } = useTranslation();
  console.log(
    `Enter Trail with harbour: ${JSON.stringify(
      harbour.name
    )} and trail  ${JSON.stringify(
      trailSearchResult
    )} with criteria ${JSON.stringify(criteria)}`
  );
  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">{t("book.trailheading")} </CardTitle>
        {t("book.trailmessage", {
          count: trailSearchResult.length,
        })}{" "}
        {trailSearchResult.map((trail) => {
          return (
            <div key={trail.id}>
              <b>
                {t("common.berthCapital")} {trail.label}
              </b>{" "}
              {t("common.from")} {DateFunctions.stringToDateString(trail.from)}
              {"  "} {t("common.to")}{" "}
              {DateFunctions.stringToDateString(trail.to)}
            </div>
          );
        })}
        <ToBookingDetailsButton
          trailSearchResult={trailSearchResult}
          harbour={harbour}
          criteria={criteria}
          submitDestination={submitDestination}
        />
      </CardBody>
    </Card>
  );
};

export default Trail;
