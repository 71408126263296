import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Row, Col, Container, Nav, NavItem, NavLink } from "reactstrap";
import "./App.css";
import { useAuth } from "./AuthContext";
import Profile from "./Profile";

const ChildrenHandler = ({ children }) => {
  if (!children) {
    return <Profile />;
  } else {
    return children;
  }
};

const MyPage = ({ children }) => {
  const auth = useAuth();
  const user = auth.getUser();
  const { t } = useTranslation();
  return (
    <Container>
      {/* <Row>
        <Col md="12">
          <h1 align="center">User zone</h1>
        </Col>
      </Row> */}
      <Row>
        <Col>
          <Nav tabs>
            <NavItem>
              <NavLink id="myProfileId" tag={Link} to="/mypage" active>
                {t("nav.sub.profile")}
              </NavLink>
            </NavItem>
            {user && auth.hasRole("WITHBERTH") && (
              <NavItem>
                <NavLink id="myBerthId" tag={Link} to="/mypage/myberth" active>
                  {t("nav.sub.myberth")}
                </NavLink>
              </NavItem>
            )}
            <NavItem>
              <NavLink
                id="futureBookingsId"
                tag={Link}
                to="/mypage/futurebookings"
                active
              >
                {t("nav.sub.futurebookings")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                id="bookingHistoryId"
                tag={Link}
                to="/mypage/bookingshistory"
                active
              >
                {t("nav.sub.pastbookings")}
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
      <ChildrenHandler children={children} />
    </Container>
  );
};

export default MyPage;
