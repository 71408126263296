import { Container, Row, Col, Spinner } from "reactstrap";
import SalesBarChart from "./SalesBarChart";

import React, { useState, useEffect } from "react";
import { HBApi } from "../HBApi";
import AvailabilityBarChart from "./AvailabilityBarChart";
import { addDays } from "date-fns";
const RecentStats = () => {
  const [salesDataset, setSalesDataset] = useState({});
  const [availableDatset, setAvailableDataset] = useState({});
  const [loading, setLoading] = useState(true);
  const to = addDays(new Date(), 30);
  useEffect(() => {
    async function fetchData() {
      const dataSetResult = await HBApi.adminStatsRevenueByDay(
        new Date().toISOString(),
        to.toISOString()
      );
      const dataJson = await dataSetResult.json();
      console.log(`Fetched data in useEffect ${dataJson.name}`);
      //DataSet(String name, List<Datum> data)
      // Datum(String label, List<Integer> data)
      const labels = dataJson.data.map((datum) => datum.label);
      const berthSales = dataJson.data.map((datum) => datum.data[0]);
      const bookingSales = dataJson.data.map((datum) => datum.data[1]);
      const elSales = dataJson.data.map((datum) => datum.data[2]);
      const cancellationSales = dataJson.data.map((datum) => datum.data[3]);
      //0, 155, 50 green
      setSalesDataset({
        labels,
        datasets: [
          {
            label: "Berth",
            data: berthSales,
            borderWidth: 1,
            backgroundColor: "rgba(53, 162, 235, 0.5)",
          },
          {
            label: "Booking fee",
            data: bookingSales,
            borderWidth: 1,
            backgroundColor: "rgba(10, 162, 35, 0.5)",
          },
          {
            label: "Electricity",
            data: elSales,
            borderWidth: 1,
            backgroundColor: "rgba(255, 99, 132, 0.5)",
          },
          {
            label: "Cancellation",
            data: cancellationSales,
            borderWidth: 1,
            backgroundColor: "rgba(255, 99, 255, 0.5)",
          },
        ],
      });
    }
    async function fetchAvailabilityData() {
      const dataSetResult = await HBApi.adminStatsAvailabilityByDay(
        new Date().toISOString(),
        to.toISOString()
      );
      const dataJson = await dataSetResult.json();
      console.log(`Fetched data in useEffect ${dataJson.name}`);
      const labels = dataJson.data.map((datum) => datum.label);
      const available = dataJson.data.map((datum) => datum.data[0]);
      const booked = dataJson.data.map((datum) => datum.data[1]);
      //0, 155, 50 green
      setAvailableDataset({
        labels,
        datasets: [
          {
            label: "Booked berths",
            data: available,
            borderWidth: 1,
            backgroundColor: "rgba(15, 0, 250, 0.5)",
          },
          {
            label: "Available berths",
            data: booked,
            borderWidth: 1,
            backgroundColor: "rgba(0, 155, 50, 0.5)",
          },
        ],
      });
    }

    fetchData().then((result) => {
      fetchAvailabilityData().then((res) => {
        setLoading(false);
      });
    });
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Container>
      <Row>
        <Col>
          <h3>Revenue per day</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <SalesBarChart data={salesDataset} />
        </Col>
      </Row>
      <Row>
        <Col>
          <h3>Availability per day</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <AvailabilityBarChart data={availableDatset} />
        </Col>
      </Row>
    </Container>
  );
};

export default RecentStats;
