import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Container } from "reactstrap";
import "./App.css";
import Footer from "./Footer";
import AppNavbarLight from "./AppNavbarLight";
import { HBApi } from "./HBApi";
import { useAuth } from "./AuthContext";
import { persistor } from "./store";

const SignOut = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  console.log("Enter sign out");

  useEffect(() => {
    if (auth.userIsAuthenticated()) {
      console.log(`userIsAuthenticated is true`);
      HBApi.signOut({ email: auth.getUser().email, password: "unused" }).then(
        (rv) => {
          console.log("Received sign out result: " + JSON.stringify(rv));
          auth.userLogout();
          persistor.purge();
          navigate("/");
        }
      );
    } else {
      persistor.purge();
      navigate("/");
    }
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col md="12">
            <AppNavbarLight />
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <h1 align="center">Sign out</h1>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default SignOut;
