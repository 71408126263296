import React from "react";
import { useTranslation } from "react-i18next";
import "../App.css";

import {
  Container,
  Row,
  Col,
  List,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";

const Terms = () => {
  const { t } = useTranslation();

  return (
    <Container fluid>
      <Container fluid>
        <Row>
          <Col></Col>
          <Col md="10">
            <Card>
              <CardHeader className="fw-bold p-4 my-1 fs-3">
                {t("info.terms.heading")}
              </CardHeader>
              <CardBody>
                <List className="p-2 my-2">
                  <li className="fw-bold p-2 my-1">{t("info.terms.term0")}</li>
                  <li className="fw-bold p-2 my-1">{t("info.terms.term1")}</li>
                  <li className="fw-bold p-2 my-1">{t("info.terms.term2")}</li>
                  <li className="fw-bold p-2 my-1">{t("info.terms.term3")}</li>
                  <li className="fw-bold p-2 my-1">{t("info.terms.term4")}</li>
                  <li className="fw-bold p-2 my-1">{t("info.terms.term5")}</li>
                  <li className="fw-bold p-2 my-1">{t("info.terms.term6")}</li>
                  <li className="fw-bold p-2 my-1">{t("info.terms.term7")}</li>
                </List>
              </CardBody>
            </Card>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Terms;
