import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DateFunctions } from "../hmehelpers/DateFunctions";
import {
  Toast,
  ToastHeader,
  ToastBody,
  Row,
  Col,
  Button,
  Container,
  List,
  Spinner,
  Input,
} from "reactstrap";
import "../App.css";
import { HBApi } from "../HBApi";
import { BookingFunctions } from "../hmehelpers/BookingFunctions";

const BookingOverlay = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [booking, setBooking] = useState({});
  const [errorResult, setErrorResult] = useState(null);
  const [updateComplete, setUpdateComplete] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cancelError, setCancelError] = useState(null);
  const [toOverlay, setToOverlay] = useState(new Map());
  const [overlays, setOverlays] = useState([]);

  useEffect(() => {
    async function fetchData() {
      var overlayResponse;
      try {
        const bookingResponse = await HBApi.getBookingByIdNoJson(id);
        const bookingJson = await bookingResponse.json();
        overlayResponse = await HBApi.getAdminOverlaysByBooking(id);
        const overlayJson = await overlayResponse.json();
        const toOverlayMap = new Map();
        overlayJson.forEach((ol) => toOverlayMap.set(ol.bookingDayId, true));
        setOverlays(overlayJson);
        setToOverlay(toOverlayMap);
        setBooking(bookingJson);
        setLoading(false);
      } catch (error) {
        console.log(`Caught error `);
        if (!overlayResponse) {
          setErrorResult(t("common.generalerrormessage"));
        } else if (overlayResponse.status === 401) {
          setCancelError(401);
        } else if (overlayResponse.status === 403) {
          setCancelError(403);
        } else {
          setErrorResult(t("common.generalerrormessage"));
        }
        setLoading(false);
      }
    }
    fetchData(); //.then((result) => setLoading(false));
  }, []);

  const handleOverlay = async (event) => {
    event.preventDefault();
    setErrorResult(null);
    setLoading(true);
    console.log(`Will overlay days ${JSON.stringify(toOverlay)}`);
    HBApi.overlayBooking(booking.id, toOverlay, overlays)
      .then((response) => {
        if (response.ok) {
          setLoading(false);
          return response.body;
        } else {
          console.log(`Received error response ${response.status}`);
          return Promise.reject(response);
        }
      })
      .then((success) => {
        setUpdateComplete(true);
      })
      .catch((errorResponse) => {
        setLoading(false);
        if (errorResponse.set === 401) {
          setCancelError(401);
        }
        if (errorResponse.status === 409) {
          setCancelError(409);
        }
        if (errorResponse.status === 412) {
          setCancelError(412);
        }
        if (errorResponse.status === 417) {
          setCancelError(417);
        } else if (errorResponse.status === 423) {
          setCancelError(423); // LOCKED means the booking is historic and therefore cannot be cancelled
        } else if (errorResponse.status === 400) {
          setCancelError(400);
        } else {
          setErrorResult("An error ocurred");
        }
      });
  };
  const Error400Message = () => {
    if (cancelError === 400) {
      return (
        <div>
          <b>{t("bookingoverlay.illegaldaysmessage")}</b>
        </div>
      );
    }
  };

  const hasDisabled = () => {
    return booking.bdvs
      .flatMap((bdv) => bdv.days)
      .find((day) => isDisabled2(day));
  };
  const isDisabled2 = (dayArg) => {
    return false;
    // const todayAtMidnight = new Date();
    // todayAtMidnight.setHours(0, 0, 0, 0, 0);
    // if (
    //   DateFunctions.compareDates(
    //     new Date(Date.parse(dayArg.from)),
    //     todayAtMidnight
    //   ) < 0
    // ) {
    //   return true;
    // } else {
    //   return false;
    // }
  };

  const allDaysDisabled = () => {
    const days = booking.bdvs.flatMap((bdv) => bdv.days);
    try {
      return days.length === days.filter((d) => isDisabled2(d)).length;
    } catch (error) {
      return false;
    }
  };

  const DaysSelect = () => {
    console.log(
      `Enter daysselect with days ${JSON.stringify(toOverlay.entries().next())}`
    );
    return booking.bdvs
      .flatMap((bdv) => bdv.days)
      .map((day, index) => {
        if (isDisabled2(day)) {
          return (
            <li>
              <Input
                id={"cancellation-" + day.id}
                checked={toOverlay.get(day.id)}
                disabled
                type="checkbox"
                onClick={() => {
                  const currentValue = toOverlay.get(day.id);
                  toOverlay.set(day.id, !currentValue);
                  const newMap = new Map(toOverlay);
                  setToOverlay(newMap);
                }}
              ></Input>{" "}
              {DateFunctions.stringToOnlyDateString(day.from)}{" "}
              {day.status === 2 && t("bookingcancellation.alreadycancelled")}
              {day.status === 1 && "(*)"}
            </li>
          );
        } else {
          return (
            <li>
              <Input
                id={"cancellation-" + day.id}
                checked={toOverlay.get(day.id)}
                type="checkbox"
                onClick={() => {
                  const currentValue = toOverlay.get(day.id);
                  toOverlay.set(day.id, !currentValue);
                  const newMap = new Map(toOverlay);
                  setToOverlay(newMap);
                }}
              ></Input>{" "}
              {DateFunctions.stringToOnlyDateString(day.from)}
            </li>
          );
        }
      });
  };

  const SubmitButton = () => {
    if (!allDaysDisabled()) {
      return (
        <Button id="overlayButtonId" color="primary" onClick={handleOverlay}>
          {t("bookingoverlay.confirmbutton")}
        </Button>
      );
    } else {
      return (
        <Button
          id="overlayButtonId"
          disabled
          color="primary"
          onClick={handleOverlay}
        >
          {t("bookingoverlay.confirmbutton")}
        </Button>
      );
    }
  };
  if (loading) {
    return <Spinner />;
  }
  if (cancelError === 401) {
    return <div>{t("common.unauthorizedmessage")}</div>;
  }
  if (cancelError === 403) {
    return <div>{t("common.forbiddenmessage")}</div>;
  }
  if (errorResult) {
    return <div>{errorResult}</div>;
  }
  if (updateComplete) {
    return (
      <div>
        <Container>
          <Row>
            <Col>
              <div className="p-3 my-2 bg-secondary rounded">
                <Toast>
                  <ToastHeader>
                    <b>{t("bookingoverlay.successheading")}</b>
                  </ToastHeader>
                  <ToastBody>
                    <List>
                      <li>{t("bookingoverlay.availabilitymessage")}</li>
                    </List>
                    <List>
                      <DaysSelect />
                    </List>
                    {hasDisabled() && (
                      <>(*) = {t("bookingoverlay.toolatemessage")}</>
                    )}

                    <hr />
                  </ToastBody>
                </Toast>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  } else if (errorResult) {
    return (
      <div>
        <Container>
          <Row>
            <Col>
              <div className="p-3 my-2 bg-danger rounded">
                <Toast>
                  <ToastHeader>
                    <b>{t("bookingcancellation.header")}</b>
                  </ToastHeader>
                  <ToastBody>
                    {errorResult}
                    <hr />
                  </ToastBody>
                </Toast>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  } else if (cancelError === 401) {
    if (cancelError === 423) {
      return (
        <div>
          <Container>
            <Row>
              <Col>
                <div className="p-3 my-2 bg-secondary rounded">
                  <Toast>
                    <ToastHeader>
                      <b>{t("bookingcancellation.notcancellableheader")}</b>
                    </ToastHeader>
                    <ToastBody>
                      <List>
                        <li>
                          {t("bookingcancellation.notcancellablemessage")}
                        </li>
                      </List>
                      <hr />
                    </ToastBody>
                  </Toast>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }
  }

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div className="p-3 my-2 bg-secondary rounded">
              <Toast>
                <ToastHeader>
                  <b>{t("bookingoverlay.heading")}</b>
                </ToastHeader>
                <ToastBody>
                  {t("bookingoverlay.explanation")}
                  <List>
                    <li>
                      {t("common.berthCapital")}{" "}
                      {BookingFunctions.berthsCommaSeparated(booking)}{" "}
                    </li>
                  </List>
                  {t("bookingoverlay.selectdays")}
                  <List>
                    <DaysSelect />
                  </List>
                  {hasDisabled() && (
                    <>(*) = {t("bookingoverlay.toolatemessage")}</>
                  )}
                  <Error400Message />
                  <SubmitButton />

                  <hr />
                </ToastBody>
              </Toast>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default BookingOverlay;
