import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Label,
  Input,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Row,
  Col,
  Button,
  Container,
  InputGroup,
  Spinner,
  List,
} from "reactstrap";
import "./App.css";
import AppNavbarLight from "./AppNavbarLight";
import { CountryDropdown } from "react-country-region-selector";
import { useAuth } from "./AuthContext";
import { HBApi } from "./HBApi";
import { DateFunctions } from "./hmehelpers/DateFunctions";
import { HarbourFunctions } from "./hmehelpers/HarbourFunctions";
import { BookingFunctions } from "./hmehelpers/BookingFunctions";
import BookingDetailsElectricitySection from "./BookingDetailsElectricitySection";
import { useSelector } from "react-redux";

const br = "8px";

const BookingDetails = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const auth = useAuth();
  const bookingInformation = useSelector((store) => store.booking);
  const ui = useSelector((store) => store.userinformation);
  const [bookingDryRun, setBookingDryRun] = useState([]);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState(null);

  let priorityCountries = ["Sweden", "Denmark"];
  const currency = () => bookingDryRun[0].currency;
  const cancellableDaysCount = () =>
    bookingDryRun
      .map((dr) => dr.cancellableDaysCount)
      .reduce((l, r) => l + r, 0);

  let harbour = bookingInformation.harbour;

  // In case of cached criteria, the arrival/departure format is 2023-10-01T22:00:00.000Z - will not be a date
  let criteria = {
    length: bookingInformation.length,
    width: bookingInformation.width,
    depth: bookingInformation.depth,
    arrival: new Date(bookingInformation.arrival),
    departure: new Date(bookingInformation.departure),
    segments: bookingInformation.segments,
  };
  console.log(`Criteria : ${JSON.stringify(criteria)}`);
  let segments = bookingInformation.segments;

  console.log(
    `BookingDetails constructed segments: ${JSON.stringify(segments)}`
  );

  const dayCount = () =>
    bookingDryRun.map((dr) => dr.days.length).reduce((l, r) => l + r, 0);
  console.log(
    "Enter booking details with user information: " +
      JSON.stringify(ui.userinformation)
  );
  console.log(
    "Enter booking details with prefilled boat information: " +
      JSON.stringify(bookingInformation.boatInfo)
  );
  console.log(
    `Condition to populate is : ${JSON.stringify(
      ui.userinformation && bookingInformation.boatInfo
    )}`
  );
  let bd =
    ui.userinformation && ui.boatinformation
      ? {
          electricity: false,
          cancellation: false,
          boattype: ui.boatinformation[0]
            ? ui.boatinformation[0].boatType
            : "SAIL",
          boatmodel: ui.boatinformation[0]?.boatModel,
          boatname: ui.boatinformation[0]?.boatName,
          email: ui.userinformation.email,
          firstName: ui.userinformation.firstName,
          lastName: ui.userinformation.lastName,
          phone: ui.userinformation.phone,
          country: ui.userinformation.country,
        }
      : { electricity: false, cancellation: false, boattype: "SAIL" };
  const [bookingDetails, setBookingDetails] = useState(bd);

  useEffect(() => {
    console.log("Enter useEffect, will fetch dryruns");
    async function fetchDryRun() {
      const dryRunResult = await HBApi.bookingDryRun(segments, criteria);
      console.log(
        `Fetched dryRunResult in useEffect  ${JSON.stringify(dryRunResult)}`
      );
      setBookingDryRun(dryRunResult);
    }

    fetchDryRun().then((result) => {
      setLoading(false);
    });
  }, []);

  const handleChange = async (event) => {
    const { name, value } = event.target;
    console.log(
      "Handle change with name: " +
        name +
        " and value: " +
        value +
        " old object:" +
        JSON.stringify(bookingDetails)
    );
    setBookingDetails({ ...bookingDetails, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    console.log(
      "Clicked Proceed and booking details is:" +
        JSON.stringify({ segments, bookingDetails })
    );
    const boatDetails = {
      boatType: bookingDetails.boattype,
      boatModel: bookingDetails.boatmodel,
      boatName: bookingDetails.boatname,
      length: criteria.length,
      width: criteria.width,
      depth: criteria.depth,
    };

    HBApi.bookWithPayment(segments, bookingDetails, boatDetails)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return Promise.reject(response);
      })
      .then((json) => {
        console.log(
          "Received booking confirmation from wrapper csrf function: " +
            JSON.stringify(json)
        );
        window.location = json.paymentUrl;
      })
      .catch((errorResponse) => {
        console.log("errorResponse" + errorResponse.status);
        if (errorResponse.status === 409) {
          console.log(
            "409 response, this will happen as concurrent booking has been done"
          );
          navigate("/bookingconflict");
        } else if (errorResponse.status === 412) {
          console.log("412 response, this is a temporary error");
          setErrorStatus(412);
        }
      });
  };

  const handleSigninSubmit = async (event) => {
    event.preventDefault();
    console.log("handleSigninSubmit");
    navigate("/signin", {
      state: {
        nextpage: "/bookingdetails",
        trail: segments,
        harbour: harbour,
        criteria: criteria,
      },
    });
  };

  const SignInButton = () => {
    if (!auth.userIsAuthenticated()) {
      return (
        <Button
          id="signInButton"
          size="md"
          color="primary"
          type="submit"
          onClick={handleSigninSubmit.bind(this)}
        >
          {t("bookingdetails.loginbutton")}
        </Button>
      );
    }
  };
  const EmailFormFeedback = () => {
    if (emailInvalid) {
      return <FormFeedback>{t("commonvalidation.invalidemail")}</FormFeedback>;
    }
  };
  const asyncEmailVerification = async (event) => {
    event.preventDefault();
    console.log(`asynchronous email verification`);
    HBApi.validateEmail(bookingDetails.email).then((response) => {
      if (response.status === 400) {
        console.log("Email invalid");
        setEmailInvalid(true);
      } else if (response.ok) {
        console.log("Email valid");
        setEmailInvalid(false);
      }
    });
  };
  const formReadyForValidation = () => {
    if (emailInvalid) return false;
    if (!bookingDetails.country || bookingDetails.country === "") return false;
    if (
      !bookingDetails.email ||
      !bookingDetails.firstName ||
      !bookingDetails.lastName ||
      !bookingDetails.phone ||
      !bookingDetails.crewCount ||
      !bookingDetails.boatmodel ||
      !bookingDetails.boatname
    )
      return false;
    return true;
  };

  const BookButton = () => {
    if (formReadyForValidation()) {
      return (
        <Button
          id="bookButtonId"
          size="lg"
          color="primary"
          type="submit"
          onClick={handleSubmit.bind(this)}
        >
          {t("bookingdetails.proceedButton")}
        </Button>
      );
    } else {
      return (
        <Button disabled size="lg" color="primary" type="submit">
          {t("bookingdetails.proceedButton")}
        </Button>
      );
    }
  };

  const totalPrice = () => {
    var rv = bookingDryRun
      .map((dr) => dr.totalBerthPrice)
      .reduce((l, r) => l + r, 0);
    if (bookingDetails.cancellation) {
      rv = bookingDryRun
        .map((dr) => dr.priceWithCancellation)
        .reduce((l, r) => l + r, 0);
    }
    if (bookingDetails.electricity) {
      rv += dayCount() * bookingDryRun[0].electricityPricePerDay;
    }

    return rv;
  };

  const BerthsOrTrailLabel = () => {
    if (segments.length === 1) {
      return (
        <Label tag="h6">
          {t("common.berthCapital")} {segments[0].label}
        </Label>
      );
    } else {
      return (
        <Label tag="h6">
          {t("bookingdetails.traillabel", { count: segments.length })}{" "}
          {BookingFunctions.berthsCommaSeparatedForSegments(segments)}
        </Label>
      );
    }
  };

  const AddonsPricePerDay = ({ day, dryRun }) => {
    console.log(
      `AddonsPricePerDay with day : ${JSON.stringify(day)} and cancellable: ${
        day.initialCancellableStatus
      }`
    );
    const c = day.initialCancellableStatus;
    switch (true) {
      case bookingDetails.cancellation === true &&
        bookingDetails.electricity === true &&
        c === 1:
        return `+ ${dryRun.cancellationPricePerDay} ${dryRun.currency} ${t(
          "common.cancellationFeeCapital"
        )} + ${dryRun.electricityPricePerDay} ${dryRun.currency} ${t(
          "common.electricityCapital"
        )}`;
      case bookingDetails.cancellation === true &&
        bookingDetails.electricity === true &&
        c === 0:
        return `+ ${dryRun.electricityPricePerDay} ${dryRun.currency} ${t(
          "common.electricityCapital"
        )}`;
      case bookingDetails.cancellation === false &&
        bookingDetails.electricity === true:
        return `+ ${dryRun.electricityPricePerDay} ${dryRun.currency} ${t(
          "common.electricityCapital"
        )}`;
      case bookingDetails.cancellation === true &&
        bookingDetails.electricity === false &&
        c === 1:
        return `+ ${dryRun.cancellationPricePerDay} ${dryRun.currency} ${t(
          "common.cancellationFeeCapital"
        )}`;
      default:
        return "";
    }
  };
  function pricePerDayKey(day, segmentNo) {
    return `price-segment-${segmentNo}-day-${day.ordering}-id`;
  }
  const Day = ({ dryRun, segmentNo }) => {
    return dryRun.days.map((day) => (
      <li
        key={pricePerDayKey(day, segmentNo)}
        id={pricePerDayKey(day, segmentNo)}
      >
        {DateFunctions.stringToDateString(day.from)},{" "}
        {day.berthPrice + day.bookingPrice} {day.currency}{" "}
        <AddonsPricePerDay day={day} dryRun={dryRun} />
      </li>
    ));
  };
  const Segment = () => {
    return bookingDryRun.map((dr, index) => {
      return (
        <FormGroup row>
          <Col>
            <Label>
              <b>
                {t("bookingdetails.segmentDetailsHeading", {
                  index: segments[index].label,
                  count: dr.days.length,
                })}
              </b>{" "}
            </Label>
            <List>
              <Day dryRun={dr} segmentNo={index} />
            </List>
          </Col>
          <Col></Col>
        </FormGroup>
      );
    });
  };

  if (loading) {
    return (
      <Container>
        <Row>
          <Col md="12">
            <AppNavbarLight />
          </Col>
        </Row>
        <Row>
          <Spinner>Loading</Spinner>
        </Row>
      </Container>
    );
  }
  return (
    <Container>
      <Row>
        <Col md="12">
          <h1 align="center">{t("bookingdetails.heading")}</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <img
            src={HarbourFunctions.imagePath(harbour.image)}
            alt="harbour image"
            style={{ borderRadius: "10px" }}
          />
        </Col>
      </Row>
      <Row>
        <Form className="bg-light border">
          <h2 align="center">{harbour.name}</h2>
          <FormGroup row>
            <Col>
              <BerthsOrTrailLabel />
            </Col>
            <Col>
              <Label tag="h6">
                {t("common.arrivalCapital")}:{" "}
                {DateFunctions.dateString(criteria.arrival)}
              </Label>
            </Col>
            <Col>
              <Label tag="h6">
                {t("common.departureCapital")}:{" "}
                {DateFunctions.dateString(criteria.departure)}
              </Label>
            </Col>
            <Col>
              <Label tag="h6">
                {t("common.fixationTypeCapital")}:{" "}
                {t(`common.${segments[0].fixationTypeLabel}`)}
              </Label>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col>
              <Label id="dimensionsLabel" tag="h6">
                {t("bookingdetails.dimensions")}: {criteria.length}m/
                {criteria.width}m/{criteria.depth}m
              </Label>
            </Col>
          </FormGroup>

          <BookingDetailsElectricitySection
            bookingDryRun={bookingDryRun}
            bookingDetails={bookingDetails}
            setBookingDetails={setBookingDetails}
          />
          {cancellableDaysCount() > 0 && (
            <FormGroup row switch>
              <Col>
                <Label for="cancellation">
                  {t("common.cancellationFeeCapital")}{" "}
                  <b>
                    {bookingDryRun[0].cancellationPricePerDay} {currency()}{" "}
                  </b>
                  {t("common.perDay")}{" "}
                </Label>
                <Input
                  id="cancellation"
                  checked={bookingDetails.cancellation}
                  type="switch"
                  role="cancellation"
                  onClick={() =>
                    setBookingDetails({
                      ...bookingDetails,
                      cancellation: !bookingDetails.cancellation,
                    })
                  }
                />
                <FormText>({t("bookingdetails.cancellationInfo")})</FormText>
              </Col>
            </FormGroup>
          )}
          {cancellableDaysCount() > 0 &&
            cancellableDaysCount() < dayCount() && (
              <FormGroup row>
                <Col>
                  <b>{t("bookingdetails.notallcancellableMessage")}</b>{" "}
                  {bookingDetails.cancellation &&
                    t("bookingdetails.seeDetailsBelow")}
                </Col>
              </FormGroup>
            )}
          {cancellableDaysCount() === 0 && (
            <FormGroup row>
              <Col>
                <b>{t("common.noteCapital")}:</b>{" "}
                {t("bookingdetails.noCancellableDays")}
              </Col>
            </FormGroup>
          )}
          <FormGroup row>
            <Col>
              <Label>
                <b>
                  {t("bookingdetails.totalPriceNDays", { count: dayCount() })}
                </b>{" "}
              </Label>
              <FormText> {t("bookingdetails.totalPriceInfo")}</FormText>
            </Col>
            <Col>
              <Label>
                <b>
                  {totalPrice()} {currency()} ({t("common.inclVAT")})
                </b>
              </Label>
            </Col>
          </FormGroup>
          <Segment />
          <FormGroup row>
            <Col>
              <Label bssize="md" for="email">
                Email
              </Label>
              <InputGroup>
                {emailInvalid ? (
                  <Input
                    style={{ borderRadius: br }}
                    value={bookingDetails.email}
                    id="email"
                    bssize="md"
                    type="email"
                    name="email"
                    invalid
                    onChange={handleChange}
                    onBlur={asyncEmailVerification}
                  />
                ) : (
                  <Input
                    style={{ borderRadius: br }}
                    value={bookingDetails.email}
                    id="email"
                    bssize="md"
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={asyncEmailVerification}
                  />
                )}
                <EmailFormFeedback />
              </InputGroup>
              <SignInButton />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col>
              <Label for="firstName">{t("common.firstname")}</Label>
              <Input
                style={{ borderRadius: br }}
                value={bookingDetails.firstName}
                id="firstName"
                bssize="md"
                type="text"
                name="firstName"
                minLength="2"
                maxLength="100"
                onChange={handleChange}
              />
            </Col>
            <Col>
              <Label for="lastName">{t("common.lastname")}</Label>
              <Input
                style={{ borderRadius: br }}
                value={bookingDetails.lastName}
                id="lastName"
                bssize="md"
                type="text"
                name="lastName"
                minLength="2"
                maxLength="100"
                onChange={handleChange}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col>
              <Label for="phone">{t("common.phone")}</Label>
              <PhoneInput
                style={{
                  borderRadius: br,
                  backgroundColor: "white",
                  height: "40px",
                }}
                id="phone"
                defaultCountry="SE"
                placeholder={t("common.phoneDefaultOption")}
                value={bookingDetails.phone}
                onChange={(val) =>
                  handleChange({ target: { name: "phone", value: val } })
                }
              />
            </Col>
            <Col>
              <Label for="country">{t("common.country")}</Label>
              <InputGroup>
                <CountryDropdown
                  style={{
                    borderRadius: br,
                    backgroundColor: "white",
                    height: "40px",
                  }}
                  id="country"
                  name="country"
                  value={bookingDetails.country}
                  priorityOptions={priorityCountries}
                  onChange={(val) =>
                    handleChange({ target: { name: "country", value: val } })
                  }
                />
                <FormFeedback>Please select country</FormFeedback>
              </InputGroup>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col>
              <Label for="crewCount">{t("bookingdetails.crewcount")}</Label>
              <Input
                style={{ borderRadius: br }}
                value={bookingDetails.crewcount}
                id="crewCount"
                bssize="md"
                type="number"
                min="1"
                name="crewCount"
                onChange={handleChange}
              />
            </Col>
            <Col>
              <Label for="boattype">{t("common.boattype")}</Label>
              <Input
                style={{ borderRadius: br }}
                value={bookingDetails.boattype}
                id="boattype"
                bssize="md"
                type="select"
                name="boattype"
                onChange={handleChange}
              >
                <option key="sail" value="sail">
                  {t("common.sailOption")}
                </option>
                <option key="motor" value="motor">
                  {t("common.motorOption")}
                </option>
              </Input>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col>
              <Label for="boatmodel">{t("common.boatmodel")}</Label>
              <Input
                style={{ borderRadius: br }}
                id="boatmodel"
                value={bookingDetails.boatmodel}
                bssize="md"
                type="text"
                name="boatmodel"
                minLength="2"
                maxLength="100"
                onChange={handleChange}
              />
            </Col>
            <Col>
              <Label for="boatname">{t("common.boatname")}</Label>
              <Input
                style={{ borderRadius: br }}
                id="boatname"
                value={bookingDetails.boatname}
                bssize="md"
                type="text"
                name="boatname"
                minLength="2"
                maxLength="100"
                onChange={handleChange}
              />
            </Col>
          </FormGroup>
          <FormGroup>
            {errorStatus === 412 && (
              <div>
                <b>{t("bookingdetails.tryagainmessage")}</b>
              </div>
            )}
            <BookButton />
          </FormGroup>
        </Form>
      </Row>
    </Container>
  );
};

export default BookingDetails;
