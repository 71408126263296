import i18n from "../i18n";
import { addDays, addHours } from "date-fns";
import { maxBookingAheadDays } from "../HBConstants";
export const DateFunctions = {
  dateString,
  onlyDateString,
  stringToDateString,
  stringToOnlyDateString,
  stringToTimeString,
  compareDateStrings,
  compareDates,
  spannedBy,
  spannedByInclusiveFrom,
  stringToWithCustomEndTimeHoursDateString,
  unavailableDates,
  containsDate,
};

export function stringToWithCustomEndTimeHoursDateString(date) {
  let d = new Date(Date.parse(date));
  if (d.getHours() === 12) {
    return stringToDateString(date);
  } else {
    return `${stringToDateString(date)} ${i18n.t(
      "common.attimepreposition"
    )} ${d.getHours()}.00`;
  }
}
export function stringToDateString(dateString) {
  return DateFunctions.dateString(new Date(Date.parse(dateString)));
}
export function stringToOnlyDateString(dateString) {
  return DateFunctions.onlyDateString(new Date(Date.parse(dateString)));
}
function dateString(date) {
  return date.toLocaleDateString(i18n.language, {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
}
function onlyDateString(dateTime) {
  return new Date(dateTime).toLocaleDateString(i18n.language, {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}
export const onlyDateOptions = {
  weekday: "short",
  year: "numeric",
  month: "short",
  day: "numeric",
};
function stringToTimeString(dateString) {
  return new Date(Date.parse(dateString)).toLocaleTimeString(i18n.language, {
    hour: "2-digit",
    minute: "2-digit",
  });
}

function compareDateStrings(l, r) {
  const left = new Date(Date.parse(l));
  const right = new Date(Date.parse(r));
  return left.getTime() - right.getTime();
}
function compareDates(l, r) {
  return l.getTime() - r.getTime();
}
function spannedBy(candidateDate, fromString, toString) {
  const fromDate = new Date(Date.parse(fromString));
  const from = fromDate.getTime();
  const toDate = new Date(Date.parse(toString));
  const to = toDate.getTime();
  return candidateDate.getTime() < to && candidateDate.getTime() > from;
}
function spannedByInclusiveFrom(candidateDate, fromString, toString) {
  const fromDate = new Date(Date.parse(fromString));
  const from = fromDate.getTime();
  const toDate = new Date(Date.parse(toString));
  const to = toDate.getTime();
  return candidateDate.getTime() < to && candidateDate.getTime() >= from;
}
// ud array of Dates
// selectedDate the selected arrival date
function unavailableDates(ud, selectedDate) {
  const selectedDateAsDate = new Date(Date.parse(selectedDate));
  if (ud.length === 0) {
    console.log(`DateFunctions unavailableDates with empty ud`);
    return [];
  } else {
    console.log(
      `DateFunctions unavailableDates with ${ud.length} unavailable dates`
    );
    const greater = ud.filter(
      (d) => d.getTime() > selectedDateAsDate.getTime()
    );
    if (greater.length === 0) {
      console.log(
        `There is no unavailable date later than the selected date ${JSON.stringify(
          selectedDateAsDate
        )}`
      );
      return ud;
    } else {
      const sorted = greater.sort((l, r) => l.getTime() - r.getTime());
      console.log(
        `There is at least one (${
          greater.length
        }) unavailable date later than the selected date: ${JSON.stringify(
          selectedDateAsDate
        )}, sorted : ${JSON.stringify(sorted)}`
      );
      const firstUnavailable = addDays(sorted[0], 1);
      console.log(
        `first unavailable for departure selection : ${JSON.stringify(
          firstUnavailable
        )}`
      );
      const udUpUntilFirstUnavailable = ud.filter(
        (d) => d.getTime() < sorted[0].getTime()
      );
      console.log(
        `udUpUntilFirstUnavailable: ${JSON.stringify(
          udUpUntilFirstUnavailable
        )}`
      );
      return udUpUntilFirstUnavailable.concat(
        [firstUnavailable].concat(
          Array.from({ length: maxBookingAheadDays }, (_, i) => i + 1).map(
            (index) => addDays(firstUnavailable, index)
          )
        )
      );
    }
  }
}
// Assume dateStrings have the hour 12 and is a localdatetime
function containsDate(dateStrings, candidate) {
  if (dateStrings.length === 0) {
    return false;
  } else {
    const candidateAsDate = new Date(Date.parse(candidate));
    const theHour = candidateAsDate.getHours();
    console.log(`Candidate hour : ${theHour}`);
    const candidateAsDateModifiedHour =
      theHour === 0 ? addHours(candidateAsDate, 12) : candidateAsDate;
    const rv = dateStrings.find(
      (d) => Date.parse(d) === candidateAsDateModifiedHour.getTime()
    );
    // console.log(
    //   `containsDate with ${JSON.stringify(
    //     dateStrings
    //   )}, candidate: ${JSON.stringify(candidate)}, yields ${rv}`
    // );
    return rv !== undefined;
  }
}
