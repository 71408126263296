import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { bookingSliceName } from "./booking/bookingreducer";
import { userSliceName } from "./user/userreducer";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { thunk } from "redux-thunk";
//import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

const persistConfig = {
  key: "root",
  storage,
  //stateReconciler: autoMergeLevel2,
};

const rootReducer = combineReducers({
  booking: bookingSliceName,
  userinformation: userSliceName,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: () => [thunk],
});

export const persistor = persistStore(store);
export default store;
