import "leaflet/dist/leaflet.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import { sv } from "date-fns/locale";
import { addDays, addHours } from "date-fns";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardTitle,
  CardText,
  Label,
  Input,
  Form,
  FormGroup,
  Row,
  Col,
  Container,
  Spinner,
} from "reactstrap";
import { maxBookingAheadDays } from "./HBConstants";
import HarbourMap from "./HarbourMap";
import BerthOrTrail from "./booking/BerthOrTrail";
import AppNavbarLight from "./AppNavbarLight";
import { HBApi } from "./HBApi";
import { BerthFunctions } from "./hmehelpers/BerthFunctions";
import { BookingFunctions } from "./hmehelpers/BookingFunctions";
import "./App.css";

import { Suspense } from "react";
import { HarbourFunctions } from "./hmehelpers/HarbourFunctions";
import {
  dimension,
  harbour,
  berths,
  offerDates,
  initUnavailableDates,
} from "./booking/bookingreducer";
import { useDispatch, useSelector } from "react-redux";
import { DateFunctions } from "./hmehelpers/DateFunctions";

const br = "8px";

const Harbour = () => {
  registerLocale("sv", sv);

  const { id } = useParams();

  const { t, i18n } = useTranslation();
  const [coords, setCoords] = useState([0, 0]);
  const [loading, setLoading] = useState(true);
  const [selectedBerth, setSelectedBerth] = useState();
  const searchObject = useSelector((store) => store.booking);
  const userInformation = useSelector((store) => store.userinformation);

  const dispatch = useDispatch();
  // We search again if the dates have changed, or if the dimensions have changed and the change in dimension
  // leads to a different subset of compatible berths
  function logFriendlySearchObject(so) {
    return {
      arrival: so.arrival,
      departure: so.departure,
      length: so.length,
      width: so.width,
      depth: so.depth,
      lengthInput: so.lengthInput,
      widthInput: so.widthInput,
      depthInput: so.depthInput,
    };
  }

  var today = new Date();
  var maxDate = addDays(today, maxBookingAheadDays);

  useEffect(() => {
    console.log(
      `Enter useEffect() , searchObject is ${JSON.stringify(
        logFriendlySearchObject(searchObject)
      )}`
    );
    setLoading(true);

    async function loadData() {
      const berthResponse = await HBApi.getBerthsForHarbour(id);
      const data = await berthResponse.json();
      dispatch(berths(data));
      console.log(
        `Received ${data.length} berths for harbour ${id} in useEffect`
      );
      const harbourResponse = await HBApi.getHarbourById(id);
      const harbourData = await harbourResponse.json();
      const berthConfigurationResponse =
        await HBApi.getBerthConfigurationByHarbour(id);
      const berthConfiguration = await berthConfigurationResponse.json();
      dispatch(
        harbour({
          ...harbourData,
          twinBerthsSupported: berthConfiguration.twinBerthsSupported,
        })
      );
      dispatch(initUnavailableDates(id));
      setCoords([harbourData.latitude, harbourData.longitude]);

      if (userInformation && userInformation.boatinformation) {
        console.log(
          `There is boatinformation to be considered for input fields ${JSON.stringify(
            userInformation.boatinformation
          )}`
        );
        if (!searchObject.lengthInput) {
          const bi = userInformation.boatinformation[0];
          console.log(
            `No length entered previously, this will be pre-populated with boat information`
          );
          dispatch(dimension({ name: "length", value: bi.length }));
          dispatch(dimension({ name: "width", value: bi.width }));
          dispatch(dimension({ name: "depth", value: bi.depth }));
        }
      }
    }
    loadData().then(() => setLoading(false));
  }, []);

  const Loading = () => {
    return (
      <Container>
        <Row>
          <Col md="12">
            <AppNavbarLight />
          </Col>
        </Row>
        <Row>
          <Spinner>Loading</Spinner>
        </Row>
      </Container>
    );
  };

  if (loading) {
    return <Loading />;
  }

  const shouldDisplayDPF = () => {
    console.log(
      `Enter shouldDisplayDPF with searchObject ${JSON.stringify(
        logFriendlySearchObject(searchObject)
      )}`
    );
    return (
      BerthFunctions.boatDimensionsValid(
        searchObject.lengthInput,
        searchObject.widthInput,
        searchObject.depthInput
      ) &&
      BerthFunctions.berthsCompatible(
        searchObject.berths,
        searchObject.lengthInput,
        searchObject.widthInput,
        searchObject.depthInput
      )
    );
  };

  const BookingDatePicker = () => {
    console.log(
      `Datepicker to be rendered using startDate: ${searchObject.arrival} and endDate: ${searchObject.departure}`
    );
    return (
      <div
        id="datepickerDiv"
        style={{ display: shouldDisplayDPF() ? "block" : "none" }}
      >
        <Label for="arrival">{t("book.arrdep")}</Label>
        <div>
          <DatePicker
            id="arrival"
            locale={i18n.language}
            minDate={today}
            maxDate={maxDate}
            startDate={
              searchObject.arrival ? new Date(searchObject.arrival) : undefined // This is due to the string/date distinction
            }
            endDate={searchObject.departure}
            selectsRange={true}
            inline
            onChange={onDateChange}
            selectsDisabledDaysInRange={false}
            excludeDates={BookingFunctions.calcExcludedDays(
              searchObject.arrival,
              searchObject.departure,
              searchObject.unavailableDates
            )}
          />
        </div>
      </div>
    );
  };
  const dimensionsMessage = () => {
    if (
      !BerthFunctions.boatDimensionsValid(
        searchObject.lengthInput,
        searchObject.widthInput,
        searchObject.depthInput
      )
    ) {
      return <div id="calendarMessage">{t("book.dimensionsmessage")}</div>;
    } else if (
      !BerthFunctions.berthsCompatible(
        searchObject.berths,
        searchObject.length,
        searchObject.width,
        searchObject.depth
      )
    ) {
      return (
        <div id="calendarMessage">
          {t("book.noberthsforyourdimensionsmessage")}
        </div>
      );
    }
  };

  const handleChange = async (event) => {
    const { name, value } = event.target;
    console.log(
      "Handle change with name: " +
        name +
        " and value: " +
        value +
        ", bearthSearchResult is: " +
        searchObject.berthSearchResult.length
    );
    dispatch(dimension({ name: name, value: value }));
  };

  const onDateChange = (datesIn) => {
    console.log(`Enter onDateChange with datesIn ${JSON.stringify(datesIn)}`);
    dispatch(offerDates(datesIn));
  };

  return (
    <Suspense fallback={<Loading />}>
      <Container>
        <Row>
          <Col>
            <Label for="img0" tag="h1">
              {searchObject.harbour.name}
            </Label>
            <img
              src={HarbourFunctions.imagePath(searchObject.harbour.image)}
              id="harbourImageId"
              style={{ borderRadius: "10px" }}
            />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Card>
              <CardTitle tag="h5">{t("book.harbourdesc")}</CardTitle>
              <CardText>{t("harbours." + searchObject.harbour.id)}</CardText>
            </Card>
          </Col>
          <Col md="6">
            <Form className="bg-light border">
              <Label size="lg">{t("book.bdim")}</Label>
              <Row>
                <Col>
                  <FormGroup floating>
                    <Input
                      style={{ borderRadius: br }}
                      placeholder="Length"
                      value={searchObject.lengthInput}
                      type="number"
                      name="length"
                      id="length"
                      onChange={handleChange}
                      autoComplete="length"
                    />
                    <Label for="length" size="sm">
                      {t("common.length")}
                    </Label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup floating>
                    <Input
                      style={{ borderRadius: br }}
                      placeholder="Width"
                      value={searchObject.widthInput}
                      type="number"
                      name="width"
                      id="width"
                      onChange={handleChange}
                      autoComplete="width"
                    />
                    <Label for="width" size="sm">
                      {t("common.width")}
                    </Label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup floating>
                    <Input
                      style={{ borderRadius: br }}
                      placeholder="Depth"
                      value={searchObject.depthInput}
                      type="number"
                      name="depth"
                      id="depth"
                      onChange={handleChange}
                      autoComplete="depth"
                    />
                    <Label for="depth" size="sm">
                      {t("common.depth")}
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    {dimensionsMessage()}

                    <BookingDatePicker />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col>
            <HarbourMap
              coords={coords}
              selectedBerth={selectedBerth}
              setSelectedBerth={setSelectedBerth}
              berthSearchResult={searchObject.berthSearchResult}
              trailSearchResult={searchObject.trailSearchResult}
            />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <BerthOrTrail
              selectedBerth={selectedBerth}
              setSelectedBerth={setSelectedBerth}
              submitDestination={"/bookingdetails"}
            />
          </Col>
        </Row>
      </Container>
    </Suspense>
  );
};

export default Harbour;
