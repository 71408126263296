export const HarbourFunctions = {
  thumbnailPath,
  imagePath,
};
function thumbnailPath(imageName) {
  return `/images/harbours/thumbnails/${imageName.substring(
    0,
    imageName.length - 5
  )}-tn.webp`;
}

function imagePath(imageName) {
  return "/images/harbours/" + imageName;
}
