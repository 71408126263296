import { Container, Row, Col, Spinner } from "reactstrap";

import React, { useState, useEffect } from "react";
import { HBApi } from "../HBApi";
import { addDays, subDays } from "date-fns";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = (chartName) => ({
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: chartName,
    },
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
});

const AvailabilitySearchChart = ({ data }) => {
  return <Bar options={options("Availability search")} data={data} />;
};
const MemberAvailabilityOperationsChart = ({ data }) => {
  return <Bar options={options("Availability operations")} data={data} />;
};

const UserActivitystats = () => {
  const [availabilitySearchDataset, setAvailabilitySearchDataset] = useState(
    {}
  );
  const [availabilityOperationsDatset, setAvailabilityOperationsDatset] =
    useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const from = subDays(new Date(), 30).toISOString();
    const to = addDays(new Date(), 1).toISOString();
    async function fetchSearchData() {
      const dataSetResult = await HBApi.adminStatsAvailabilitySearchPerDay(
        from,
        to
      );
      const dataJson = await dataSetResult.json();
      console.log(`Fetched data in useEffect ${dataJson.name}`);
      //DataSet(String name, List<Datum> data)
      // Datum(String label, List<Integer> data)
      const labels = dataJson.data.map((datum) => datum.label);
      const positiveHitsCounts = dataJson.data.map((datum) => datum.data[0]);
      const zeroHitCounts = dataJson.data.map((datum) => datum.data[1]);
      setAvailabilitySearchDataset({
        labels,
        datasets: [
          {
            label: "Searches with positive hits",
            data: positiveHitsCounts,
            borderWidth: 1,
            backgroundColor: "rgba(53, 162, 235, 0.5)",
          },
          {
            label: "Searches with no hits",
            data: zeroHitCounts,
            borderWidth: 1,
            backgroundColor: "rgba(255, 99, 132, 0.5)",
          },
        ],
      });
    }
    async function fetchBerthAvailabilityOperationsData() {
      const dataSetResult =
        await HBApi.adminStatsBerthAvailabilityOperationsByDay(from, to);
      const dataJson = await dataSetResult.json();
      console.log(`Fetched data in useEffect ${dataJson.name}`);
      //DataSet(String name, List<Datum> data)
      // Datum(String label, List<Integer> data)
      const labels = dataJson.data.map((datum) => datum.label);
      const available = dataJson.data.map((datum) => datum.data[0]);
      const nonavailable = dataJson.data.map((datum) => datum.data[1]);
      //0, 155, 50 green
      setAvailabilityOperationsDatset({
        labels,
        datasets: [
          {
            label: "Register availability",
            data: available,
            borderWidth: 1,
            backgroundColor: "rgba(0, 155, 50, 0.5)",
          },
          {
            label: "Register non-availability",
            data: nonavailable,
            borderWidth: 1,
            backgroundColor: "rgba(255, 99, 132, 0.5)",
          },
        ],
      });
    }

    fetchSearchData().then((result) => {
      fetchBerthAvailabilityOperationsData().then((res) => {
        setLoading(false);
      });
    });
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Container>
      <Row>
        <Col>
          <h3>Availability search</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <AvailabilitySearchChart data={availabilitySearchDataset} />
        </Col>
      </Row>
      <Row>
        <Col>
          <h3>Member availability operations</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <MemberAvailabilityOperationsChart
            data={availabilityOperationsDatset}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default UserActivitystats;
