import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import { BerthFunctions } from "../hmehelpers/BerthFunctions";
import { maxBookingAheadDays } from "../HBConstants";
import { HBApi } from "../HBApi";
import { addDays } from "date-fns";
import { DateFunctions } from "../hmehelpers/DateFunctions";
const initialState = {
  lengthInput: "",
  widthInput: "",
  depthInput: "",
  searchCount: 0,
  berthSearchResult: [],
  trailSearchResult: [],
  loading: false,
};

const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    loading(state, action) {
      state.loading = action.payload.state;
    },
    berthSearchResult(state, action) {
      state.berthSearchResult = action.payload;
      state.trailSearchResult = [];
    },
    trailSearchResult(state, action) {
      state.berthSearchResult = [];
      state.trailSearchResult = action.payload;
    },
    dimensionsValid(state, action) {
      state.length = action.payload.lengthInput;
      state.width = action.payload.widthInput;
      state.depth = action.payload.depthInput;
    },
    dimensionInvalid(state, action) {
      console.log(
        `Enter dimensionInvalid with action ${JSON.stringify(action)}`
      );
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    },
    unavailableDates(state, action) {
      // console.log(
      //   `Enter bookingslice unavailableDates with ${JSON.stringify(
      //     action.payload
      //   )}`
      // );
      state.unavailableDates = action.payload;
    },
    dates(state, action) {
      console.log(
        `Enter bookingslice dates with action ${JSON.stringify(action)}`
      );
      state.arrival = action.payload.arrival;
      state.departure = action.payload.departure;
    },
    segments(state, action) {
      console.log(
        `Enter bookingslice segments with action ${JSON.stringify(action)}`
      );
      state.segments = action.payload;
    },
    harbour(state, action) {
      state.harbour = action.payload;
    },
    berths(state, action) {
      //console.log(`Enter bookingslice berths with: ${JSON.stringify(action)}`);
      state.berths = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      return initialState;
    });
  },
});
function logFriendlySearchObject(so) {
  return {
    arrival: so.arrival,
    departure: so.departure,
    length: so.length,
    width: so.width,
    depth: so.depth,
    lengthInput: so.lengthInput,
    widthInput: so.widthInput,
    depthInput: so.depthInput,
  };
}

function shouldSearchAgain(lastSearchObject, newSearchObject, harbour) {
  console.log(
    `Enter shouldSearchAgain with lastSearchObject: ${JSON.stringify(
      logFriendlySearchObject(lastSearchObject)
    )} and newSearchObject: ${JSON.stringify(
      logFriendlySearchObject(newSearchObject)
    )}`
  );
  if (!newSearchObject.departure) {
    return false;
  } else {
    const a = BerthFunctions.berthsCompatibleList(
      lastSearchObject.berths,
      lastSearchObject.length,
      lastSearchObject.width,
      lastSearchObject.depth
    ).map((b) => b.id);
    const b = BerthFunctions.berthsCompatibleList(
      lastSearchObject.berths,
      newSearchObject.lengthInput,
      newSearchObject.widthInput,
      newSearchObject.depthInput
    ).map((b) => b.id);
    const sameCompatibleBerths = a.sort().toString() === b.sort().toString();
    if (sameCompatibleBerths && !twinSupport(harbour)) {
      console.log(
        `Compatible berths are still the same, no need to search again`
      );
      return false;
    } else if (sameCompatibleBerths && twinSupport(harbour)) {
      console.log(
        "Compatible berths are still the same but twin supports mandates new search"
      );
      return true;
    } else {
      console.log(
        `Compatible berths are no longer the same, need to search again`
      );
      return true;
    }
  }
}

function twinSupport(harbour) {
  return harbour.twinBerthsSupported;
}

export const {
  berthSearchResult,
  trailSearchResult,
  dates,
  loading,
  segments,
  harbour,
  berths,
  unavailableDates,
  dimensionsValid,
  dimensionInvalid,
} = bookingSlice.actions;
export const bookingSliceName = bookingSlice.reducer;

export function initUnavailableDates(harbourIdIn) {
  return async function (dispatch, getState) {
    console.log(`Enter initUnavailableDates with ${harbourIdIn}`);
    const state = getState().booking;
    if (
      BerthFunctions.boatDimensionsValid(state.length, state.width, state.depth)
    ) {
      const udResponse = await HBApi.unavailableDatesByCriteria(
        harbourIdIn,
        new Date(),
        addDays(new Date(), maxBookingAheadDays + 1),
        state.length,
        state.width,
        state.depth
      );
      const unavailableDatesIn = await udResponse.json();

      if (
        // If only one of arrival/departure is unavailable we simply unset the dates
        (state.arrival &&
          DateFunctions.containsDate(unavailableDatesIn, state.arrival)) ||
        (state.departure &&
          DateFunctions.containsDate(unavailableDatesIn, state.departure))
      ) {
        dispatch(unavailableDates(unavailableDatesIn));
        dispatch(berthSearchResult([]));
        dispatch(trailSearchResult([]));
        dispatch(
          dates({
            arrival: "",
            departure: "",
          })
        );
      } else if (
        state.arrival &&
        state.departure &&
        BerthFunctions.boatDimensionsValid(
          state.lengthInput,
          state.widthInput,
          state.depthInput
        )
      ) {
        await processSearchObject(
          dispatch,
          harbourIdIn,
          state,
          unavailableDatesIn
        );
      } else {
        dispatch(unavailableDates(unavailableDatesIn));
        dispatch(berthSearchResult([]));
        dispatch(trailSearchResult([]));
      }
    }
  };
}
export function offerDates(datesIn) {
  return async function (dispatch, getState) {
    const [start, end] = datesIn;
    //console.log(`Thunk with datesIn ${JSON.stringify(datesIn)}`);
    if (end && start.getTime() === end.getTime()) {
      console.log("Start date = end date");
      return;
    }
    if (end) {
      console.log("End date exists in onDateChange");
      dispatch(dates({ arrival: start, departure: end }));
      dispatch(loading({ state: true }));
      await processSearchObject(
        dispatch,
        getState().booking.harbour.id,
        getState().booking,
        null
      );
      dispatch(loading({ state: false }));
    } else {
      dispatch(dates({ arrival: start }));
    }
  };
}
export function dimension(payload) {
  return async function (dispatch, getState) {
    const state = getState().booking;
    console.log(
      `Enter loadUnavailableDates with payload: ${JSON.stringify(
        payload
      )} and existing length: ${JSON.stringify(state.length)}`
    );
    const harbourId = state.harbour.id;
    const inputName = payload.name + "Input";
    const value = payload.value;
    const newSearchObject = {
      ...state,
      [inputName]: value,
    };
    if (
      BerthFunctions.boatDimensionsValid(
        newSearchObject.lengthInput,
        newSearchObject.widthInput,
        newSearchObject.depthInput
      )
    ) {
      console.log(`Thunk new boat dimensions are valid`);
      if (
        state.berths &&
        BerthFunctions.berthsCompatible(
          state.berths,
          newSearchObject.lengthInput,
          newSearchObject.widthInput,
          newSearchObject.depthInput
        )
      ) {
        console.log("Thunk : There are berths compatible with dimensions.");

        if (shouldSearchAgain(state, newSearchObject, state.harbour)) {
          console.log(
            `Thunk: Should search again is true, will perform new search with: ${JSON.stringify(
              payload
            )}`
          );
          dispatch(
            dimensionsValid({
              lengthInput: newSearchObject.lengthInput,
              widthInput: newSearchObject.widthInput,
              depthInput: newSearchObject.depthInput,
            })
          );
          dispatchInvalidDimension(dispatch, {
            name: inputName,
            value: value,
          });
          // Fetch search result and unavailable dates
          const udResponse = await HBApi.unavailableDatesByCriteria(
            harbourId,
            new Date(),
            addDays(new Date(), maxBookingAheadDays + 1),
            newSearchObject.lengthInput,
            newSearchObject.widthInput,
            newSearchObject.depthInput
          );
          const unavailableDates = await udResponse.json();
          processSearchObject(
            dispatch,
            harbourId,
            newSearchObject,
            unavailableDates
          );
        } else {
          console.log(
            `Thunk: No need to search again, setting search object ${JSON.stringify(
              logFriendlySearchObject(newSearchObject)
            )}`
          );
          if (!state.arrival && !state.departure) {
            // Fetch unavailable dates if dates are not set
            const udResponse = await HBApi.unavailableDatesByCriteria(
              harbourId,
              new Date(),
              addDays(new Date(), maxBookingAheadDays + 1),
              newSearchObject.lengthInput,
              newSearchObject.widthInput,
              newSearchObject.depthInput
            );
            const unavailableDatesIn = await udResponse.json();
            dispatch(unavailableDates(unavailableDatesIn));
          }
          // Dispatch
          dispatch(
            dimensionsValid({
              lengthInput: newSearchObject.lengthInput,
              widthInput: newSearchObject.widthInput,
              depthInput: newSearchObject.depthInput,
            })
          );
          dispatchInvalidDimension(dispatch, {
            name: inputName,
            value: value,
          });
        }
      } else {
        // No compatible berths, still change values
        dispatch(
          dimensionsValid({
            lengthInput: newSearchObject.lengthInput,
            widthInput: newSearchObject.widthInput,
            depthInput: newSearchObject.depthInput,
          })
        );
        dispatchInvalidDimension(dispatch, {
          name: inputName,
          value: value,
        });
        dispatch(berthSearchResult([]));
        dispatch(trailSearchResult([]));
      }
    } else {
      console.log(
        `Thunk: Dimensions not valid, returning ${JSON.stringify(
          logFriendlySearchObject(newSearchObject)
        )}`
      );
      dispatchInvalidDimension(dispatch, {
        name: inputName,
        value: value,
      });
    }
  };
}

// Fetch berths/trails and dispatch the results, also dispatches the unavailable dates
async function processSearchObject(
  dispatchFunction,
  harbourId,
  newSearchObject,
  unavailableDatesIn
) {
  console.log(
    "Enter processSearchObject with newSearchOject: " +
      JSON.stringify(logFriendlySearchObject(newSearchObject))
  );
  const searchResultResponse = await HBApi.searchBerthByHarbourAndCriteria(
    harbourId,
    newSearchObject.arrival,
    newSearchObject.departure,
    newSearchObject.lengthInput,
    newSearchObject.widthInput,
    newSearchObject.depthInput
  );
  const data = await searchResultResponse.json();

  const availableBerths = data.filter((b) => b.available === true);
  const nonAvailableBerths = data.filter((b) => b.available === false);
  if (unavailableDatesIn) {
    dispatchFunction(unavailableDates(unavailableDatesIn));
  } else {
    console.log(`No unavailableDatesIn, skipping dispatch`);
  }
  if (availableBerths.length === 0) {
    console.log(
      "Received only busy berths and " +
        nonAvailableBerths.length +
        " unavailable berths, there might be a trail"
    );
    const trailsResult = await HBApi.getTrails(harbourId, {
      ...newSearchObject,
      length: newSearchObject.lengthInput,
      width: newSearchObject.widthInput,
      depth: newSearchObject.depthInput,
    });
    if (trailsResult.length === 0) {
      console.log(
        "No trail found, non available berth count is: " + data.length
      );
      dispatchFunction(berthSearchResult(data));
    } else {
      console.log("Trail of length " + trailsResult.length + " found");
      dispatchFunction(trailSearchResult(trailsResult));
    }
  } else {
    console.log(
      "Received " +
        data.length +
        " berths for harbour " +
        harbourId +
        " in processSearchObject  "
    );
    dispatchFunction(berthSearchResult(data));
  }
}

function dispatchInvalidDimension(dispatchFunction, arg) {
  dispatchFunction(
    dimensionInvalid({
      name: arg.name,
      value: arg.value,
    })
  );
}
