import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Row, Col, Container, Spinner } from "reactstrap";
import "./App.css";
import { useEffect, useState } from "react";
import { HBApi } from "./HBApi";

const PaymentCompleted = () => {
  const [params, setParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [reference, setReference] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    console.log("Enter useEffect, will fetch booking reference");
    async function fetchReferences() {
      const referenceResult =
        await HBApi.getBookingReferenceByPaymentExternalId(
          params.get("paymentid"),
          params.get("vendor")
        );
      const jsonResult = await referenceResult.json();
      console.log(
        `Fetched booking references in useEffect  ${JSON.stringify(jsonResult)}`
      );
      setReference(jsonResult);
    }

    fetchReferences().then((result) => {
      setLoading(false);
    });
  }, []);

  if (loading) {
    return (
      <Container>
        <Row>
          <Col>
            <Spinner>Loading</Spinner>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      <Row>
        <Col md="12">
          <h1 align="center">{t("paymentcompleted.heading")}</h1>
        </Col>
      </Row>
      <Row>
        <Col>{t("paymentcompleted.message")}</Col>
      </Row>
      <Row>
        <Col md="12">
          {t("paymentcompleted.referencemessage")}:{" "}
          <b>
            <div id="bookingReferenceId">{reference.reference}</div>
          </b>
        </Col>
      </Row>
    </Container>
  );
};

export default PaymentCompleted;
