import React, { useEffect, useState } from "react";
import { Row, Button, Container, Spinner, Collapse } from "reactstrap";
import "../App.css";
import { HBApi } from "../HBApi";
import { DateFunctions } from "../hmehelpers/DateFunctions";
import SettlementDetails from "./SettlementDetails";
import SettlementSummary from "./SettlementSummary";

const Settlement = () => {
  const [loading, setLoading] = useState(true);
  const [harbour, setHarbour] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [details, setDetails] = useState({});
  const [refresh, setRefresh] = useState(0);
  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    const now = new Date();
    async function fetchData() {
      const adminInfo = await HBApi.getAdminInfo();
      const adminInfoJson = await adminInfo.json();
      const detailsResponse = await HBApi.getSettlementDetailedDryrun(
        adminInfoJson.harbour.id,
        now.toISOString()
      );
      const detailsJson = await detailsResponse.json();
      setHarbour(adminInfoJson.harbour.id);
      setDetails(
        detailsJson.sort((l, r) =>
          DateFunctions.compareDateStrings(l.from, r.from)
        )
      );
    }
    fetchData().then((result) => setLoading(false));
  }, [refresh]);

  const handlePayout = async (event) => {
    event.preventDefault();
    await HBApi.initiatePayout(harbour, new Date().toISOString());
    setRefresh((refresh) => refresh + 1);
  };

  if (loading) {
    return <Spinner />;
  }
  return (
    <Container>
      <Row>
        <h3>Bookings currently eligible for settlement</h3>{" "}
      </Row>
      <Row>
        <SettlementSummary details={details} />
      </Row>

      {details.length > 0 && (
        <div>
          <Button
            color="primary"
            onClick={toggle}
            style={{ marginBottom: "1rem" }}
          >
            Show details
          </Button>
          {"    "}
          <Button
            color="primary"
            onClick={handlePayout}
            style={{ marginBottom: "1rem" }}
          >
            Initiate payout
          </Button>
        </div>
      )}
      <Collapse isOpen={isOpen}>
        <SettlementDetails details={details} />
      </Collapse>
    </Container>
  );
};

export default Settlement;
