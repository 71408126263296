import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Accordion,
  Badge,
  Label,
  Input,
  Row,
  Col,
  Button,
  Container,
  Spinner,
} from "reactstrap";
import { addDays } from "date-fns";
import "../App.css";
import { HBApi } from "../HBApi";
import {
  stringToDateString,
  stringToWithCustomEndTimeHoursDateString,
} from "../hmehelpers/DateFunctions";
import { BerthFunctions } from "../hmehelpers/BerthFunctions";
import { useAuth } from "../AuthContext";
import DatePicker from "react-datepicker";
const BerthStatusAdmin = ({ berth }) => {
  console.log("BerthStatusAdmin enter with berth " + berth);
  const navigate = useNavigate();
  const auth = useAuth();

  const [myBerths, setMyBerths] = useState();
  const [futureBookings, setFutureBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [futureStatuses, setFutureStatuses] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [customArrival, setCustomArrival] = useState("12");
  const [refresh, setRefresh] = useState(0);
  var today = new Date();
  var maxDate = addDays(today, 150);

  const [open, setOpen] = useState("0");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  useEffect(() => {
    console.log("Enter useEffect BerthStatusAdmin " + berth);
    setLoading(true);
    HBApi.adminBerthOwnerView(berth.id)
      .then((response) => {
        if (response.ok) {
          console.log("Berth owner view response ok");
          return response.json();
        }
        return Promise.reject(response);
      })
      .then((data) => {
        console.log("BerthStatusAdmin received berth: " + JSON.stringify(data));
        setMyBerths(data);
        HBApi.getAdminBookingsByBerth(data.id, today, maxDate)
          .then((bookings) => {
            console.log("Received bookings: " + JSON.stringify(bookings));
            setFutureBookings(bookings);
            HBApi.getAdminBerthStatusesByBerth(data.id, new Date(), new Date())
              .then((statuses) => {
                console.log("Received berth statuses ");
                return statuses;
              })
              .then((statuses) => statuses.json())
              .then((statusesJson) => setFutureStatuses(statusesJson));
          })
          .then(() => setLoading(false));
      })
      .catch((errorResponse) => {
        console.log("errorResponse" + errorResponse.status);
        if (errorResponse.status === 401) {
          // If the server has restarted, this will happen
          console.log(
            "Unexpected 401 response, this will happen if the server has restarted"
          );
          auth.userLogout();
          navigate("/signin", { state: { nextpage: "/mypage/myberth" } });
        }
      });
  }, [refresh, berth]);

  const highlightWithRanges = [
    {
      "react-datepicker__day--highlighted-custom-1":
        BerthFunctions.bookedDays(futureBookings),
    },
    {
      "react-datepicker__day--highlighted-custom-2":
        BerthFunctions.statusDaysExcludingBookedDays(
          futureStatuses,
          futureBookings
        ),
    },
  ];

  const onDateChange = (dates) => {
    const [start, end] = dates;
    console.log("Enter onDateChange with start: " + start + " and end: " + end);
    if (end && start.getTime() === end.getTime()) {
      console.log("Start date = end date");
      return;
    }
    setStartDate(start);
    setEndDate(end);
  };

  const StatusDates = () => {
    let hld = highlightWithRanges;
    return (
      <DatePicker
        minDate={new Date()}
        maxDate={maxDate}
        onChange={onDateChange}
        highlightDates={hld}
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        inline
        selectsDisabledDaysInRange={false}
      />
    );
  };

  const StatusCol = ({ berth }) => {
    if (!berth.enabled) {
      return (
        <Col>
          <Badge>Disabled</Badge>
        </Col>
      );
    } else if (berth.enabled && berth.available) {
      return (
        <Col>
          <Badge>Currently Available</Badge>
        </Col>
      );
    } else {
      return (
        <Col>
          <Badge>Currently Booked</Badge>
        </Col>
      );
    }
  };

  const FutureBookingCount = ({ berth }) => {
    return (
      <Col>
        <Badge>
          Future booked days: {BerthFunctions.bookedDays(futureBookings).length}
        </Badge>
      </Col>
    );
  };

  const statusLabel = (berth) => {
    if (berth.defaultStatus === 0) {
      return "Future enabled days: ";
    } else {
      return "Future disabled days: ";
    }
  };
  const FutureEnabledCount = ({ berth }) => {
    return (
      <Col>
        <Badge>
          {statusLabel(berth)}
          {BerthFunctions.futureStatusDays(futureStatuses).length}
        </Badge>
      </Col>
    );
  };

  const BookingsStatusesAccordion = () => {
    return (
      <Accordion open={open} toggle={toggle}>
        <AccordionItem>
          <AccordionHeader targetId="1">
            <b>Future booking details</b>
          </AccordionHeader>
          <AccordionBody accordionId="1">
            <BookingsList />
          </AccordionBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeader targetId="2">
            <b>Future statuses</b>
          </AccordionHeader>
          <AccordionBody accordionId="2">
            <StatusesList />
          </AccordionBody>
        </AccordionItem>
      </Accordion>
    );
  };

  const BookingsList = () => {
    return futureBookings.map((b) => (
      <Row key={b.id}>
        <Col>
          <b>Booking:</b>{" "}
        </Col>
        <Col> from: {stringToWithCustomEndTimeHoursDateString(b.from)} </Col>
        <Col> to: {stringToWithCustomEndTimeHoursDateString(b.to)} </Col>
        <Col> booked at {stringToDateString(b.createdAt)} </Col>
        <Col> Cancellable: {b.cancellable ? "yes" : "no"} </Col>
      </Row>
    ));
  };

  const StatusesList = () => {
    return futureStatuses.map((st) => (
      <Row key={st.id}>
        <Col>
          <b>{st.status === 1 ? "Bookable period" : "Non bookable period"}</b>{" "}
        </Col>
        <Col> from: {stringToWithCustomEndTimeHoursDateString(st.from)} </Col>
        <Col> to: {stringToWithCustomEndTimeHoursDateString(st.to)} </Col>
        <Col>
          <Button onClick={() => deleteStatus({ target: { id: st.id } })}>
            X
          </Button>
        </Col>
        <Col />
      </Row>
    ));
  };

  const NoBerth = () => {
    return (
      <Container>
        <Row>
          <Col md="12">
            <h1 align="center">No berth</h1>
          </Col>
        </Row>
      </Container>
    );
  };

  const handleCustomArrival = async (event) => {
    const { name, value } = event.target;
    setCustomArrival(value);
  };

  const deleteStatus = (event) => {
    console.log(`deleteStatus with ${JSON.stringify(event.target)}`);
    HBApi.adminDeleteBerthStatus({ id: event.target.id })
      .then((response) => {
        if (response.status.ok) {
          return response.json();
        }
      })
      .then((jsonResponse) => setRefresh(refresh + 1));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    console.log(
      `Submit alter available request with start date ${startDate} and end date ${endDate} (hours: ${endDate.getHours()}) and custom arrival ${customArrival}`
    );
    const desiredStatus = berth.default_status === "DISABLED" ? "1" : "0";
    HBApi.adminModifyBerthStatus({
      berth: myBerths.id,
      berthStatus: desiredStatus,
      from: startDate,
      to: endDate,
      customArrival: customArrival,
    })
      .then((response) => {
        console.log(`Got response: ${response.status}`);
        return response.json();
      })
      .then((data) => {
        console.log(`Modify statuses respons: ${JSON.stringify(data)}`);
        let newFutureStatuses = [...futureStatuses, data[0]];
        console.log(
          `New future statuses: ${JSON.stringify(newFutureStatuses)}`
        );
        setRefresh(refresh + 1);
      });
  };

  const MakeAvailableButton = () => {
    const buttonText =
      berth.default_status === "DISABLED"
        ? "Make available"
        : "Make unavilable";
    if (
      endDate &&
      BerthFunctions.atLeastOneDayChangesStatusToEnabled(
        startDate,
        endDate,
        futureStatuses,
        futureBookings
      )
    ) {
      return (
        <Button
          id="makeAvailableButtonId"
          size="lg"
          color="primary"
          onClick={handleSubmit.bind(this)}
        >
          {buttonText}
        </Button>
      );
    } else {
      return (
        <Button size="lg" color="primary" disabled>
          {buttonText}
        </Button>
      );
    }
  };

  const CustomHours = () => {
    return Array.from({ length: 10 }, (value, index) => index + 12).map(
      (actualHour) => {
        return (
          <option key={actualHour} value={actualHour}>
            {actualHour}
          </option>
        );
      }
    );
  };
  const CustomArrival = () => {
    return (
      <div>
        <Label for="customarrival">Custom end time</Label>
        <Input
          value={customArrival}
          id="customarrival"
          bssize="md"
          type="select"
          name="customarrival"
          onChange={handleCustomArrival}
        >
          <CustomHours />
        </Input>
      </div>
    );
  };

  const DefaultStatus = () => {
    if (myBerths.defaultStatus === 0) {
      return <Badge color="warning">Disabled</Badge>;
    } else {
      return <Badge>Enabled</Badge>;
    }
  };

  if (loading) {
    return <Spinner />;
  }

  if (myBerths.length === 0) {
    return <NoBerth />;
  } else {
    return (
      <Container>
        <Row>
          <Col md="12">
            <h3 align="center">Berth Details</h3>
          </Col>
        </Row>
        <Row>
          <Col id="berthLabelId">Berth: {myBerths.label}</Col>
          <Col>
            Default status: <DefaultStatus />
          </Col>
          <Col />
          <Col />
        </Row>
        <Row>
          <Col id="maxLengthId">Max length: {myBerths.maxLength}</Col>
          <Col>Max width: {myBerths.maxWidth}</Col>
          <Col>Max depth: {myBerths.maxDepth}</Col>
          <Col>Fixation type: {myBerths.fixationTypeLabel}</Col>
        </Row>
        <Row>
          <StatusCol berth={myBerths} />
          <FutureBookingCount berth={myBerths} />
          <FutureEnabledCount berth={myBerths} />
          <Col />
        </Row>

        <Row>
          <BookingsStatusesAccordion />
        </Row>
        <Row>
          <Col />
          <Col>
            <StatusDates />
          </Col>
          <Col>
            <CustomArrival />
          </Col>
        </Row>
        <Row>
          <Col />
          <Col>
            <MakeAvailableButton />
          </Col>
          <Col />
        </Row>
      </Container>
    );
  }
};

export default BerthStatusAdmin;
