import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Toast, ToastHeader, ToastBody, Row, Col, Container } from "reactstrap";
import "./App.css";
import Footer from "./Footer";
import { HBApi } from "./HBApi";

const VerifyEmail = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [verifyResult, setVerifyResult] = useState("");
  useEffect(() => {
    console.log(`Enter use effect with id : ${id} `);

    HBApi.verifyRegistrationEmail(id)
      .then((response) => {
        console.log(`Received response : ${JSON.stringify(response)}`);
        if (response.ok) {
          console.log("Response is ok");
          return response.body;
        } else {
          console.log(`Response is not ok`);
          return Promise.reject(response);
        }
      })
      .then((data) => {
        console.log("Received string response : " + JSON.stringify(data));
        setVerifyResult(t("verifyemail.successmessage"));
      })
      .catch((errorResponse) => {
        console.log("errorResponse " + errorResponse.status);
        if (errorResponse.status === 417) {
          setVerifyResult(t("verifyemail.expirationmessage"));
        } else if (errorResponse.status === 404) {
          setVerifyResult(t("verifyemail.notfoundmessage"));
        } else if (errorResponse.status === 409) {
          setVerifyResult(t("verifyemail.alreadyprocessedmessage"));
        } else if (errorResponse.status === 500) {
          setVerifyResult(t("common.generalerrormessage"));
        }
      });
  }, []);

  console.log("Enter verify email");

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div className="p-3 my-2 bg-secondary rounded">
              <Toast>
                <ToastHeader>
                  <b>{t("verifyemail.heading")}</b>
                </ToastHeader>
                <ToastBody>
                  {verifyResult}
                  <hr />
                </ToastBody>
              </Toast>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default VerifyEmail;
