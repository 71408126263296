import "leaflet/dist/leaflet.css";
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useState } from "react";
import "./App.css";

const Footer = () => {
  return (
    <div className="App-header">
      www.harbourme.com <br />
      info@harbourme.com
    </div>
  );
};

export default Footer;
