import { useTranslation } from "react-i18next";
import { Row, Col, Container } from "reactstrap";
import "./App.css";
import AppNavbarLight from "./AppNavbarLight";

const BookingConflict = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Row>
        <Col md="12">
          <AppNavbarLight />
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <h1 align="center">{t("bookingconflict.heading")}</h1>
        </Col>
      </Row>
      <Row>
        <Col>{t("bookingconflict.message")}</Col>
      </Row>
    </Container>
  );
};

export default BookingConflict;
