import { useState, useEffect } from "react";
import { Container, Spinner } from "reactstrap";
import { useParams } from "react-router-dom";
import CodeRow from "../bookinglists/CodeRow";
import { HBApi } from "../HBApi";
import { DateFunctions } from "../hmehelpers/DateFunctions";
import { BookingFunctions } from "../hmehelpers/BookingFunctions";
import BookingBody from "../bookinglists/BookingBody";
import { useTranslation } from "react-i18next";
const ManageBookingAnonymous = () => {
  const [booking, setBooking] = useState({});
  const [facilityCodes, setFacilityCodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    async function fetchData() {
      const fb = await HBApi.getBookingByIdNoJson(id);
      const fbJson = await fb.json();
      BookingFunctions.bookingTrailSorted(fbJson);
      const harbourResponse = await HBApi.getHarbourById(fbJson.harbourId);
      const harbourJson = await harbourResponse.json();
      setBooking({ bookingTrailDeepView: fbJson, harbourView: harbourJson });

      if (
        fbJson.status !== 2 &&
        DateFunctions.spannedBy(new Date(), fbJson.from, fbJson.to)
      ) {
        const codeResponse = await HBApi.getFacilityCodesByBooking(fbJson.id);
        const codesJson = await codeResponse.json();
        setFacilityCodes(codesJson);
      }
    }

    fetchData().then((fetchResult) => setLoading(false));
  }, []);

  const CodeContainer = () => {
    return (
      <Container>
        <CodeRow
          booking={booking.bookingTrailDeepView}
          facilityCodes={[facilityCodes]}
        />
      </Container>
    );
  };
  const StatusSection = () => {
    const { t } = useTranslation();
    const s = booking.bookingTrailDeepView.status;
    if (s === 1) {
      return (
        <h3>
          <b>
            {t("common.bookingStatus.1")} {t("common.booking")}
          </b>
        </h3>
      );
    } else if (s === 3) {
      return (
        <h3>
          <b>
            {t("common.bookingStatus.3")} {t("common.booking")}
          </b>
        </h3>
      );
    } else if (s === 2) {
      return (
        <h3>
          <b>
            {t("common.bookingStatus.2")} {t("common.booking")}
          </b>
        </h3>
      );
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <StatusSection />
      <CodeContainer />
      <BookingBody booking={booking} overlays={{}} />
    </>
  );
};

export default ManageBookingAnonymous;
