import React from "react";
import { useTranslation } from "react-i18next";
import "../App.css";
import CookieConsent from "react-cookie-consent";

import {
  Container,
  Row,
  Col,
  List,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";

const Items = () => {
  const { t } = useTranslation();
  return Array.from({ length: 10 }, (v, i) => i).map((count) => (
    <li className="fw-bold p-2 my-1">
      {t(`info.faq.q${count}`)}
      <div className="p-2 my-1">- {t(`info.faq.a${count}`)}</div>
    </li>
  ));
};

const Faq = () => {
  const { t } = useTranslation();

  return (
    <Container fluid className="withBgImage">
      <Container fluid>
        <Row>
          <Col md="2" />
          <Col md="8">
            <div className="home-text-on-image">{t("info.about.heading")}</div>
          </Col>
          <Col md="2" />
        </Row>
        <Row>
          <Col></Col>
          <Col md="6">
            <Card>
              <CardHeader className="fw-bold p-4 my-1 fs-3">
                {t("info.faq.heading")}
              </CardHeader>
              <CardBody>
                <List className="p-2 my-2">
                  <Items />
                </List>
              </CardBody>
            </Card>
          </Col>
          <Col></Col>
        </Row>
      </Container>
      <CookieConsent
        style={{ background: "#556577" }}
        buttonStyle={{
          background: "#FEFEFE",
          color: "#4e503b",
          fontSize: "13px",
        }}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </Container>
  );
};

export default Faq;
