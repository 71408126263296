import { useNavigate } from "react-router-dom";
import { Label, Input, Button } from "reactstrap";
//import "./App.css";
import { DateFunctions } from "../hmehelpers/DateFunctions";
import { addDays } from "date-fns";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { segments, dates } from "./bookingreducer";
const BerthInput = ({
  selectedBerth,
  setSelectedBerth,
  berthSearchResult,
  harbour,
  criteria,
  submitDestination,
}) => {
  console.log("Enter BerthInput");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const berthSelected = () => {
    return selectedBerth && selectedBerth !== "0";
  };
  const LabelF = () => {
    if (berthSelected()) {
      return (
        <Label size="lg" for="berthSelect">
          {t("book.chosenberthlabel")}
        </Label>
      );
    } else {
      return (
        <Label size="lg" for="berthSelect">
          {t("book.selectoneberth")}
        </Label>
      );
    }
  };
  const ToBookingDetailsButton = () => {
    const dispatch = useDispatch();
    const searchObject = useSelector((store) => store.booking);
    const validBookingLength = () => {
      console.log(
        `validBookingLength with arrival ${searchObject.arrival} , departure ${searchObject.departure}`
      );
      const breakPoint = addDays(new Date(), 90);

      if (Date.parse(searchObject.arrival) > breakPoint.getTime()) {
        if (
          Date.parse(searchObject.departure) -
            Date.parse(searchObject.arrival) >=
          86400000 * 5
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    };

    if (!validBookingLength()) {
      return <Label>{t("book.minbookinglengthmessage")}</Label>;
    } else if (berthSelected()) {
      let berthState = berthSearchResult.find((b) => b.id === selectedBerth);
      return (
        <Button
          id="toBookingDetailsButton"
          size="lg"
          color="primary"
          onClick={() => {
            console.log(
              "Clicked payment and selected berth is:" +
                JSON.stringify(berthState) +
                " and criteria: " +
                JSON.stringify(criteria)
            );
            dispatch(
              segments([
                {
                  berthId: berthState.id,
                  label: berthState.label,
                  from: criteria.arrival,
                  to: criteria.departure,
                  fixationTypeLabel: berthState.fixationTypeLabel,
                },
              ])
            );
            dispatch(
              dates({
                arrival: criteria.arrival,
                departure: criteria.departure,
              })
            );
            navigate(submitDestination, {
              state: {
                selectedBerth: berthState,
                harbour: harbour,
                criteria: criteria,
              },
            });
          }}
        >
          {t("book.proceedtodetails")}
        </Button>
      );
    } else {
      return (
        <Button size="lg" color="primary" disabled>
          {t("book.proceedtodetails")}
        </Button>
      );
    }
  };
  if (berthSearchResult.filter((b) => b.available).length === 0) {
    return <div>No available berths for the selected period</div>;
  } else {
    return (
      <div>
        <LabelF />
        <Input
          value={selectedBerth}
          type="select"
          name="select"
          id="berthSelect"
          onChange={(event) => setSelectedBerth(event.target.value)}
        >
          <option key="0" value="0">
            {t("book.chooseberthselectdefaultchoice")}
          </option>
          {berthSearchResult
            .slice()
            .sort((left, right) => {
              if (left.label < right.label) {
                return -1;
              } else if (left.label > right.label) {
                return 1;
              } else return 0;
            })
            .filter((b) => b.available)

            .map((b) => {
              return (
                <option key={b.id} value={b.id}>
                  {`${b.label}  (${b.berthAndBookingInclVAT} ${b.currency})`}
                </option>
              );
            })}
        </Input>

        <ToBookingDetailsButton />
      </div>
    );
  }
};

export default BerthInput;
