import { DateFunctions } from "../hmehelpers/DateFunctions";
import { List } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import { useAuth } from "../AuthContext";
const calcDays = (bookingTrailDeepView) =>
  bookingTrailDeepView.bdvs.flatMap((bdv) => bdv.days);

const TotalPrice = ({ bookingTrailDeepView }) => {
  const ep = bookingTrailDeepView.electricity
    ? bookingTrailDeepView.electricityPrice *
      calcDays(bookingTrailDeepView).length
    : 0;
  const cp = bookingTrailDeepView.cancellable
    ? bookingTrailDeepView.cancellablePrice *
      calcDays(bookingTrailDeepView)
        .filter((d) => d.initialCancellableStatus === 1)
        .map((d) => 1)
        .reduce((l, r) => l + r, 0)
    : 0;
  const bp = calcDays(bookingTrailDeepView)
    .map((b) => b.berthPrice + b.bookingPrice)
    .reduce((l, r) => l + r, 0);
  return (
    <div>
      <b>
        {i18n.t("bookingbody.totalprice")} {ep + cp + bp}{" "}
        {bookingTrailDeepView.bdvs[0].days[0].currency}
      </b>
    </div>
  );
};
const Days = ({ booking }) => {
  return calcDays(booking).map((d, index) => (
    <li>
      {i18n.t("common.day")} {index + 1},{" "}
      {DateFunctions.stringToDateString(d.from)}
      {":  "}
      {i18n.t("bookingbody.berthprice")} {d.berthPrice + d.bookingPrice}{" "}
      {d.currency}
      {d.initialCancellableStatus === 1 && (
        <div>
          {i18n.t("common.cancellationFeeCapital")} {booking.cancellablePrice}{" "}
          {d.currency}
        </div>
      )}
      {booking.electricity && (
        <div>
          {i18n.t("common.electricityCapital")} {booking.electricityPrice}{" "}
          {d.currency}
        </div>
      )}
    </li>
  ));
};

const OverlayDays = ({ booking, overlays }) => {
  return calcDays(booking).map((d, index) => {
    const overlay = overlays.find((o) => o.bookingDayId === d.id);
    return (
      <li>
        {i18n.t("common.day")} {index + 1},{" "}
        {DateFunctions.stringToOnlyDateString(d.from)}
        {"  "}
        {overlay && (
          <b> - {i18n.t("bookingbody.overlayType." + overlay.desiredStatus)}</b>
        )}
      </li>
    );
  });
};

const CancelLink = ({ bookingTrailDeepView }) => {
  const endsInFuture =
    DateFunctions.compareDates(
      new Date(Date.parse(bookingTrailDeepView.to)),
      new Date()
    ) > 0;
  return (
    bookingTrailDeepView.cancellable &&
    endsInFuture && (
      <div>
        <Link to={"/cancellation/" + bookingTrailDeepView.id}>
          {i18n.t("common.docancel")}
        </Link>{" "}
      </div>
    )
  );
};
const OverrideLink = ({ bookingTrailDeepView }) => {
  const auth = useAuth();
  const endsInFuture =
    DateFunctions.compareDates(
      new Date(Date.parse(bookingTrailDeepView.to)),
      new Date()
    ) > 0;
  return (
    auth.hasRole("ADMIN") &&
    endsInFuture && (
      <div>
        <Link to={"/admin/bookingoverlay/" + bookingTrailDeepView.id}>
          {i18n.t("bookingbody.dooverlay")}
        </Link>{" "}
      </div>
    )
  );
};
const Electricity = ({ booking }) => {
  const { t } = useTranslation();
  if (booking.electricityPrice === 0) {
    return t("common.electricityincluded");
  } else {
    return booking.electricity ? t("common.yes") : t("common.no");
  }
};
const LabelsAndStart = ({ bookingTrailDeepView }) => {
  if (bookingTrailDeepView.bdvs.length === 1) {
    return (
      <li>
        {i18n.t("common.berthCapital")}: {bookingTrailDeepView.bdvs[0].label}
      </li>
    );
  } else {
    return (
      <>
        {bookingTrailDeepView.bdvs.map((b) => (
          <li>
            {i18n.t("common.berthCapital")} {b.label}, {i18n.t("common.from")}{" "}
            {DateFunctions.stringToDateString(b.from)}
          </li>
        ))}
      </>
    );
  }
};
const BookingBody = ({ booking, overlays }) => {
  const { t } = useTranslation();
  const auth = useAuth();
  let b = booking.bookingTrailDeepView;
  const harbour = booking.harbourView;
  return (
    <div>
      <List>
        <li>
          <LabelsAndStart bookingTrailDeepView={booking.bookingTrailDeepView} />
        </li>
        <li>
          {t("common.arrivalCapital")}:{" "}
          {DateFunctions.stringToDateString(b.from)}{" "}
        </li>
        <li>
          {t("common.departureCapital")}:{" "}
          {DateFunctions.stringToDateString(b.to)}{" "}
        </li>
        {auth.hasRole("ADMIN") && (
          <>
            <li>
              {t("common.name")} : {b.firstName} {b.lastName}
            </li>
            <li>
              {t("common.phone")}: {b.phone}
            </li>
            <li>
              {t("common.country")}: {b.country}
            </li>
          </>
        )}
        <li>
          {t("common.cancellable")}:{" "}
          {b.cancellable ? t("common.yes") : t("common.no")}
        </li>
        <li>
          {t("common.electricityCapital")}: <Electricity booking={b} />
        </li>
        <li>
          {t("common.reference")}: {b.reference}
        </li>
      </List>

      <b>{t("bookingbody.contactheading")}</b>
      <List>
        <li>
          {t("common.email")}: {harbour.contactEmail}
        </li>
        <li>
          {t("common.phone")}: {harbour.contactPhone}
        </li>
      </List>

      <b>{t("bookingbody.pricedetails")}</b>
      <TotalPrice bookingTrailDeepView={b} />
      <CancelLink bookingTrailDeepView={b} />
      <OverrideLink bookingTrailDeepView={b} />
      <List>
        <Days booking={b} />
      </List>
      {auth.hasRole("ADMIN") && overlays.length > 0 && (
        <>
          <b>{t("bookingbody.specialoperations")}</b>
          <List>
            <OverlayDays booking={b} overlays={overlays} />
          </List>
        </>
      )}
      <b>{t("bookingbody.aboutboat")}</b>
      <List>
        <li>
          {t("bookingbody.boatdimensions")} {b.length} m, {b.width} m, {b.depth}{" "}
          m
        </li>
        <li>
          {t("common.boatmodel")}: {b.boatModel}
        </li>
        <li>
          {t("common.boatname")}: {b.boatName}
        </li>
      </List>
    </div>
  );
};

export default BookingBody;
