import React from "react";
import { useTranslation } from "react-i18next";

import { Toast, ToastHeader, ToastBody, Row, Col, Container } from "reactstrap";
import "./App.css";
import Footer from "./Footer";

const SignUpSuccess = () => {
  const { t } = useTranslation();

  console.log("Enter signup success");

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div className="p-3 my-2 bg-secondary rounded">
              <Toast>
                <ToastHeader>
                  <b>{t("signupsuccess.header")}</b>
                </ToastHeader>
                <ToastBody>
                  {t("signupsuccess.verify")}

                  <hr />
                  <ul>
                    <li>{t("signupsuccess.emailexpiry")}</li>
                  </ul>
                </ToastBody>
              </Toast>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default SignUpSuccess;
