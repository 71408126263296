import { List } from "reactstrap";
import { DateFunctions } from "../hmehelpers/DateFunctions";

const calculateSummary = (details) => {
  const netSum = details
    .map((b) => b.payoutNet)
    .reduce((ack, current) => ack + current, 0);
  const cancellableFeesTotal = details
    .map((b) => b.payoutNoVat)
    .reduce((ack, current) => ack + current, 0);
  const netRounded = Math.round(netSum * 100) / 100;
  const vatSum = details
    .map((b) => b.payoutVat)
    .reduce((ack, current) => ack + current, 0);
  const vatRounded = Math.round(vatSum * 100) / 100;
  return {
    from: details.length > 0 ? details[0].from : new Date(),
    to: new Date().toISOString(),
    bookingCount: details.length,
    cancelledCount: details.filter((b) => b.status === 2).length,
    partiallyCancelledCount: details.filter((b) => b.status === 3).length,
    payoutNet: netRounded,
    vat: vatRounded,
    cancellableFeesTotal: cancellableFeesTotal,
    currency: details[0]?.currency,
  };
};

const SettlementSummary = ({ details }) => {
  const summary = calculateSummary(details);
  if (summary.bookingCount === 0) {
    return <div>No bookings settleable</div>;
  } else {
    return (
      <List>
        <li>
          <b>From</b> {DateFunctions.onlyDateString(summary.from)} <b>to</b>{" "}
          {DateFunctions.onlyDateString(summary.to)}
        </li>
        <li>
          <b>Booking total count: </b>
          {summary.bookingCount}
        </li>
        <li>
          <b>Of which cancelled:</b> {summary.cancelledCount}{" "}
          <b> and partially cancelled:</b> {summary.partiallyCancelledCount}
        </li>
        <li>
          <b>Payout net value:</b>{" "}
          {summary.payoutNet + summary.cancellableFeesTotal} {summary.currency}
        </li>
        <li>
          <b>Of which is cancellation fees value:</b>{" "}
          {summary.cancellableFeesTotal} {summary.currency}
        </li>
        <li>
          <b>Payout VAT value: </b>
          {summary.vat} {summary.currency}{" "}
        </li>
      </List>
    );
  }
};
export default SettlementSummary;
