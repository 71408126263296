import { useNavigate } from "react-router-dom";
import { Col, Row, Card, CardBody, CardTitle, CardImg } from "reactstrap";
import { HarbourFunctions } from "./hmehelpers/HarbourFunctions";

function HarbourImagesList({ subharbours }) {
  const navigate = useNavigate();
  const harbourList = subharbours.map((harbour) => {
    let imagePath = HarbourFunctions.thumbnailPath(harbour.image);
    return (
      <Col key={harbour.id}>
        <Card
          id={"harboursearchresult-" + harbour.id}
          className="my-4"
          outline
          onClick={() => navigate("/harbours/" + harbour.id)}
          style={{ cursor: "pointer", width: "18rem", bordeRadius: "8px" }}
        >
          <CardImg
            id="cardImageId"
            src={imagePath}
            style={{ borderRadius: "8px" }}
            top
          />
          <CardBody>
            <CardTitle tag="h4">{harbour.name}</CardTitle>
          </CardBody>
        </Card>
      </Col>
    );
  });

  return <Row>{harbourList}</Row>;
}

export default HarbourImagesList;
