import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Collapse,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useAuth } from "./AuthContext";

const AppNavbarLight = () => {
  const [isOpen, setIsOpen] = useState(false);
  const auth = useAuth();
  const user = auth.getUser();
  const { t, i18n } = useTranslation();

  return (
    <Navbar light className="my-2" expand="md">
      <NavbarBrand className="navbarbrand" href="/">
        <img
          alt="logo"
          src="/favicon.ico"
          style={{
            height: 40,
            width: 40,
          }}
        />
        HarbourMe
      </NavbarBrand>
      <NavbarToggler
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="justify-content-end" style={{ width: "100%" }} navbar>
          <NavItem className="navbar-default">
            <NavLink id="searchId" tag={Link} to="/">
              {t("nav.search")}
            </NavLink>
          </NavItem>
          {user && auth.hasRole("ADMIN") && (
            <NavItem className="navbar-default">
              <NavLink id="feeSupportId" tag={Link} to="/admin/feesupport">
                {t("nav.berthstatus")}
              </NavLink>
            </NavItem>
          )}
          {user && auth.hasRole("ADMIN") && (
            <NavItem className="navbar-default">
              <NavLink id="adminPageId" tag={Link} to="/admin/adminpage">
                {t("nav.admin")}
              </NavLink>
            </NavItem>
          )}
          {user && auth.hasRole("USER") && (
            <NavItem className="navbar-default">
              <NavLink id="profileId" tag={Link} to="/mypage">
                {t("nav.profile")}
              </NavLink>
            </NavItem>
          )}

          {!user && (
            <NavItem className="navbar-default">
              <NavLink id="signInId" tag={Link} to="/signin">
                {t("nav.signin")}
              </NavLink>
            </NavItem>
          )}
          {!user && (
            <NavItem className="navbar-default">
              <NavLink id="signUpId" tag={Link} to="/signup">
                {t("nav.signup")}
              </NavLink>
            </NavItem>
          )}
          {user && (
            <NavItem className="navbar-default">
              <NavLink id="signOutId" tag={Link} to="/signout">
                {t("nav.signout")}
              </NavLink>
            </NavItem>
          )}

          <NavItem className="navbar-default">
            <NavLink tag={Link} to="/about">
              {t("nav.about")}
            </NavLink>
          </NavItem>
          <NavItem className="navbar-default">
            <NavLink
              tag={Link}
              onClick={(event) => {
                event.preventDefault();
                i18n.changeLanguage("en");
              }}
            >
              EN
            </NavLink>
          </NavItem>
          <NavItem className="navbar-default">
            <NavLink
              tag={Link}
              onClick={(event) => {
                event.preventDefault();
                i18n.changeLanguage("sv");
              }}
            >
              SV
            </NavLink>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default AppNavbarLight;
