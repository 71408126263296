import { Row, Col, Container } from "reactstrap";
import AppNavbarLight from "./AppNavbarLight";

const WithNav = ({ children }) => {
  return (
    <Container>
      <Row>
        <Col md="12">
          <AppNavbarLight />
        </Col>
      </Row>
      {children}
    </Container>
  );
};

export default WithNav;
