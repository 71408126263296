import React, { useEffect, useState } from "react";
import {
  Table,
  Label,
  Input,
  Row,
  Col,
  Button,
  Container,
  Spinner,
} from "reactstrap";
import "../App.css";
import { addDays, subDays } from "date-fns";
import { HBApi } from "../HBApi";
import { DateFunctions } from "../hmehelpers/DateFunctions";
import DatePicker from "react-datepicker";

const FacilityCodeAdmin = () => {
  const [codes, setCodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [harbour, setHarbour] = useState();
  const [toAdd, setToAdd] = useState({
    from: new Date(),
    code: "",
    communicationType: "WEB",
    facilityType: "TOILETENTRANCE",
  });
  const [refresh, setRefresh] = useState(0);

  const sortCodes = (cl) => {
    console.log(`Sort called with ${JSON.stringify(cl)}`);
    return cl.sort((l, r) => DateFunctions.compareDateStrings(l.from, r.from));
  };
  const onDateChange = (date) => {
    const start = date;
    console.log(`Date = ${start}`);
    setToAdd({ ...toAdd, from: start });
  };

  useEffect(() => {
    async function fetchCodes() {
      const now = new Date().getTime();
      const aiResponse = await HBApi.getAdminInfo();
      const aiJson = await aiResponse.json();
      const cl = await HBApi.getFacilityCodesByHarbourId(
        aiJson.harbour.id,
        subDays(now, 365).toISOString(),
        addDays(now, 365).toISOString()
      );
      const clJson = await cl.json();
      setHarbour(aiJson.harbour.id);
      setToAdd({ ...toAdd, harbourId: aiJson.harbour.id });
      setCodes(sortCodes(clJson));
      setLoading(false);
    }
    fetchCodes();
  }, [refresh]);

  if (loading) {
    return <Spinner />;
  }

  const nextColor = (c, index, currentColor) => {
    if (index === 0) {
      return "white";
    } else {
      if (codes[index - 1].from !== c.from) {
        if (currentColor === "white") {
          return "lightblue";
        } else {
          return "white";
        }
      } else {
        return currentColor;
      }
    }
  };
  const CodesList = () => {
    var currentColor = "white";
    return codes.map((c, index) => {
      const tmpColor = nextColor(c, index, currentColor);
      currentColor = tmpColor;
      const typeId = "type-" + index;
      const codeId = "code-" + index;
      const fromId = "from-" + index;
      const communicationTypeId = "communicationType-" + index;
      const deleteButtonId = "deleteButton-" + index;

      return (
        <tr>
          <td id={fromId}>{c.from}</td>
          <td>{c.to}</td>
          <td id={typeId}>{c.type}</td>
          <td id={codeId}>{c.code}</td>
          <td id={communicationTypeId}>{c.communicationType}</td>
          <td>
            <Button
              id={deleteButtonId}
              size="md"
              onClick={() =>
                HBApi.deleteFacilityCode(c.id).then((result) =>
                  setRefresh((refresh) => refresh + 1)
                )
              }
            >
              X
            </Button>
          </td>
        </tr>
      );
    });
  };
  const validInput = () => {
    if (toAdd.code.length > 3) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    HBApi.addFacilityCode(toAdd).then((result) =>
      setRefresh((refresh) => refresh + 1)
    );
  };
  const handleChange = async (event) => {
    const { name, value } = event.target;
    console.log(`Handle change toAdd: }`);
    setToAdd({ ...toAdd, [name]: value });
  };
  const SubmitCodeButton = () => {
    if (validInput()) {
      return (
        <Button id="submitButtonId" color="primary" onClick={handleSubmit}>
          Add code
        </Button>
      );
    } else {
      return (
        <Button
          id="submitButtonId"
          color="primary"
          onClick={handleSubmit}
          disabled
        >
          Add code
        </Button>
      );
    }
  };

  return (
    <Container>
      <Row>
        <Col>
          <Label for="dateInput">
            <b>Code valid from</b>
          </Label>
          <DatePicker
            id="dateInputId"
            name="dateInput"
            showTimeInput="true"
            selected={toAdd.from}
            onChange={onDateChange}
          ></DatePicker>
        </Col>
        <Col>
          <Label for="codeType">
            <b>Type</b>
          </Label>
          <Input
            name="facilityType"
            type="select"
            id="facilityTypeId"
            value={toAdd.facilityType}
            onChange={handleChange}
          >
            <option value="TOILETENTRANCE">Toilet</option>
            <option value="HARBOURENTRANCE">Harbour entrance</option>
          </Input>
        </Col>
        <Col>
          <Label for="communicationType">
            <b>Communicated via</b>
          </Label>
          <Input
            name="communicationType"
            value={toAdd.communicationType}
            type="select"
            id="communicationTypeId"
            onChange={handleChange}
          >
            <option value="WEB">Web</option>
            <option value="EMAIL">Email</option>
          </Input>
        </Col>
        <Col>
          <Label for="codeInput">
            <b>Code</b>
          </Label>
          <Input
            name="code"
            value={toAdd.code}
            id="codeInputId"
            type="text"
            onChange={handleChange}
          ></Input>
        </Col>
        <Col></Col>
      </Row>
      <Row>
        <Col>
          <SubmitCodeButton />
        </Col>
      </Row>

      <Row>
        <Table striped responsive>
          <thead>
            <th>
              <b>Valid from</b>
            </th>
            <th>
              <b>Valid to</b>
            </th>
            <th>
              <b>Code type</b>
            </th>
            <th>
              <b>Code</b>
            </th>
            <th>Communicated via</th>
            <th></th>
          </thead>
          <tbody>
            <CodesList />
          </tbody>
        </Table>
      </Row>
    </Container>
  );
};

export default FacilityCodeAdmin;
