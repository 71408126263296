import React, { useEffect, useState } from "react";
import { Row, Button, Container, Spinner, Table, Collapse } from "reactstrap";
import "../App.css";
import { HBApi } from "../HBApi";
import { DateFunctions } from "../hmehelpers/DateFunctions";
import SettlementDetails from "./SettlementDetails";
import SettlementSummary from "./SettlementSummary";
const Settled = () => {
  const [loading, setLoading] = useState(true);
  const [harbour, setHarbour] = useState();
  const [settlements, setSettlements] = useState([]);
  const [currentSettlement, setCurrentSettlement] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [details, setDetails] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    const now = new Date();
    async function fetchData() {
      const adminInfo = await HBApi.getAdminInfo();
      const adminInfoJson = await adminInfo.json();
      const settlementsResponse = await HBApi.getSettlements(
        adminInfoJson.harbour.id,
        now.toISOString()
      );
      const settlementsJson = await settlementsResponse.json();
      setHarbour(adminInfoJson.harbour.id);
      setSettlements(settlementsJson);
    }
    fetchData().then((result) => setLoading(false));
  }, [refresh]);

  const handleSettlement = async (event) => {
    const id = event.target.id;
    if (id === currentSettlement) {
      toggle();
      return;
    } else {
      console.log(`settlement ${id} chosen`);
      const currentSettlement = await HBApi.getSettlementDetails(id);
      const currentSettlementJson = await currentSettlement.json();
      setDetails(
        currentSettlementJson.sort((l, r) =>
          DateFunctions.compareDateStrings(l.from, r.from)
        )
      );
      setCurrentSettlement(id);
      setIsOpen(true);
    }
  };

  const SettlementList = () => {
    return (
      <Table striped responsive>
        <thead>
          <tr>
            <th>Id</th>
            <th>Date</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
          {settlements.map((s, indext) => (
            <>
              <tr>
                <td>Id: {s.id}</td>
                <td>
                  Date: {DateFunctions.stringToDateString(s.createdAt)}{" "}
                  {DateFunctions.stringToTimeString(s.createdAt)}
                </td>
                <td>
                  <Button
                    onClick={() => handleSettlement({ target: { id: s.id } })}
                  >
                    Details
                  </Button>
                </td>
              </tr>
              <Collapse isOpen={isOpen && currentSettlement === s.id}>
                {currentSettlement && <SettlementSummary details={details} />}
                <SettlementDetails details={details} />
              </Collapse>
            </>
          ))}
        </tbody>
      </Table>
    );
  };

  if (loading) {
    return <Spinner />;
  }

  if (settlements.length === 0) {
    return (
      <Container>
        <Row>
          <h3>No past settlements yet</h3>
        </Row>
      </Container>
    );
  }
  return (
    <Container>
      <Row>
        <h3>Settlements</h3>
      </Row>
      <SettlementList />
    </Container>
  );
};

export default Settled;
