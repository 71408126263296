import React, { createContext, useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";

const AuthContext = createContext();
function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const ui = useSelector((store) => store.userinformation);

  useEffect(() => {
    const storedUser = ui.userinformation;
    setUser(storedUser);
  }, []);

  const getUser = () => {
    if (ui.userinformation === null) {
      return null;
    } else {
      return ui.userinformation;
    }
  };
  const hasRole = (roleName) => {
    return getUser() ? getUser().roles?.includes(roleName) : false;
  };
  const userIsAuthenticated = () => {
    return ui.userinformation?.email ? true : false;
  };

  const userLogin = (user) => {
    setUser(user);
  };

  const userLogout = () => {
    setUser(null);
  };

  const contextValue = {
    user,
    getUser,
    userIsAuthenticated,
    userLogin,
    userLogout,
    hasRole,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
}

export default AuthContext;

export function useAuth() {
  return useContext(AuthContext);
}

export { AuthProvider };
