import React from "react";
import { useTranslation } from "react-i18next";
import BerthInput from "./BerthInput";
import Trail from "./Trail";
import { BerthFunctions } from "../hmehelpers/BerthFunctions";
import "../App.css";
import { useSelector } from "react-redux";
const BerthOrTrail = ({
  selectedBerth,
  setSelectedBerth,
  submitDestination,
}) => {
  const searchObject = useSelector((store) => store.booking);
  const { t, i18n } = useTranslation();
  const modSearchObject = () => {
    let arrivalHour = new Date(Date.parse(searchObject.arrival));
    arrivalHour.setHours(12);

    let departureHour = new Date(Date.parse(searchObject.departure));
    departureHour.setHours(12);
    return {
      ...searchObject,
      arrival: arrivalHour,
      departure: departureHour,
    };
  };
  const validDimensionsInput = () =>
    BerthFunctions.boatDimensionsValid(
      searchObject.lengthInput,
      searchObject.widthInput,
      searchObject.depthInput
    );
  const berthsCompatibleIn = () =>
    BerthFunctions.berthsCompatible(
      searchObject.berths,
      searchObject.lengthInput,
      searchObject.widthInput,
      searchObject.depthInput
    );

  if (validDimensionsInput() && berthsCompatibleIn()) {
    if (searchObject.berthSearchResult.length > 0) {
      return (
        <BerthInput
          selectedBerth={selectedBerth}
          setSelectedBerth={setSelectedBerth}
          berthSearchResult={searchObject.berthSearchResult}
          harbour={searchObject.harbour}
          criteria={modSearchObject()}
          submitDestination={submitDestination}
        />
      );
    } else if (searchObject.trailSearchResult.length > 0) {
      return (
        <Trail
          trailSearchResult={searchObject.trailSearchResult}
          harbour={searchObject.harbour}
          criteria={modSearchObject()}
          submitDestination={submitDestination}
        />
      );
    } else if (
      BerthFunctions.boatDimensionsValid(
        searchObject.length,
        searchObject.width,
        searchObject.depth
      ) &&
      searchObject.arrival &&
      searchObject.departure
    ) {
      console.log(
        `Neither berthSearchResult nor trailSearchResult have length > 0`
      );
      return <div>{t("book.noavailableberths")}</div>;
    }
  } else {
    console.log(`BerthInput not shown`);
  }
};

export default BerthOrTrail;
