import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { HBApi } from "./HBApi";
import { DateFunctions } from "./hmehelpers/DateFunctions";
import { Row, Col, Container } from "reactstrap";
import AppNavbarLight from "./AppNavbarLight";

const BookingInformation = () => {
  const { id } = useParams();
  const [bookingInformation, setBookingInformation] = useState({});
  useEffect(() => {
    HBApi.getBookingByIdNoJson(id)
      .then((response) => response.json())
      .then((data) => {
        console.log(`Received data : ${JSON.stringify(data)}`);
        setBookingInformation(data);
      });
  }, []);

  return (
    <Container>
      <Row>
        <Col md="12">
          <AppNavbarLight />
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <h1 align="center">Booking information</h1>
        </Col>
      </Row>
      <Row>
        <Col></Col>
        <Col></Col>
        <Col>Booking reference:</Col>
        <Col>{bookingInformation?.reference}</Col>
        <Col></Col>
        <Col></Col>
      </Row>
      <Row>
        <Col></Col>
        <Col></Col>
        <Col>Booking made at: </Col>
        <Col>
          {DateFunctions.stringToDateString(bookingInformation?.createdAt)}
        </Col>
        <Col></Col>
        <Col></Col>
      </Row>
      <Row>
        <Col></Col>
        <Col></Col>
        <Col>Departure: </Col>
        <Col>{bookingInformation?.to}</Col>
        <Col></Col>
        <Col></Col>
      </Row>
      <Row>
        <Col></Col>
        <Col></Col>
        <Col>Cancellable:</Col>
        <Col id="cancellable">
          {bookingInformation?.cancellable ? "Yes" : "No"}
        </Col>
        <Col></Col>
        <Col></Col>
      </Row>
      <Row>
        <Col></Col>
        <Col></Col>
        <Col>Boat name:</Col>
        <Col id="boatName">{bookingInformation?.boatName}</Col>
        <Col></Col>
        <Col></Col>
      </Row>
    </Container>
  );
};

export default BookingInformation;
