import React from "react";
import { Link } from "react-router-dom";

import { Row, Col, Container, Nav, NavItem, NavLink } from "reactstrap";
import "../App.css";

import { useAuth } from "../AuthContext";
import RecentStats from "./RecentStats";

const ChildrenHandler = ({ children }) => {
  if (!children) {
    return <RecentStats />;
  } else {
    return children;
  }
};

const AdminPage = ({ children }) => {
  const auth = useAuth();
  const allowed = auth.hasRole("ADMIN");
  if (!allowed) {
    return <div>Forbidden</div>;
  }
  return (
    <Container>
      <Row>
        <Col md="12">
          <h1 align="center">Admin zone</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <Nav tabs>
            <NavItem>
              <NavLink
                id="recentStatsId"
                tag={Link}
                to="/admin/recentstats"
                active
              >
                Revenue & availability
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                id="userActivityStatsId"
                tag={Link}
                to="/admin/useractivitystats"
                active
              >
                User activity
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                id="berthAdminId"
                tag={Link}
                to="/admin/berthadmin"
                active
              >
                Berth admin
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                id="facilityCodeAdminId"
                tag={Link}
                to="/admin/facilitycodeadmin"
                active
              >
                Facility codes
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                id="settlementId"
                tag={Link}
                to="/admin/settlement"
                active
              >
                Settlement
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink id="settledId" tag={Link} to="/admin/settled" active>
                Past settlements
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink id="bookingsId" tag={Link} to="/admin/bookings" active>
                Bookings
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
      <ChildrenHandler children={children} />
    </Container>
  );
};

export default AdminPage;
