import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

const initialState = {};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    usersignin(state, action) {
      console.log(`Enter userslice with action ${JSON.stringify(action)}`);
      state.userinformation = action.payload;
    },
    boatinformation(state, action) {
      state.boatinformation = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      return initialState;
    });
  },
});

export const { usersignin, boatinformation } = userSlice.actions;
export const userSliceName = userSlice.reducer;
