import "./App.css";
import ManageBookingAnonymous from "./booking/ManageBookingAnonymous";
import Home from "./Home";
import About from "./info/About";
import Faq from "./info/Faq";
import Terms from "./info/Terms";
import InfoPage from "./InfoPage";
import Harbour from "./Harbour";
import SignIn from "./SignIn";
import SignOut from "./SignOut";
import SignUp from "./SignUp";
import SignUpSuccess from "./SignUpSuccess";
import BookingDetails from "./BookingDetails";
import BookingConfirmation from "./BookingConfirmation";
import BookingInformation from "./BookingInformation";
import BookingConflict from "./BookingConflict";
import VerifyEmail from "./VerifyEmail";
import Profile from "./Profile";
import FutureBookings from "./FutureBookings";
import FeeSupport from "./admin/FeeSupport";
import RecentStats from "./admin/RecentStats";
import UserActivitystats from "./admin/UserActivityStats";
import BerthAvailability from "./admin/BerthAvailability";
import FacilityCodeAdmin from "./admin/FacilityCodeAdmin";
import Settlement from "./admin/Settlement";
import Settled from "./admin/Settled";
import Bookings from "./admin/Bookings";
import AdminPage from "./admin/AdminPage";
import HarbourEmbedded from "./embedded/HarbourEmbedded";
import BookingDetailsEmbedded from "./embedded/BookingDetailsEmbedded";
import { AuthProvider } from "./AuthContext";
import { useAuth } from "./AuthContext";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";
import BookingsHistory from "./BookingsHistory";
import WithNav from "./WithNav";
import WithNavAndFooter from "./WithNavAndFooter";
import MyPageNoNav from "./MyPageNoNav";
import MyHarbourNoNav from "./MyHarbourNoNav";
import BookingCancellationAnonymous from "./BookingCancellationAnonymous";
import ResetPassword from "./ResetPassword";
import ForgotPassword from "./ForgotPassword";
import PaymentCompleted from "./PaymentCompleted";
import BookingOverlay from "./admin/BookingOverlay";
import "./i18n";
import { useIdleTimer } from "react-idle-timer";

const React = require("react"); // <1>
const ReactDOM = require("react-dom"); // <2>

const App = () => {
  const ProtectedRoute = ({ children, nextpage }) => {
    const auth = useAuth();
    const user = auth.getUser();

    console.log(
      `Enter ProtectedRoute User: ${JSON.stringify(
        user
      )} and next page: ${JSON.stringify(nextpage)}`
    );
    if (!auth.userIsAuthenticated()) {
      return <Navigate to="/signin" state={{ nextpage: nextpage }} />;
    }

    return children;
  };

  const LogoutOnIdle = ({ children }) => {
    const navigate = useNavigate();
    const onIdle = () => {
      console.log(`onIdle called`);
      navigate("/signout");
    };

    const { getRemainingTime } = useIdleTimer({
      onIdle,
      timeout: 1200_000,
      throttle: 5000,
    });
    return children;
  };

  return (
    <AuthProvider>
      <Router>
        <LogoutOnIdle>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route
              exact
              path="/admin/feesupport"
              element={
                <ProtectedRoute nextpage="/admin/feesupport">
                  <WithNav>
                    {" "}
                    <FeeSupport />
                  </WithNav>
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/admin/adminpage"
              element={
                <ProtectedRoute nextpage="/admin/adminpage">
                  <WithNav>
                    <AdminPage />
                  </WithNav>
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/admin/recentstats"
              element={
                <ProtectedRoute nextpage="/admin/recentstats">
                  <WithNav>
                    <AdminPage>
                      <RecentStats />
                    </AdminPage>
                  </WithNav>
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/admin/useractivitystats"
              element={
                <ProtectedRoute nextpage="/admin/useractivitystats">
                  <WithNav>
                    <AdminPage>
                      <UserActivitystats />
                    </AdminPage>
                  </WithNav>
                </ProtectedRoute>
              }
            />

            <Route
              exact
              path="/admin/berthadmin"
              element={
                <ProtectedRoute nextpage="/admin/berthadmin">
                  <WithNav>
                    <AdminPage>
                      <BerthAvailability />
                    </AdminPage>
                  </WithNav>
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/admin/facilitycodeadmin"
              element={
                <ProtectedRoute nextpage="/admin/facilitycodeadmin">
                  <WithNav>
                    <AdminPage>
                      <FacilityCodeAdmin />
                    </AdminPage>
                  </WithNav>
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/admin/settlement"
              element={
                <ProtectedRoute nextpage="/admin/settlement">
                  <WithNav>
                    <AdminPage>
                      <Settlement />
                    </AdminPage>
                  </WithNav>
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/admin/settled"
              element={
                <ProtectedRoute nextpage="/admin/settled">
                  <WithNav>
                    <AdminPage>
                      <Settled />
                    </AdminPage>
                  </WithNav>
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/admin/bookings"
              element={
                <ProtectedRoute nextpage="/admin/bookings">
                  <WithNav>
                    <AdminPage>
                      <Bookings />
                    </AdminPage>
                  </WithNav>
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/admin/bookingoverlay/:id"
              element={
                <ProtectedRoute nextpage="/admin/bookingoverlay">
                  <WithNav>
                    <AdminPage>
                      <BookingOverlay />
                    </AdminPage>
                  </WithNav>
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/about"
              element={
                <WithNav>
                  <InfoPage>
                    <About />
                  </InfoPage>
                </WithNav>
              }
            />
            <Route
              exact
              path="/terms"
              element={
                <WithNav>
                  <InfoPage>
                    <Terms />
                  </InfoPage>
                </WithNav>
              }
            />
            <Route
              exact
              path="/faq"
              element={
                <WithNav>
                  <InfoPage>
                    <Faq />
                  </InfoPage>
                </WithNav>
              }
            />
            <Route
              path="/harbours/:id"
              exact={true}
              element={
                <WithNavAndFooter>
                  <Harbour />
                </WithNavAndFooter>
              }
            />
            <Route
              path="/embedded/harbours/:id"
              exact={true}
              element={<HarbourEmbedded />}
            />

            <Route
              path="/bookings/:id"
              exact={true}
              element={<BookingInformation />}
            />
            <Route
              path="/bookingdetails"
              element={
                <WithNavAndFooter>
                  <BookingDetails />
                </WithNavAndFooter>
              }
            />
            <Route
              path="/embedded/bookingdetails"
              element={<BookingDetailsEmbedded />}
            />
            <Route
              path="bookingconfirmation"
              element={
                <WithNav>
                  <BookingConfirmation />
                </WithNav>
              }
            />
            <Route
              path="embedded/bookingconfirmation"
              element={<BookingConfirmation />}
            />
            <Route
              path="/booking/paymentcompleted"
              element={
                <WithNav>
                  <PaymentCompleted />
                </WithNav>
              }
            />

            <Route
              path="/cancellation/:id"
              element={
                <WithNavAndFooter>
                  <BookingCancellationAnonymous />
                </WithNavAndFooter>
              }
            />
            <Route
              path="/booking/:id"
              element={
                <WithNavAndFooter>
                  <ManageBookingAnonymous />
                </WithNavAndFooter>
              }
            />
            {/* <Route
            path="/myharbour"
            element={
              <ProtectedRoute nextpage="/myharbour">
                <MyHarbour />
              </ProtectedRoute>
            }
          /> */}
            <Route
              path="/mypage"
              element={
                <ProtectedRoute nextpage="/mypage">
                  <WithNav>
                    <MyPageNoNav>
                      <Profile />
                    </MyPageNoNav>
                  </WithNav>
                </ProtectedRoute>
              }
            />
            <Route
              path="/mypage/myberth"
              element={
                <ProtectedRoute nextpage="/mypage/myberth">
                  <WithNav>
                    <MyPageNoNav>
                      <MyHarbourNoNav />
                    </MyPageNoNav>
                  </WithNav>
                </ProtectedRoute>
              }
            />
            <Route
              path="/mypage/futurebookings"
              element={
                <ProtectedRoute nextpage="/mypage/futurebookings">
                  <WithNav>
                    <MyPageNoNav>
                      <FutureBookings />
                    </MyPageNoNav>
                  </WithNav>
                </ProtectedRoute>
              }
            />
            <Route
              path="/mypage/bookingshistory"
              element={
                <ProtectedRoute nextpage="/mypage/bookingshistory">
                  <WithNav>
                    <MyPageNoNav>
                      <BookingsHistory />
                    </MyPageNoNav>
                  </WithNav>
                </ProtectedRoute>
              }
            />
            <Route path="/signin" element={<SignIn />} />
            <Route
              path="/forgotpassword"
              element={
                <WithNav>
                  <ForgotPassword />
                </WithNav>
              }
            />
            <Route
              path="/resetpassword/:id"
              element={
                <WithNav>
                  <ResetPassword />
                </WithNav>
              }
            />
            <Route path="/signout" element={<SignOut />} />
            <Route
              path="/signup"
              element={
                <WithNav>
                  <SignUp />
                </WithNav>
              }
            />
            <Route
              path="/signupsuccess"
              element={
                <WithNav>
                  <SignUpSuccess />
                </WithNav>
              }
            />
            <Route
              path="/verifyemail/:id"
              exact={true}
              element={
                <WithNav>
                  <VerifyEmail />
                </WithNav>
              }
            />
            <Route path="/bookingconflict" element={<BookingConflict />} />
          </Routes>
        </LogoutOnIdle>
      </Router>
    </AuthProvider>
  );
};

export default App;
