import { useTranslation } from "react-i18next";
import {
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  Container,
  Row,
  Col,
} from "reactstrap";
import BookingBody from "./BookingBody";
import CodeRow from "./CodeRow";
import { HarbourFunctions } from "../hmehelpers/HarbourFunctions";
import { DateFunctions } from "../hmehelpers/DateFunctions";
import { BerthFunctions } from "../hmehelpers/BerthFunctions";

const BookingAccordionItems = ({ bookings, facilityCodes }) => {
  const { t } = useTranslation();
  return bookings.map((bk, i) => (
    <AccordionItem>
      <AccordionHeader targetId={i}>
        <Container>
          <Row>
            <Col>
              <Container>
                <Row>
                  <Col>
                    <b>
                      {bk.harbourView.name} - {t("common.berthLowercase")}{" "}
                      {BerthFunctions.labels(bk.bookingTrailDeepView)}{" "}
                    </b>
                  </Col>
                </Row>
                <CodeRow
                  booking={bk.bookingTrailDeepView}
                  facilityCodes={facilityCodes}
                />
                {bk.bookingTrailDeepView.status === 2 && (
                  <Row>
                    <Col>
                      <b>{t("common.cancelledBooking")}</b>
                    </Col>
                  </Row>
                )}
                {bk.bookingTrailDeepView.status === 3 && (
                  <Row>
                    <Col>
                      <b>{t("common.partiallyCancelledBooking")}</b>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col>
                    {t("common.arrivalCapital")}:{" "}
                    {DateFunctions.stringToDateString(
                      bk.bookingTrailDeepView.from
                    )}{" "}
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col>
              {" "}
              <img
                style={{ "border-radius": "8px" }}
                src={HarbourFunctions.thumbnailPath(bk.harbourView.image)}
              ></img>{" "}
            </Col>
          </Row>
        </Container>
      </AccordionHeader>
      <AccordionBody accordionId={i}>
        <BookingBody booking={bk} overlays={[]} />
      </AccordionBody>
    </AccordionItem>
  ));
};

export default BookingAccordionItems;
