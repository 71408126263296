import { Label, Input, FormGroup, Col } from "reactstrap";
import { useTranslation } from "react-i18next";

const BookingDetailsElectricitySection = ({
  bookingDryRun,
  bookingDetails,
  setBookingDetails,
}) => {
  const { t } = useTranslation();
  const currency = () => bookingDryRun[0].currency;

  if (bookingDryRun[0].electricityPricePerDay === 0) {
    return (
      <FormGroup row>
        <Col>{t("common.electricityincluded")}</Col>
      </FormGroup>
    );
  }
  return (
    <FormGroup row switch>
      <Col>
        <Label for="el">
          {t("common.electricityCapital")}{" "}
          <b>
            {bookingDryRun[0].electricityPricePerDay} {currency()}{" "}
          </b>{" "}
          {t("common.perDay")}
        </Label>
        <Input
          id="electricity"
          checked={bookingDetails.electricity}
          type="switch"
          onClick={() =>
            setBookingDetails({
              ...bookingDetails,
              electricity: !bookingDetails.electricity,
            })
          }
        />
      </Col>
    </FormGroup>
  );
};

export default BookingDetailsElectricitySection;
