import "react-phone-number-input/style.css";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-number-input";
import React, { useState } from "react";
import { useAuth } from "./AuthContext";
import { useSelector, useDispatch } from "react-redux";
import { usersignin, boatinformation } from "./user/userreducer";
import {
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  FormGroup,
  Row,
  Col,
  Button,
  Container,
} from "reactstrap";
import "./App.css";
import Footer from "./Footer";
import { CountryDropdown } from "react-country-region-selector";
import { HBApi } from "./HBApi";

const br = "8px";

const Profile = () => {
  const ui = useSelector((store) => store.userinformation);
  const dispatch = useDispatch();
  const auth = useAuth();
  const { t } = useTranslation();

  const [profile, setProfile] = useState({
    email: auth.getUser().email,
    firstName: auth.getUser().firstName,
    lastName: auth.getUser().lastName,
    phone: auth.getUser().phone,
    country: auth.getUser().country,
    boatId: ui.boatinformation?.[0].id,
    boatType: ui.boatinformation?.[0].boatType || "sail",
    boatName: ui.boatinformation?.[0].boatName,
    boatModel: ui.boatinformation?.[0].boatModel,
    length:
      ui.boatinformation?.[0].length === 0
        ? ""
        : ui.boatinformation?.[0].length,
    width:
      ui.boatinformation?.[0].width === 0 ? "" : ui.boatinformation?.[0].width,
    depth:
      ui.boatinformation?.[0].depth === 0 ? "" : ui.boatinformation?.[0].depth,
  });

  const handleChange = async (event) => {
    const { name, value } = event.target;

    setProfile({ ...profile, [name]: value });
  };

  const toApiParam = () => {
    return {
      email: profile.email,
      firstName: profile.firstName,
      lastName: profile.lastName,
      phone: profile.phone,
      country: profile.country,
      boatDetails: {
        id: profile.boatId,
        boatType: profile.boatType,
        boatModel: profile.boatModel,
        boatName: profile.boatName,
        length: profile.length,
        width: profile.width,
        depth: profile.depth,
      },
    };
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("Submitting profile form" + profile.email);
    HBApi.updateProfile(toApiParam())
      .then((response) => {
        if (response.ok) {
          console.log("Successfully updated profile");
        }
        return response;
      })
      .then(() => HBApi.getUserInfo())
      .then((response) => response.json())
      .then((json) => {
        auth.userLogin(json);
        dispatch(usersignin(json));
      })
      .then(() => HBApi.getBoatInfo())
      .then((response) => response.json())
      .then((boatJson) => dispatch(boatinformation(boatJson)));
  };

  const formValid = () => {
    if (!profile.country || profile.country === "") return false;
    if (
      !profile.email ||
      !profile.email.includes("@") ||
      !profile.firstName ||
      (profile.firstName && profile.firstName.length < 2) ||
      !profile.lastName ||
      (profile.lastName && profile.lastName.length < 2) ||
      !profile.phone
    ) {
      return false;
    }
    if (
      !profile.boatName &&
      !profile.boatModel &&
      !profile.length &&
      !profile.width &&
      !profile.depth
    ) {
      return true;
    }
    if (
      profile.boatName &&
      profile.boatModel &&
      profile.boatType &&
      profile.length > 0 &&
      profile.width > 0 &&
      profile.depth > 0
    ) {
      return true;
    }

    return false;
  };

  const SubmitButton = () => {
    if (formValid()) {
      return (
        <Button
          id="submitButtonId"
          size="lg"
          color="primary"
          type="submit"
          onClick={handleSubmit.bind(this)}
        >
          {t("profile.updatebutton")}
        </Button>
      );
    } else {
      return (
        <Button
          id="submitButtonId"
          disabled
          size="lg"
          color="primary"
          type="submit"
        >
          {t("profile.updatebutton")}
        </Button>
      );
    }
  };

  return (
    <div>
      <Container>
        <Row>
          <Col></Col>
          <Col md="8">
            <Form
              className="bg-light border"
              style={{ "border-radius": "15px" }}
              onSubmit={handleSubmit}
            >
              <h2 align="center">{t("profile.heading")}</h2>

              <FormGroup row>
                <Col>
                  <Label for="email">{t("common.email")}</Label>
                  <InputGroup>
                    <Input
                      style={{ "border-radius": br }}
                      id="email"
                      name="email"
                      type="text"
                      bsSize="md"
                      value={profile.email}
                      disabled
                    />
                  </InputGroup>
                </Col>
              </FormGroup>

              <FormGroup row>
                <h5>{t("profile.aboutyou")}</h5>
                <Col>
                  <Label for="firstName">{t("common.firstname")}</Label>
                  <Input
                    style={{ "border-radius": br }}
                    value={profile.firstName}
                    id="firstName"
                    bsSize="md"
                    type="text"
                    name="firstName"
                    minLength="2"
                    maxLength="100"
                    onChange={handleChange}
                  />

                  <Label for="lastName">{t("common.lastname")}</Label>
                  <Input
                    style={{ "border-radius": br }}
                    value={profile.lastName}
                    id="lastName"
                    bsSize="md"
                    type="text"
                    name="lastName"
                    minLength="2"
                    maxLength="100"
                    onChange={handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col>
                  <Label for="phone">{t("common.phone")}</Label>
                  <PhoneInput
                    style={{
                      borderRadius: br,
                      backgroundColor: "white",
                      height: "40px",
                    }}
                    id="phone"
                    defaultCountry="SE"
                    placeholder="Enter phone number"
                    value={profile.phone}
                    onChange={(val) =>
                      handleChange({ target: { name: "phone", value: val } })
                    }
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col>
                  <Label for="country">{t("common.country")}</Label>
                  <InputGroup>
                    <CountryDropdown
                      style={{
                        "border-radius": br,
                        "background-color": "white",
                        height: "40px",
                      }}
                      id="country"
                      name="country"
                      value={profile.country}
                      onChange={(val) =>
                        handleChange({
                          target: { name: "country", value: val },
                        })
                      }
                    />
                    <FormFeedback>Please select country</FormFeedback>
                  </InputGroup>
                </Col>
              </FormGroup>
              <hr />
              <FormGroup row>
                <h5>{t("profile.aboutboat")}</h5>
              </FormGroup>
              <FormGroup row>
                <Col>{t("profile.boatinstruction")}</Col>
              </FormGroup>
              <FormGroup row>
                <Col>
                  <Label for="boatModel">{t("common.boatmodel")}</Label>
                  <Input
                    style={{ "border-radius": br }}
                    id="boatModel"
                    value={profile.boatModel}
                    bssize="md"
                    type="text"
                    name="boatModel"
                    minLength="2"
                    maxLength="100"
                    onChange={handleChange}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Col>
                  <Label for="boatType">{t("common.boattype")}</Label>
                  <Input
                    style={{ "border-radius": br }}
                    value={profile.boatType}
                    id="boatType"
                    bssize="md"
                    type="select"
                    name="boatType"
                    onChange={handleChange}
                  >
                    <option key="sail" value="sail">
                      {t("common.sailOption")}
                    </option>
                    <option key="motor" value="motor">
                      {t("common.motorOption")}
                    </option>
                  </Input>
                </Col>
              </FormGroup>
              <FormGroup>
                <Col>
                  <Label for="boatName">{t("common.boatname")}</Label>
                  <Input
                    style={{ "border-radius": br }}
                    id="boatName"
                    value={profile.boatName}
                    bssize="md"
                    type="text"
                    name="boatName"
                    minLength="2"
                    maxLength="100"
                    onChange={handleChange}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Col>
                  <Label for="length">{t("common.length")}</Label>
                  <Input
                    style={{ "border-radius": br }}
                    id="length"
                    value={profile.length}
                    bssize="md"
                    type="number"
                    name="length"
                    placeholder={t("common.inmeters")}
                    minLength="2"
                    maxLength="10"
                    onChange={handleChange}
                  />
                </Col>
                <Col>
                  <Label for="width">{t("common.width")}</Label>
                  <Input
                    style={{ "border-radius": br }}
                    id="width"
                    value={profile.width}
                    bssize="md"
                    type="number"
                    name="width"
                    placeholder={t("common.inmeters")}
                    minLength="2"
                    maxLength="10"
                    onChange={handleChange}
                  />
                </Col>
                <Col>
                  <Label for="depth">{t("common.depth")}</Label>
                  <Input
                    style={{ "border-radius": br }}
                    id="depth"
                    value={profile.depth}
                    bssize="md"
                    type="number"
                    name="depth"
                    placeholder={t("common.inmeters")}
                    minLength="2"
                    maxLength="10"
                    onChange={handleChange}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <InputGroup>
                  <SubmitButton />
                </InputGroup>
              </FormGroup>
            </Form>
          </Col>
          <Col></Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default Profile;
