import React from "react";
import { useTranslation } from "react-i18next";
import "../App.css";
import CookieConsent from "react-cookie-consent";

import {
  Container,
  Row,
  Col,
  List,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";

const About = () => {
  const { t } = useTranslation();

  return (
    <Container fluid className="withBgImage">
      <Container fluid>
        <Row>
          <Col md="2" />
          <Col md="8">
            <div className="home-text-on-image">{t("info.about.heading")}</div>
          </Col>
          <Col md="2" />
        </Row>
        <Row>
          <Col></Col>
          <Col md="6">
            <Card>
              <CardHeader className="fw-bold p-4 my-1 fs-3">
                {t("info.about.subheading2")}
              </CardHeader>
              <CardBody>
                <List className="p-2 my-2">
                  <li className="fw-bold p-2 my-1">
                    {t("info.about.generaldescription")}
                  </li>
                  <li className="fw-bold p-2 my-1">{t("info.about.desc0")}</li>
                  <li className="fw-bold p-2 my-1">{t("info.about.desc1")}</li>
                </List>
              </CardBody>
            </Card>
          </Col>
          <Col></Col>
        </Row>
        <Row>
          <Col></Col>
          <Col>
            <div className="text-on-image-medium">{t("info.about.enroll")}</div>
          </Col>
          <Col></Col>
        </Row>
        <Row>
          <Col></Col>
          <Col>
            <div className="text-on-image-small">
              {t("info.about.operatedby")}
            </div>
          </Col>
          <Col></Col>
        </Row>
      </Container>
      <CookieConsent
        style={{ background: "#556577" }}
        buttonStyle={{
          background: "#FEFEFE",
          color: "#4e503b",
          fontSize: "13px",
        }}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </Container>
  );
};

export default About;
