import React from "react";
import { Row, Col, Container, Table } from "reactstrap";
import "../App.css";
import { DateFunctions } from "../hmehelpers/DateFunctions";

const Electricity = ({ booking }) => {
  if (!booking.electricity) {
    return "-";
  } else {
    return `${booking.electricityPrice} ${booking.currency}`;
  }
};
const CancellationFee = ({ day, booking }) => {
  if (!booking.cancellable) {
    return "-";
  } else if (day.initialCancellableStatus === "CANCELLABLE") {
    return booking.cancellablePrice + " " + day.currency;
  } else {
    return "-";
  }
};
const BookingDay = ({ day, index, booking }) => {
  return (
    <tr key={index}>
      <td>Day {index + 1}</td>
      <td>
        {day.berthPrice} {day.currency}
      </td>
      <td>
        <Electricity booking={booking} />
      </td>
      <td>
        {day.bookingPrice} {day.currency}
      </td>
      <td>
        <CancellationFee day={day} booking={booking} />
      </td>
      <td>{day.status}</td>

      <td>{day.initialCancellableStatus}</td>
    </tr>
  );
};
const BookingDays = ({ booking }) => {
  return (
    <Table striped responsive>
      <thead>
        <tr>
          <th>Day</th>
          <th>Berth</th>
          <th>Electricity</th>
          <th>Booking fee</th>
          <th>Cancellation fee</th>
          <th>Final status</th>
          <th>Cancellable</th>
        </tr>
      </thead>
      <tbody>
        {booking.bookingDays.map((d, index) => (
          <BookingDay day={d} index={index} booking={booking} />
        ))}
      </tbody>
    </Table>
  );
};
const StatusInfo = ({ booking }) => {
  switch (booking.status) {
    case 1:
      return "Completed";

    case 2:
      return "Cancelled";
    case 3:
      return "Partially cancelled";
    default:
      return "Unknown status";
  }
};

const SettlementDetails = ({ details }) => {
  console.log(`Settlement details with: ${JSON.stringify(details)}`);
  return (
    <Container>
      {details.map((sb) => (
        <div>
          <Row>
            <Col>
              <h3>Booking: reference {sb.reference} </h3>
            </Col>
          </Row>
          <Row>
            <Col>
              {DateFunctions.onlyDateString(sb.from)} -{" "}
              {DateFunctions.onlyDateString(sb.to)}
            </Col>
            <Col></Col>
            <Col>
              <b>
                <StatusInfo booking={sb} />
              </b>
            </Col>
            <Col></Col>
          </Row>
          <Row>
            <Col>
              <b>Total net:</b>{" "}
              {Math.round((sb.payoutNet + sb.payoutNoVat) * 100) / 100}{" "}
              {sb.currency}
            </Col>
            <Col>
              <b>Of which cancellation fees:</b> {sb.payoutNoVat} {sb.currency}
            </Col>
            <Col>
              <b>Vat: </b>
              {sb.payoutVat} {sb.currency}
            </Col>
            <Col>
              <b>Transaction fees: </b>
              {sb.feesTotal} {sb.currency}
            </Col>
          </Row>
          <Row>
            <BookingDays booking={sb} />
          </Row>
        </div>
      ))}
    </Container>
  );
};

export default SettlementDetails;
