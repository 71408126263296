import { LatLngBounds } from "leaflet";
import "leaflet/dist/leaflet.css";
import React from "react";
import { MapContainer, TileLayer, Circle, ImageOverlay } from "react-leaflet";
import { useSelector } from "react-redux";
const HarbourMap = ({
  coords,
  selectedBerth,
  setSelectedBerth,
  berthSearchResult,
  trailSearchResult,
}) => {
  const [lat, lng] = coords;
  const bounds = new LatLngBounds(
    [lat - 0.0005, lng - 0.0013],
    [lat + 0.0005, lng + 0.0013]
  );
  console.log(
    `Enter HarbourMap with selected berth: ${selectedBerth} coords: ${JSON.stringify(
      coords
    )} and bearthSearchResult size: ${berthSearchResult.length}`
  );
  const searchObject = useSelector((store) => store.booking);
  const BerthMarkers = () => {
    return berthSearchResult
      .filter((b) => b.enabled)
      .map((berth) => {
        var radius = selectedBerth === berth.id && berth.available ? 4 : 2;
        var color = berth.available ? "green" : "gray";
        if (selectedBerth === berth.id && berth.available) color = "white";
        return (
          <Circle
            key={berth.id}
            center={[berth.latitude, berth.longitude]}
            radius={radius}
            color={color}
            eventHandlers={{
              click: (event) => {
                console.log(
                  "marker clicked for key: " +
                    berth.id +
                    ", previously selected berth " +
                    selectedBerth
                );
                setSelectedBerth(berth.id);
              },
            }}
          />
        );
      });
  };
  const TrailMarkers = () => {
    return trailSearchResult.map((tsv) => {
      return (
        <Circle
          key={tsv.id}
          center={[tsv.latitude, tsv.longitude]}
          radius="4"
          color="white"
        />
      );
    });
  };

  return (
    <div>
      <MapContainer center={coords} zoom={17} scrollWheelZoom={false}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {berthSearchResult.length > 0 && <BerthMarkers />}
        {trailSearchResult.length > 0 && <TrailMarkers />}
        {searchObject.loading && (
          <ImageOverlay
            bounds={bounds}
            url="/images/loading.webp"
            opacity={0.3}
            zIndex={10}
          />
        )}
        {/* {searchObject.loading && (
          <TileLayer url="/images/loading.webp" opacity={0.3} zIndex={10} />
        )} */}
      </MapContainer>
    </div>
  );
};

export default HarbourMap;
