import { addDays, differenceInDays, isAfter, isBefore } from "date-fns";

export const BerthFunctions = {
  bookedDays,
  statusDaysExcludingBookedDays,
  atLeastOneDayChangesStatusToEnabled,
  statusDays,
  futureStatusDays,
  near,
  nearB,
  distance,
  distanceB,
  labels,
  berthCompatible,
  berthsCompatible,
  berthsCompatibleList,
  isDimValid,
  boatDimensionsValid,
};

function wouldChangesStatusToEnabled(start, end, futureStatuses) {
  start.setHours(12);
  end.setHours(12);
  let statusesSet = new Set(statusDays(futureStatuses).map((d) => d.getTime()));
  let notAlreadyEnabled = expandDays(start.getTime(), end.getTime())
    .map((d) => d.getTime())
    .filter((candidateDay) => !statusesSet.has(candidateDay))
    .map((c) => new Date(c));
  return notAlreadyEnabled;
}

function atLeastOneDayChangesStatusToEnabled(start, end, futureStatuses) {
  return wouldChangesStatusToEnabled(start, end, futureStatuses).length > 0;
}

function statusDays(statuses) {
  return statuses
    .map((st) => [Date.parse(st.from), Date.parse(st.to)])
    .flatMap((pair) => expandDays(pair[0], pair[1]));
}

function futureStatusDays(statuses) {
  const now = new Date();
  now.setHours(12, 0, 0, 0);
  return statuses
    .filter((st) => isAfter(new Date(Date.parse(st.to)), now))
    .map((st) => {
      if (isBefore(new Date(Date.parse(st.from)), now)) {
        return { ...st, from: now };
      } else {
        return st;
      }
    })
    .map((st) => [Date.parse(st.from), Date.parse(st.to)])
    .flatMap((pair) => expandDays(pair[0], pair[1]));
}

function bookedDays(bookings) {
  return bookings
    .map((b) => [Date.parse(b.from), Date.parse(b.to)])
    .flatMap((pair) => expandDays(pair[0], pair[1]));
}

function statusDaysExcludingBookedDays(statuses, bookings) {
  const dbSet = new Set(bookedDays(bookings).map((d) => d.getTime()));

  return statuses
    .map((st) => [Date.parse(st.from), Date.parse(st.to)])
    .flatMap((pair) => expandDays(pair[0], pair[1]))
    .filter((d) => !dbSet.has(d.getTime()));
}

function expandDays(from, to) {
  let dayCount = differenceInDays(to, from);
  let range = Array.from({ length: dayCount }, (value, index) => index);
  let rv = range.map((count) => addDays(new Date(from), count));
  return rv;
}
const oneMeter = 0.000009009;

function nearB(thisBerth, berth, distanceInMeters) {
  const d = distanceB(thisBerth, berth);

  return d < distanceInMeters * oneMeter;
}

function distance(coords, berth) {
  const y0 = coords.latitude;
  const y1 = berth.latitude;
  const x0 = coords.longitude;
  const x1 = berth.longitude;
  const yDelta = parseFloat(y1) - parseFloat(y0);
  const xDelta = parseFloat(x1) - parseFloat(x0);
  return Math.sqrt(yDelta * yDelta + xDelta * xDelta);
}
function near(latLng, berth, distanceInMeters) {
  const distanceV = distance(latLng, berth);

  const rv = distanceV < distanceInMeters * oneMeter;
  if (berth.label === "A 2") {
    console.log(`latLng: ${JSON.stringify(latLng)}`);
    console.log(`distance is ${distanceV / oneMeter} meters`);
    console.log(`Returning ${rv}`);
  }

  return rv;
}

function distanceB(thisBerth, berth) {
  const y0 = thisBerth.latitude;
  const y1 = berth.latitude;
  const x0 = thisBerth.longitude;
  const x1 = berth.longitude;
  const yDelta = parseFloat(y1) - parseFloat(y0);
  const xDelta = parseFloat(x1) - parseFloat(x0);
  return Math.sqrt(yDelta * yDelta + xDelta * xDelta);
}

function labels(bookingTrailDeepView) {
  if (bookingTrailDeepView.bdvs.length === 1) {
    return bookingTrailDeepView.bdvs[0].label;
  } else {
    return bookingTrailDeepView.bdvs
      .map((b) => `${b.label}, `)
      .reduce((l, r) => l + r);
  }
}

// Return true iff all measurements are valid and berth is compatible
function berthCompatible(berth, length, width, depth) {
  var rv = true;
  if (
    !isDimValid(length) ||
    !isDimValid(width) ||
    !isDimValid(depth) ||
    (isDimValid(length) && berth.maxLength < length) ||
    (isDimValid(length) && berth.minLength > length) ||
    (isDimValid(width) && berth.maxWidth < width) ||
    (isDimValid(width) && berth.minWidth > width) ||
    (isDimValid(depth) && berth.maxDepth < depth) ||
    (isDimValid(depth) && berth.minDepth > depth)
  ) {
    rv = false;
  }
  return rv;
}
function isDimValid(value) {
  var iv = true;
  if (!value) iv = false;
  if (value <= 0) iv = false;
  return iv;
}

function berthsCompatibleList(berthsToEvaluate, length, width, depth) {
  return berthsToEvaluate.filter(function (berth) {
    return berthCompatible(berth, length, width, depth);
  });
}

function berthsCompatible(berthsToEvaluate, length, width, depth) {
  let compatible = berthsToEvaluate.filter(function (berth) {
    return berthCompatible(berth, length, width, depth);
  });
  console.log(
    "Found " +
      compatible.length +
      " berths compatible with dimensions (l, w, d): " +
      length +
      ", " +
      width +
      ", " +
      depth
  );
  return compatible.length !== 0;
}
function boatDimensionsValid(length, width, depth) {
  var isValidDim = false;
  if (
    BerthFunctions.isDimValid(length) &&
    BerthFunctions.isDimValid(width) &&
    BerthFunctions.isDimValid(depth)
  )
    isValidDim = true;
  return isValidDim;
}
