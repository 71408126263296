import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams, Link } from "react-router-dom";
import PasswordStrengthBar from "react-password-strength-bar";
import {
  Label,
  Input,
  InputGroup,
  Form,
  FormText,
  FormFeedback,
  Row,
  Col,
  Button,
  Container,
  FormGroup,
  Toast,
  ToastHeader,
  ToastBody,
} from "reactstrap";
import "./App.css";
import Footer from "./Footer";
import { HBApi } from "./HBApi";

const ResetPassword = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [credentials, setCredentials] = useState({});
  const [resetSuccess, setResetSuccess] = useState(false);
  const [errorDiffering, setErrorDiffering] = useState(false);
  const [resetError, setResetError] = useState();

  useEffect(() => {
    HBApi.markPasswordTokenFetched(id).then((response) => {
      if (!response.ok) {
        setResetError(response.status);
      }
    });
  }, []);

  const handleChange = async (event) => {
    const { name, value } = event.target;
    if (errorDiffering) {
      setErrorDiffering(false);
    }
    setCredentials({ ...credentials, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("Submitting credentials in handleSubmit" + credentials.email);
    if (credentials.password0 !== credentials.password1) {
      setErrorDiffering(true);
      return;
    }
    console.log(
      `handleSubmit using credentials ${JSON.stringify(
        credentials
      )} and token ${id}`
    );
    HBApi.resetPassword(id, credentials.password0)
      .then((response) => {
        if (response.ok) {
          setResetSuccess(true);
        }
        return Promise.reject(response);
      })
      .catch((errorResponse) => {
        console.log("errorResponse " + errorResponse.status);
        setResetError(errorResponse.status);
      });
  };
  if (resetSuccess) {
    return (
      <div className="p-3 my-2 bg-secondary rounded">
        <Container>
          <Row>
            <Col>
              <Toast>
                <ToastHeader>{t("doresetpassword.successmessage")}</ToastHeader>
                <ToastBody>
                  {t("doresetpassword.backto")}{" "}
                  <Link id="backToSignInId" to="/signin">
                    {t("doresetpassword.signin")}
                  </Link>
                </ToastBody>
              </Toast>
            </Col>
          </Row>
        </Container>
      </div>
    );
  } else if (resetError === 417) {
    return (
      <div className="p-3 my-2 bg-danger rounded">
        <Container>
          <Row>
            <Col>
              <Toast>
                <ToastHeader>
                  {t("doresetpassword.notintimeheading")}
                </ToastHeader>
                <ToastBody>
                  {t("doresetpassword.notintimemessage")}{" "}
                  {t("doresetpassword.backto")}{" "}
                  <Link id="backToSignInId" to="/signin">
                    {t("doresetpassword.signin")}
                  </Link>
                </ToastBody>
              </Toast>
            </Col>
          </Row>
        </Container>
      </div>
    );
  } else if (resetError === 409) {
    return (
      <div className="p-3 my-2 bg-danger rounded">
        <Container>
          <Row>
            <Col>
              <Toast>
                <ToastHeader>
                  {t("doresetpassword.linkalreadyusedheading")}
                </ToastHeader>
                <ToastBody>
                  {t("doresetpassword.alreadyusedmessage")}{" "}
                  {t("doresetpassword.backto")}{" "}
                  <Link id="backToSignInId" to="/signin">
                    {t("doresetpassword.signin")}
                  </Link>
                </ToastBody>
              </Toast>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  return (
    <div>
      <Container>
        <Row>
          <Col md="1"></Col>
          <Col>
            <Form
              className="bg-light border"
              style={{ borderRadius: "15px" }}
              onSubmit={handleSubmit}
            >
              <h2 align="center">{t("doresetpassword.heading")}</h2>
              <FormGroup row>
                <Label for="password0">
                  {t("doresetpassword.newpasswordlabel")}
                </Label>
                <InputGroup>
                  {errorDiffering ? (
                    <Input
                      style={{ bordeRadius: "8px" }}
                      id="password0Id"
                      name="password0"
                      type="password"
                      invalid
                      onChange={handleChange}
                    />
                  ) : (
                    <Input
                      style={{ borderRadius: "8px" }}
                      id="password0Id"
                      name="password0"
                      type="password"
                      onChange={handleChange}
                    />
                  )}
                  <FormFeedback>
                    {t("doresetpassword.passwordsdifferfeedback")}
                  </FormFeedback>
                </InputGroup>
                <FormText>
                  {t("doresetpassword.minimumcharacterstext")}
                </FormText>

                <PasswordStrengthBar
                  minLength="8"
                  password={credentials.password0}
                />
              </FormGroup>
              <FormGroup row>
                <Label for="password1">
                  {t("doresetpassword.repeatpasswordlabel")}
                </Label>
                <InputGroup>
                  {errorDiffering ? (
                    <Input
                      style={{ borderRadius: "8px" }}
                      id="password1Id"
                      name="password1"
                      type="password"
                      invalid
                      onChange={handleChange}
                    />
                  ) : (
                    <Input
                      style={{ borderRadius: "8px" }}
                      id="password1Id"
                      name="password1"
                      type="password"
                      onChange={handleChange}
                    />
                  )}

                  <FormFeedback>
                    {t("doresetpassword.passwordsdifferfeedback")}
                  </FormFeedback>
                </InputGroup>
              </FormGroup>
              <FormGroup row>
                <InputGroup>
                  <Button size="md" color="primary" id="submitId" type="submit">
                    {t("doresetpassword.buttontext")}
                  </Button>
                </InputGroup>
              </FormGroup>
            </Form>
          </Col>
          <Col md="1"></Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default ResetPassword;
