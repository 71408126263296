import { useTranslation } from "react-i18next";
import { Row, Col } from "reactstrap";
import { DateFunctions } from "../hmehelpers/DateFunctions";

const CodeRow = ({ booking, facilityCodes }) => {
  console.log(`Coderow with codes: ${JSON.stringify(facilityCodes)}`);
  const { t } = useTranslation();
  const codeTypeLabel = (constantName) => {
    if (constantName === "TOILETENTRANCE") {
      return t("facilitycodes.toilet");
    } else {
      return t("facilitycodes.entrance");
    }
  };
  const codes = DateFunctions.spannedBy(new Date(), booking.from, booking.to)
    ? facilityCodes
        .filter((array) => array[0]?.harbourId === booking.harbourId)
        .map((array) =>
          array.filter((code) =>
            DateFunctions.spannedBy(new Date(), code.from, code.to)
          )
        )
    : [];
  if ((booking.status === 1 || booking.status === 3) && codes[0]) {
    return codes[0]
      .filter((code) => code.communicationType === "WEB")
      .map((code) => (
        <Row>
          <Col>
            {codeTypeLabel(code.type)}: {code.code}
          </Col>
        </Row>
      ));
  }
};
export default CodeRow;
