export const apiAddress = () => {
  console.log(`NODE_ENV is ${process.env.NODE_ENV}`);
  if (process.env.NODE_ENV === "development") {
    return `${window.location.protocol}//${window.location.hostname}:8080/api`;
  } else {
    return window.location.protocol + "//" + window.location.hostname + "/api";
  }
};
export const maxBookingAheadDays = 240;
export const berthsAddress = apiAddress() + "/berths";
export const berthsSearchAddress = berthsAddress + "/search";
export const unavailableBerthsAddress = berthsAddress + "/unavailabledates";
export const myBerthsAddress = berthsAddress + "/myberth";
export const myBerthStatusesAddress = apiAddress() + "/berths/myberthstatuses";
export const statusAddress = apiAddress() + "/berths/status";
export const adminBerthStatusAddress = apiAddress() + "/admin/berths/status";
export const harboursAddress = apiAddress() + "/harbours";
export const harboursSearchAddress = harboursAddress + "/search?q=";
export const trailsSearchAddress = apiAddress() + "/trails/search";
export const signinAddress = apiAddress() + "/signin";
export const userinfoAddress = apiAddress() + "/userinfo";
export const admininfoAddress = apiAddress() + "/admin/admininfo";
export const adminBerthsAddress = apiAddress() + "/admin/berths";
export const adminStatsAddress = apiAddress() + "/admin/stats";
export const boatinfoAddress = apiAddress() + "/boatinfo";
export const signoutAddress = apiAddress() + "/signout";
export const csrfAddress = apiAddress() + "/csrf";
export const bookingsAddress = apiAddress() + "/bookings";
export const bookingWithPaymentAddress = apiAddress() + "/bookingrequests";
export const bookingReferenceByPaymentAddress =
  apiAddress() + "/bookings/reference";
export const adminBookingsAddress = apiAddress() + "/admin/bookings";
export const adminBookingByHarbourAddress =
  apiAddress() + "/admin/bookingsbyharbour";
export const adminOverlaysByHarbourAddress =
  apiAddress() + "/admin/overlaysbyharbour";
export const userFutureBookingAddress = bookingsAddress + "/userfuturebookings";
export const userHistoricBookingAddress =
  bookingsAddress + "/userhistoricbookings";
//export const cancelBookingAddress = bookingsAddress + "/cancel";
export const cancelBookingAddressDaylist = bookingsAddress + "/canceldetailed";

export const signupAddress = apiAddress() + "/bookingusers";
export const verifyRegistrationEmailAddress =
  apiAddress() + "/confirmregistration";
export const validateEmailAddress = apiAddress() + "/validateemail";
export const mayResendRerificationEmailAddress =
  apiAddress() + "/mayresendverification";
export const resendVerificationEmailAddress =
  apiAddress() + "/resendverification";
export const forgotPasswordAddress = apiAddress() + "/forgotpassword";
export const resetPasswordAddress = apiAddress() + "/resetpassword";
export const markPasswordTokenFetchedAddress =
  apiAddress() + "/fetchpasswordtoken";
export const adminGetBerthStatusFeeSupportAddress =
  apiAddress() + "/admin/berths/statusoverview";
export const adminFacilityCodeAddress = apiAddress() + "/admin/facility/codes";
export const adminSettlementsAddress = apiAddress() + "/admin/settlements";
