import { addDays } from "date-fns";
import {
  berthsAddress,
  unavailableBerthsAddress,
  adminBerthsAddress,
  berthsSearchAddress,
  myBerthStatusesAddress,
  statusAddress,
  adminBerthStatusAddress,
  harboursAddress,
  harboursSearchAddress,
  signinAddress,
  signoutAddress,
  forgotPasswordAddress,
  resetPasswordAddress,
  markPasswordTokenFetchedAddress,
  userinfoAddress,
  admininfoAddress,
  adminStatsAddress,
  boatinfoAddress,
  trailsSearchAddress,
  myBerthsAddress,
  bookingsAddress,
  bookingWithPaymentAddress,
  userFutureBookingAddress,
  userHistoricBookingAddress,
  cancelBookingAddress,
  csrfAddress,
  signupAddress,
  verifyRegistrationEmailAddress,
  validateEmailAddress,
  mayResendRerificationEmailAddress,
  resendVerificationEmailAddress,
  adminGetBerthStatusFeeSupportAddress,
  bookingReferenceByPaymentAddress,
  adminBookingsAddress,
  adminFacilityCodeAddress,
  adminSettlementsAddress,
  adminBookingByHarbourAddress,
  adminOverlaysByHarbourAddress,
  cancelBookingAddressDaylist,
} from "./HBConstants";
import i18n from "./i18n";

export const HBApi = {
  postWithCsrf,
  postWithCsrfResponse,
  getBerthsForHarbour,
  getMyBerths,
  getHarbourById,
  searchHarbour,
  searchBerthByHarbourAndCriteria,
  getBerthConfigurationByHarbour,
  unavailableDatesByCriteria,
  signIn,
  signOut,
  signUp,
  forgotPassword,
  resetPassword,
  updateProfile,
  verifyRegistrationEmail,
  getUserInfo,
  getBoatInfo,
  getTrails,
  book,
  bookWithPayment,
  bookingDryRun,
  getBookingsByBerth,
  getAdminBookingsByBerth,
  getAdminBookingsByHarbour,
  getAdminOverlaysByHarbour,
  getAdminOverlaysByBooking,
  overlayBooking,
  getUserFutureBookings,
  getUserHistoricBookings,
  getBookingByIdNoJson,
  getBookingReference,
  getBookingReferences,
  getBookingReferenceByPaymentExternalId,
  cancelBookingDaylist,
  cancelDryRun,
  getBerthStatusesByBerth,
  modifyBerthStatus,
  adminModifyBerthStatus,
  adminDeleteBerthStatus,
  deleteBerthStatus,
  validateEmail,
  mayResendVerification,
  requestResend,
  getAdminBerthStatusForFeeSupport,
  getAdminInfo,
  markPasswordTokenFetched,
  adminStatsRevenueByDay,
  adminStatsAvailabilityByDay,
  adminStatsAvailabilitySearchPerDay,
  adminStatsBerthAvailabilityOperationsByDay,
  adminBerthOwnerView,
  getAdminBerthStatusesByBerth,
  addFacilityCode,
  deleteFacilityCode,
  getFacilityCodesByHarbourId,
  getFacilityCodesByBooking,
  getSettlementSummaryDryrun,
  getSettlementDetailedDryrun,
  initiatePayout,
  getSettlements,
  getSettlementDetails,
};

const postOptions = (tokenResp, data) => {
  console.log("Enter postOptions with: " + JSON.stringify(tokenResp));
  return {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-TOKEN": tokenResp.token,
    },
    credentials: "include",
    body: JSON.stringify(data),
  };
};

const methodOptions = (methodP, tokenResp, data) => {
  console.log(
    `Enter methodOptions with: ${methodP} and token ${JSON.stringify(
      tokenResp
    )}`
  );
  return {
    method: methodP,
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-TOKEN": tokenResp.token,
    },
    credentials: "include",
    body: JSON.stringify(data),
  };
};
const methodOptionsNoBody = (methodP, tokenResp) => {
  console.log(
    `Enter methodOptions with: ${methodP} and token ${JSON.stringify(
      tokenResp
    )}`
  );
  return {
    method: methodP,
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-TOKEN": tokenResp.token,
    },
    credentials: "include",
  };
};

export function postWithCsrf(address, data) {
  return fetch(csrfAddress, {
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((tokenResp) =>
      fetch(address, postOptions(tokenResp, data)).then((response2) =>
        response2.json()
      )
    );
}

export function methodWithCsrfNoJson(method, address, data) {
  return fetch(csrfAddress, {
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((tokenResp) =>
      fetch(address, methodOptions(method, tokenResp, data))
    );
}
export function methodWithCsrfNoJsonNoBody(method, address) {
  return fetch(csrfAddress, {
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((tokenResp) => fetch(address, methodOptions(method, tokenResp)));
}

export function postWithCsrfNoJson(address, data) {
  return fetch(csrfAddress, {
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((tokenResp) => fetch(address, postOptions(tokenResp, data)));
}

export function postWithCsrfResponse(address, data) {
  return fetch(csrfAddress, {
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((tokenResp) => fetch(address, postOptions(tokenResp, data)));
}

function getMyBerths() {
  console.log("Enter getMyBerths");
  return fetch(`${myBerthsAddress}`, { credentials: "include" });
}

function getBerthsForHarbour(harbourId) {
  return fetch(`${berthsAddress}?harbour=${harbourId}`, {
    credentials: "include",
  });
}

function getBerthConfigurationByHarbour(harbourId) {
  return fetch(`${berthsAddress}/config/${harbourId}`, {
    credentials: "include",
  });
}

function getHarbourById(id) {
  return fetch(`${harboursAddress}/${id}`, { credentials: "include" });
}

function searchHarbour(searchTerm) {
  return fetch(`${harboursSearchAddress}${searchTerm}`, {
    credentials: "include",
  });
}

function searchBerthByHarbourAndCriteria(
  harbourId,
  arrival,
  departure,
  length,
  width,
  depth
) {
  return fetch(
    `${berthsSearchAddress}?harbour=${harbourId}&length=${length}&width=${width}&depth=${depth}&from=${arrival}&to=${departure}`,
    { credentials: "include" }
  );
}

function unavailableDatesByCriteria(
  harbourId,
  arrival,
  departure,
  length,
  width,
  depth
) {
  return fetch(
    `${unavailableBerthsAddress}?harbour=${harbourId}&length=${length}&width=${width}&depth=${depth}&from=${arrival}&to=${departure}`,
    { credentials: "include" }
  );
}

function signIn(credentials) {
  return postWithCsrfResponse(signinAddress, credentials);
}
function signOut(credentials) {
  return postWithCsrf(signoutAddress, credentials);
}
function signUp(formData) {
  return postWithCsrfResponse(signupAddress, formData);
}

function book(segments, bookingDetails, boatDetails) {
  return postWithCsrfNoJson(bookingsAddress, {
    segments,
    bookingDetails,
    boatDetails,
    language: i18n.language,
  });
}
function bookWithPayment(segments, bookingDetails, boatDetails) {
  return postWithCsrfNoJson(bookingWithPaymentAddress, {
    segments,
    bookingDetails,
    boatDetails,
    language: i18n.language,
  });
}

// function cancelBooking(idParam) {
//   console.log(`Enter HBApi cancelBooking ${idParam}`);
//   return postWithCsrfNoJson(cancelBookingAddress, { id: idParam });
// }
function cancelBookingDaylist(idParam, daylist) {
  console.log(
    `Enter HBApi cancelBooking detailed id ${idParam} ,days: ${JSON.stringify(
      Object.fromEntries(daylist)
    )}}`
  );
  return postWithCsrfNoJson(cancelBookingAddressDaylist, {
    id: idParam,
    days: Object.fromEntries(daylist),
  });
}

function cancelDryRun(idParam, fromDayParam) {
  return fetch(
    `${bookingsAddress}/${idParam}/canceldryrun?fromDay=${fromDayParam}`,
    {
      credentials: "include",
    }
  );
}
function getBookingReference(idParam) {
  return fetch(`${bookingsAddress}/${idParam}/reference`, {
    credentials: "include",
  });
}
function getBookingReferenceByPaymentExternalId(externalPaymentId, vendorInt) {
  return fetch(
    `${bookingReferenceByPaymentAddress}?paymentId=${externalPaymentId}&vendor=${vendorInt}`,
    {
      credentials: "include",
    }
  );
}
async function getBookingReferences(idsParam) {
  const responses = await Promise.all(
    idsParam.map((bookingId) => getBookingReference(bookingId))
  );
  const bookingReferencesJson = await Promise.all(
    responses.map((r) => r.json())
  );

  return bookingReferencesJson;
}

function oneSegmentBookingDryRun(trailSegment, criteria) {
  return fetch(
    `${bookingsAddress}/bookingdryrun?from=${trailSegment.from}&to=${trailSegment.to}&berthId=${trailSegment.berthId}&length=${criteria.length}&width=${criteria.width}&depth=${criteria.depth}`,
    {
      credentials: "include",
    }
  );
}
async function bookingDryRun(segments, criteria) {
  const responses = await Promise.all(
    segments.map((s) => oneSegmentBookingDryRun(s, criteria))
  );
  const dryRunJson = await Promise.all(responses.map((r) => r.json()));

  return dryRunJson;
}

function getUserInfo() {
  console.log("Enter HBApi getUserInfo");
  return fetch(`${userinfoAddress}`, { credentials: "include" });
}
function getBoatInfo() {
  console.log("Enter HBApi getBoatInfo");
  return fetch(`${boatinfoAddress}`, { credentials: "include" });
}
function getUserFutureBookings() {
  return fetch(`${userFutureBookingAddress}`, { credentials: "include" });
}
function getUserHistoricBookings() {
  return fetch(`${userHistoricBookingAddress}`, { credentials: "include" });
}

function getTrails(id, newSearchObject) {
  return fetch(
    `${trailsSearchAddress}?harbour=${id}&length=${newSearchObject.length}&width=${newSearchObject.width}&depth=${newSearchObject.depth}&from=${newSearchObject.arrival}&to=${newSearchObject.departure}`,
    { credentials: "include" }
  ).then((response0) => response0.json());
}

function getBookingsByBerth(berthId, from, to) {
  return fetch(`${bookingsAddress}?berthId=${berthId}&from=${from}&to=${to}`, {
    credentials: "include",
  }).then((response) => response.json());
}
function getAdminBookingsByBerth(berthId, from, to) {
  return fetch(
    `${adminBookingsAddress}?berthId=${berthId}&from=${from}&to=${to}`,
    {
      credentials: "include",
    }
  ).then((response) => response.json());
}
function getAdminBookingsByHarbour(harbourId, from, to) {
  return fetch(
    `${adminBookingByHarbourAddress}?harbourId=${harbourId}&from=${from}&to=${to}`,
    {
      credentials: "include",
    }
  );
}
function getAdminOverlaysByHarbour(harbourId, from, to) {
  return fetch(
    `${adminOverlaysByHarbourAddress}?harbourId=${harbourId}&from=${from}&to=${to}`,
    {
      credentials: "include",
    }
  );
}
function getAdminOverlaysByBooking(bookingTrailId) {
  return fetch(`${adminBookingsAddress}/${bookingTrailId}/overlays`, {
    credentials: "include",
  });
}

// toOverlay: map from bookingDay to boolean
// overlays: existing overlays
//
function overlayBooking(bookingTrailId, toOverlay, overlays) {
  const arg = { overlays: [] };
  toOverlay.forEach((value, key) => {
    console.log(`Key: ${key}, value: ${value}`);
    const status = value ? 2 : 1;
    if (overlays.find((ol) => ol.bookingDayId === key)) {
      const existing = overlays.find((o) => o.bookingDayId === key);

      arg.overlays.push({
        id: existing.id,
        bookingDayId: existing.bookingDayId,
        status: status,
      });
    } else {
      arg.overlays.push({ bookingDayId: key, status: status });
    }
  });
  console.log(`Will call overlays with body: ${JSON.stringify(arg)}`);
  return postWithCsrfNoJson(
    `${adminBookingsAddress}/${bookingTrailId}/overlays`,
    arg
  );
}

function getBookingByIdNoJson(id) {
  return fetch(`${bookingsAddress}/${id}`, {
    credentials: "include",
  });
}

function getBerthStatusesByBerth(berthId, from, to) {
  return fetch(
    `${myBerthStatusesAddress}?berthId=${berthId}&from=${from}&to=${to}`,
    { credentials: "include" }
  ).then((response) => response.json());
}
function getAdminBerthStatusesByBerth(berthId) {
  return fetch(`${adminBerthsAddress}/${berthId}/berthstatuses`, {
    credentials: "include",
  });
}

function modifyBerthStatus(modificationInfo) {
  return postWithCsrfNoJson(statusAddress, modificationInfo);
}
function adminModifyBerthStatus(modificationInfo) {
  return postWithCsrfNoJson(adminBerthStatusAddress, modificationInfo);
}
function deleteBerthStatus(id) {
  return methodWithCsrfNoJson("DELETE", statusAddress, id);
}
function adminDeleteBerthStatus(id) {
  return methodWithCsrfNoJson("DELETE", adminBerthStatusAddress, id);
}

function verifyRegistrationEmail(id) {
  console.log(`verifyRegistrationEmail with token ${id}`);
  return fetch(`${verifyRegistrationEmailAddress}?token=${id}`, {
    credentials: "include",
  });
}

function validateEmail(emailAddress) {
  return fetch(`${validateEmailAddress}?email=${emailAddress}`, {
    credentials: "include",
  });
}

function mayResendVerification(emailAddress) {
  return fetch(`${mayResendRerificationEmailAddress}?email=${emailAddress}`, {
    credentials: "include",
  });
}

function requestResend(emailAddress) {
  return postWithCsrfNoJson(resendVerificationEmailAddress, {
    email: emailAddress,
  });
}

function updateProfile(arg) {
  console.log(`HBApi.updateProfile : ${JSON.stringify(arg)}`);
  return methodWithCsrfNoJson("PATCH", signupAddress, arg);
}

function forgotPassword(emailAddress) {
  return postWithCsrfNoJson(forgotPasswordAddress, {
    email: emailAddress,
  });
}

function resetPassword(tokenArg, passwordArg) {
  return postWithCsrfNoJson(resetPasswordAddress, {
    password: passwordArg,
    token: tokenArg,
  });
}

function markPasswordTokenFetched(tokenArg) {
  return fetch(`${markPasswordTokenFetchedAddress}?token=${tokenArg}`, {
    credentials: "include",
  });
}

function getAdminBerthStatusForFeeSupport(harbourId, date) {
  return fetch(
    `${adminGetBerthStatusFeeSupportAddress}?harbour=${harbourId}&date=${date}`,
    {
      credentials: "include",
    }
  );
}

function getAdminInfo() {
  return fetch(admininfoAddress, { credentials: "include" });
}

function adminStatsRevenueByDay(from, to) {
  return fetch(`${adminStatsAddress}/revenuebyday?from=${from}&to=${to}`, {
    credentials: "include",
  });
}
function adminStatsAvailabilityByDay(from, to) {
  return fetch(`${adminStatsAddress}/availabilitybyday?from=${from}&to=${to}`, {
    credentials: "include",
  });
}
function adminStatsAvailabilitySearchPerDay(from, to) {
  return fetch(
    `${adminStatsAddress}/availabilitysearchbyday?from=${from}&to=${to}`,
    {
      credentials: "include",
    }
  );
}
function adminStatsBerthAvailabilityOperationsByDay(from, to) {
  return fetch(
    `${adminStatsAddress}/berthavailabilityoperationsbyday?from=${from}&to=${to}`,
    {
      credentials: "include",
    }
  );
}

function adminBerthOwnerView(id) {
  return fetch(`${adminBerthsAddress}/${id}/ownerview`, {
    credentials: "include",
  });
}

function addFacilityCode(info) {
  return postWithCsrfNoJson(adminFacilityCodeAddress, info);
}

function deleteFacilityCode(id) {
  return methodWithCsrfNoJsonNoBody(
    "DELETE",
    `${adminFacilityCodeAddress}/${id}`,
    id
  );
}
function getFacilityCodesByHarbourId(harbourId, from, to) {
  const toP = to ? to : addDays(new Date(), 365).toISOString();
  return fetch(
    `${adminFacilityCodeAddress}?harbourId=${harbourId}&from=${from}&to=${to}`,
    {
      credentials: "include",
    }
  );
}

function getFacilityCodesByBooking(bookingId) {
  return fetch(`${bookingsAddress}/${bookingId}/facilitycodes`, {
    credentials: "include",
  });
}
function getSettlementSummaryDryrun(harbourId, to) {
  return fetch(
    `${adminSettlementsAddress}/summarydryrun?harbourId=${harbourId}&to=${to}`,
    { credentials: "include" }
  );
}
function getSettlementDetailedDryrun(harbourId, to) {
  return fetch(
    `${adminSettlementsAddress}/detaileddryrun?harbourId=${harbourId}&to=${to}`,
    { credentials: "include" }
  );
}

function initiatePayout(harbourId, dateString) {
  return postWithCsrfNoJson(`${adminSettlementsAddress}`, {
    harbourId: harbourId,
    to: dateString,
  });
}
function getSettlements(harbourId, to) {
  return fetch(`${adminSettlementsAddress}?harbourId=${harbourId}&to=${to}`, {
    credentials: "include",
  });
}
function getSettlementDetails(settlementId) {
  return fetch(`${adminSettlementsAddress}/${settlementId}`, {
    credentials: "include",
  });
}
