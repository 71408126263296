import { useState, useEffect } from "react";
import { Accordion, Container, Row, Col, Spinner } from "reactstrap";
import { useNavigate } from "react-router-dom";
import BookingAccordionItems from "./bookinglists/BookingAccordionItems";
import { HBApi } from "./HBApi";
import { DateFunctions } from "./hmehelpers/DateFunctions";
import { BookingFunctions } from "./hmehelpers/BookingFunctions";
import { useAuth } from "./AuthContext";
const FutureBookings = () => {
  const [bookings, setBookings] = useState([]);
  const [facilityCodes, setFacilityCodes] = useState([]);
  const [loading, setLoading] = useState(true);

  const [open, setOpen] = useState("0");
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      const fb = await HBApi.getUserFutureBookings();

      if (fb.status === 401) {
        // If the server has restarted, this will happen
        console.log(
          "Unexpected 401 response, this will happen if the server has restarted"
        );
        auth.userLogout();
        navigate("/signin", { state: { nextpage: "/mypage/myberth" } });
      }

      const fbJson = await fb.json();
      if (!fbJson[0]) {
        return;
      } else {
        setBookings(fbJson);
        fbJson.forEach((t2) => {
          BookingFunctions.bookingTrailSorted(t2.bookingTrailDeepView);
        });
        const codesResponses = await Promise.all(
          fbJson
            .filter(
              (b) =>
                b.bookingTrailDeepView.status !== 2 &&
                DateFunctions.spannedBy(
                  new Date(),
                  b.bookingTrailDeepView.from,
                  b.bookingTrailDeepView.to
                )
            )
            .map((ongoingBooking) =>
              HBApi.getFacilityCodesByBooking(
                ongoingBooking.bookingTrailDeepView.id
              )
            )
        );
        const codesJson = await Promise.all(
          codesResponses.map((r) => r.json())
        );
        setFacilityCodes(codesJson);
      }
    }
    fetchData().then((fetchResult) => setLoading(false));
  }, []);

  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  if (loading) {
    return <Spinner />;
  }
  if (bookings.length === 0) {
    return (
      <Container>
        <Row>
          <Col md="12">
            <h1 align="center">No future bookings</h1>
          </Col>
        </Row>
      </Container>
    );
  }
  return (
    <Accordion open={open} toggle={toggle}>
      <BookingAccordionItems
        bookings={bookings}
        facilityCodes={facilityCodes}
      />
    </Accordion>
  );
};

export default FutureBookings;
