import { useState, useEffect } from "react";
import { Accordion, Container, Row, Col, Spinner } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { HBApi } from "./HBApi";
import { DateFunctions } from "./hmehelpers/DateFunctions";
import { useAuth } from "./AuthContext";
import BookingAccordionItems from "./bookinglists/BookingAccordionItems";
import { BookingFunctions } from "./hmehelpers/BookingFunctions";
const BookingsHistory = () => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);

  const [open, setOpen] = useState("0");
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    HBApi.getUserHistoricBookings()
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return Promise.reject(response);
      })
      .then((data) => {
        if (!data[0]) {
          //setLoading(false);
          return;
        } else {
          try {
            data.sort(
              (
                l,
                r // Most recent booking first
              ) =>
                DateFunctions.compareDateStrings(
                  r.bookingTrailDeepView.from,
                  l.bookingTrailDeepView.from
                )
            );
          } catch (exception) {
            console.log(`Exception sorting : ${exception}`);
          }
          data.forEach((t2) =>
            BookingFunctions.bookingTrailSorted(t2.bookingTrailDeepView)
          );
          setBookings(data);
        }
      })
      .then(() => setLoading(false))
      .catch((errorResponse) => {
        console.log("errorResponse" + errorResponse.status);
        if (errorResponse.status === 401) {
          // If the server has restarted, this will happen
          console.log(
            "Unexpected 401 response, this will happen if the server has restarted"
          );
          auth.userLogout();
          navigate("/signin", { state: { nextpage: "/mypage/myberth" } });
        }
      });
  }, []);

  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  if (loading) {
    return <Spinner />;
  }
  if (bookings.length === 0) {
    return (
      <Container>
        <Row>
          <Col md="12">
            <h1 align="center">No past bookings</h1>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Accordion open={open} toggle={toggle}>
      <BookingAccordionItems bookings={bookings} facilityCodes={[]} />
    </Accordion>
  );
};

export default BookingsHistory;
